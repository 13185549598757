import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NavigationStart, Router } from '@angular/router';
import { IDocument } from 'app/models/documents/document';
import { IDocumentType } from 'app/models/documents/documentType';
import { IApplicant } from 'app/models/licenses/applicant';
import { IEntityApplicant } from 'app/models/licenses/entity-applicant';
import { SharedService } from 'app/services/core/shared.service';
import { ApplicantService } from 'app/services/licenses/applicant.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

export const DateFormats = {
  parse: {
    dateInput: ['MM/DD/YYYY']
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-entity-applicant',
  templateUrl: './entity-applicant.component.html',
  styleUrls: ['./entity-applicant.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: DateFormats }]
})
export class EntityApplicantComponent implements OnInit {
  @Input() licenseId: number = 0;
  @Input() licenseType: string = '';
  @Input() cardColor: string = '';
  @Input() tableColor: string = '';
  @Input() renewal: boolean = false;
  @Input() status: string = '';
  @Input() licenseLocked: boolean = false;
  @Input() documentTypes: IDocumentType[] = [];
  @Input() individualApplicants: IApplicant[] = [];
  @Output() notifyParent: EventEmitter<boolean> = new EventEmitter<boolean>();

  public routeSubscription: Subscription;
  public applicants: IEntityApplicant[] = [];
  public applicant: IEntityApplicant = {
    id: 0,
    entityName: '',
    registeredIn: '',
    oregonResidency: false,
    headquartersState: '',
    headquartersCity: '',
    headquartersStreet: '',
    headquartersZip: '',
    headquartersCounty: '',
    physicalIsHeadquarters: false,
    physicalState: '',
    physicalCity: '',
    physicalStreet: '',
    physicalZip: '',
    physicalCounty: '',
    mailingIsPhysical: false,
    mailingIsHeadquarters: false,
    mailingState: '',
    mailingCity: '',
    mailingStreet: '',
    mailingZip: '',
    mailingCounty: '',
    primaryContactName: '',
    primaryContactNumber: '',
    primaryContactEmail: '',
    language: '',
    previousLicense: false,
    affiliatedLicenses: '',
    complete: false,
    applicantDocuments: [],
    applicants: [],
    businessInOregon: null,
    secretaryofStateNumber: '',
    registeredBusiness: null,
    registryNumber: '',
    registrationAttestation: false,
    applicantPageCorrect: null,
    applicantPageCorrectReason: ''
  }
  public filteredApplicants: IApplicant[] = [];
  public addingApplicant: boolean = false;
  public documentsValid: boolean = true;
  public applicantInfoType: number = 0;
  public applicantInfo: string = 'Applicant Information';
  public applicantForm = new UntypedFormGroup({
    entityName: new UntypedFormControl("", [Validators.required]),
    businessInOregon: new UntypedFormControl(Validators.required),
    registrationAttestation: new UntypedFormControl(false, Validators.requiredTrue),
    registryNumber: new UntypedFormControl(''),
    registeredBusiness: new UntypedFormControl(),
    secretaryofStateNumber: new UntypedFormControl(''),
    registeredIn: new UntypedFormControl(''),
    oregonResidency: new UntypedFormControl(false),
    headquartersStreet: new UntypedFormControl("", [Validators.required]),
    headquartersCity: new UntypedFormControl("", [Validators.required]),
    headquartersState: new UntypedFormControl("", [Validators.required]),
    headquartersZip: new UntypedFormControl("", [Validators.required, Validators.pattern(/^\d{5}(-\d{4})?$/)]),
    headquartersCounty: new UntypedFormControl(""),
    physicalIsHeadquarters: new UntypedFormControl(false),
    physicalStreet: new UntypedFormControl("", [Validators.required]),
    physicalCity: new UntypedFormControl("", [Validators.required]),
    physicalState: new UntypedFormControl("", [Validators.required]),
    physicalZip: new UntypedFormControl("", [Validators.required, Validators.pattern(/^\d{5}(-\d{4})?$/)]),
    physicalCounty: new UntypedFormControl(""),
    mailingIsHeadquarters: new UntypedFormControl(false),
    mailingIsPhysical: new UntypedFormControl(false),
    mailingStreet: new UntypedFormControl(""),
    mailingCity: new UntypedFormControl(""),
    mailingState: new UntypedFormControl(""),
    mailingZip: new UntypedFormControl("", [Validators.pattern(/^\d{5}(-\d{4})?$/)]),
    mailingCounty: new UntypedFormControl(""),
    primaryContactNumber: new UntypedFormControl('', [Validators.required, Validators.pattern(/^\(?([0-9]{3})\)?[-]?([0-9]{3})[-]?([0-9]{4}).*$/)]),
    primaryContactEmail: new UntypedFormControl('', [Validators.required, Validators.email]),
    confirmEmail: new UntypedFormControl(''),
    previousLicense: new UntypedFormControl(false),
    affiliatedLicenses: new UntypedFormControl(''),
    language: new UntypedFormControl(''),
    applicantPageCorrect: new UntypedFormControl(),
    applicantPageCorrectReason: new UntypedFormControl('')
    }, {
      validators: [
        this.physicalStreetValidator(),
        this.physicalCityValidator(),
        this.physicalStateValidator(),
        this.physicalZipValidator(),
        this.mailingStreetValidator(),
        this.mailingCityValidator(),
        this.mailingStateValidator(),
        this.mailingZipValidator(),
        this.previousLicenseValidator(),
        this.emailValidator(),
        this.businessInOregonValidator(),
        this.secretaryofStateNumberValidator(),
        this.businessInOtherStateValidator(),
        this.registeredLocationValidator(),
        this.registryNumberValidator(),
        this.applicantPageCorrectValidator()
      ]
    }
  );

  public dataColumns: string[] = ['entityName', 'complete', 'actions'];
  public dataSource = new MatTableDataSource<IEntityApplicant>(this.applicants);
  @ViewChild('paginator', {static: false}) paginator: MatPaginator;
  @ViewChild('sort', {static: false}) sort: MatSort;

  public applicantColumns: string[] = ['legalFirstName', 'legalLastName', 'actions'];
  public applicantSource = new MatTableDataSource<IApplicant>(this.filteredApplicants);
  @ViewChild('applicantPaginator', {static: false}) applicantPaginator: MatPaginator;
  @ViewChild('applicantSort', {static: false}) applicantSort: MatSort;

  public associatedApplicantSource = new MatTableDataSource<IApplicant>(this.applicant.applicants);
  @ViewChild('associatedApplicantPaginator', {static: false}) associatedApplicantPaginator: MatPaginator;
  @ViewChild('associatedApplicantSort', {static: false}) associatedApplicantSort: MatSort;

  constructor(public sharedService: SharedService,
              private router: Router,
              public applicantService: ApplicantService,
              public toastr: ToastrService) { }

  ngOnInit(): void {
    this.getApplicants();
    this.setupDocumentTypeReference();
    if(this.renewal)
    {
      this.applicantForm.controls['applicantPageCorrect'].setValidators([Validators.required]);
      this.applicantForm.controls['applicantPageCorrect'].updateValueAndValidity();
    }
    this.routeSubscription = this.router.events
    .pipe(filter((event) => event instanceof NavigationStart))
    .subscribe((e) => {
      this.navigationSave();
    });
  }

  filterIndividualApplicants(): void {
    this.filteredApplicants = this.individualApplicants.filter((applicant) => {
      return !this.applicant.applicants.some(a => a.id === applicant.id);
    });
    this.updateIndividualApplicantTable();
  }

  updateIndividualApplicantTable(): void {
    this.applicantSource.data = this.filteredApplicants;
    this.applicantSource.sort = this.applicantSort;
    this.applicantSource.paginator = this.applicantPaginator;
  }

  updateAssociatedApplicantTable(): void {
    this.associatedApplicantSource.data = this.applicant.applicants;
    this.associatedApplicantSource.sort = this.associatedApplicantSort;
    this.associatedApplicantSource.paginator = this.associatedApplicantPaginator;
  }

  setupDocumentTypeReference(): void {
    this.applicantInfoType = this.documentTypes.find(dt => dt.type === this.applicantInfo).id;
  }

  getApplicants(): void {
    this.applicantService.getEntityApplicants(this.licenseType, this.licenseId).subscribe(
      response => this.applicants = response,
      error => console.log('error', error),
      () => {
        if(this.renewal)
          this.checkCompleted();
        setTimeout(
          () => this.updateApplicantTable(), 250
        );
      }
    );
  }

  checkCompleted():void{
    this.applicants.forEach(a => {

      if(a.applicantDocuments.filter(d => !d.deprecated).length <= 0)
        a.complete = false;
      if(a.applicantPageCorrect != true && (a.applicantPageCorrectReason == '' || a.applicantPageCorrectReason == null))
        a.complete = false
      });
  }

  updateApplicantTable(): void{
    this.dataSource.data = this.applicants;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  filterTable(event: Event){
    let value = (event.target as HTMLInputElement).value;
    let filter = value.trim().toLocaleLowerCase();
    this.dataSource.filter = filter;
  }

  addApplicant(): void {
    this.validateDocuments();
    this.addingApplicant = true;
    this.applicantService.createEntityApplicant(this.licenseType, this.licenseId).subscribe(
      response => this.applicant = response,
      error => console.log('error', error),
      () => {
        this.applicants.push(this.applicant);
        this.applicantForm.markAsUntouched();
        this.applicantForm.markAsPristine();
        this.notifyParent.emit(true);
        setTimeout(() => this.filterIndividualApplicants(), 100);
      }
    );
  }

  editApplicant(applicant: IEntityApplicant): void {
    this.applicant = applicant;
    if(this.licenseLocked){
      this.applicantForm.disable();
    }
    if(this.renewal){
      this.applicantForm.controls['entityName'].disable();
      this.applicantForm.controls['language'].disable();
      this.applicantForm.controls['registeredIn'].disable();
      this.applicantForm.controls['registryNumber'].disable();
      this.applicantForm.controls['secretaryofStateNumber'].disable();
      this.applicantForm.markAllAsTouched();
    }

    this.applicantService.getAssociatedApplicants(this.applicant.id).subscribe(
      response => this.applicant.applicants = response,
      error => console.log('error', error),
      () => {
        this.updateApplicantForm();
        this.notifyParent.emit(true);
        setTimeout(() => {
          this.filterIndividualApplicants();
          this.updateAssociatedApplicantTable();
        }, 100);
        this.validateDocuments();
      }
    );
  }

  getEmptyApplicant(): IEntityApplicant {
    let emptyApplicant: IEntityApplicant = {
      id: 0,
      entityName: '',
      registeredBusiness: null,
      businessInOregon: null,
      secretaryofStateNumber: '',
      registrationAttestation: false,
      registryNumber: '',
      registeredIn: '',
      oregonResidency: false,
      headquartersState: '',
      headquartersCity: '',
      headquartersStreet: '',
      headquartersZip: '',
      headquartersCounty: '',
      physicalIsHeadquarters: false,
      physicalState: '',
      physicalCity: '',
      physicalStreet: '',
      physicalZip: '',
      physicalCounty: '',
      mailingIsPhysical: false,
      mailingIsHeadquarters: false,
      mailingState: '',
      mailingCity: '',
      mailingStreet: '',
      mailingZip: '',
      mailingCounty: '',
      primaryContactName: '',
      primaryContactNumber: '',
      primaryContactEmail: '',
      language: '',
      previousLicense: false,
      affiliatedLicenses: '',
      complete: false,
      applicantDocuments: [],
      applicants: [],
      applicantPageCorrect: null,
      applicantPageCorrectReason: ''
    }
    return emptyApplicant;
  }

  saveApplicant(close: boolean): void {
    this.updateApplicant();
    this.validateApplicantPageCorrect();
    this.applicant.complete = this.applicantForm.valid && this.applicant.applicantDocuments.filter(d => !d.deprecated).length > 0;
    this.applicantService.editEntityApplicant(this.applicant, this.licenseType, this.licenseId).subscribe(
      () => {
        this.addingApplicant = false;
        let index = this.applicants.findIndex(a => a.id === this.applicant.id);
        this.applicants[index] = this.applicant;

        if(!this.applicant.complete){
          this.applicantForm.markAllAsTouched();
          this.toastr.error("Please Complete Required Fields");
        }

        if(close) {
          if(!this.applicant.complete){
            this.sharedService.openConfirm(`The applicant information is incomplete.
                                            You will not be able to submit your application until all information is provided.
                                            Continue?`);
            this.sharedService.confirmed().subscribe(
              confirmed => {
                if(confirmed){
                  this.cancel();
                }
              }
            )
          }
          else{
            this.cancel();
          }
        }
      },
      error => console.log('error', error)
    );
  }

  navigationSave(): void {
    this.updateApplicant();
    this.applicant.complete = this.applicantForm.valid && this.applicant.applicantDocuments.filter(d => !d.deprecated).length > 0;
    this.applicantService.editEntityApplicant(this.applicant, this.licenseType, this.licenseId).subscribe();
  }

  updateApplicant(): void {
    let applicantInfo = this.applicantForm.value;
    if(!this.renewal) {
      this.applicant.entityName = applicantInfo.entityName;
      this.applicant.language = applicantInfo.language;
      this.applicant.registeredIn = applicantInfo.registeredIn;
      this.applicant.registryNumber = applicantInfo.registryNumber;
      this.applicant.secretaryofStateNumber = applicantInfo.secretaryofStateNumber;
    }
    this.applicant.businessInOregon = applicantInfo.businessInOregon;
    this.applicant.registrationAttestation = applicantInfo.registrationAttestation;
    this.applicant.registeredBusiness = applicantInfo.registeredBusiness;
    this.applicant.oregonResidency = applicantInfo.oregonResidency;
    this.applicant.headquartersState = applicantInfo.headquartersState;
    this.applicant.headquartersCity = applicantInfo.headquartersCity;
    this.applicant.headquartersStreet = applicantInfo.headquartersStreet;
    this.applicant.headquartersZip = applicantInfo.headquartersZip;
    this.applicant.headquartersCounty = applicantInfo.headquartersCounty;
    this.applicant.physicalIsHeadquarters = applicantInfo.physicalIsHeadquarters;
    if (applicantInfo.physicalIsHeadquarters) {
      this.applicant.physicalState = applicantInfo.headquartersState;
      this.applicant.physicalCity = applicantInfo.headquartersCity;
      this.applicant.physicalStreet = applicantInfo.headquartersStreet;
      this.applicant.physicalZip = applicantInfo.headquartersZip;
      this.applicant.physicalCounty = applicantInfo.headquartersCounty;
      this.applicantForm.patchValue({
        physicalState: applicantInfo.headquartersState,
        physicalCity: applicantInfo.headquartersCity,
        physicalStreet: applicantInfo.headquartersStreet,
        physicalZip: applicantInfo.headquartersZip,
        physicalCounty: applicantInfo.headquartersCounty
      });
    }
    else {
      this.applicant.physicalState = applicantInfo.physicalState;
      this.applicant.physicalCity = applicantInfo.physicalCity;
      this.applicant.physicalStreet = applicantInfo.physicalStreet;
      this.applicant.physicalZip = applicantInfo.physicalZip;
      this.applicant.physicalCounty = applicantInfo.physicalCounty;
    }
    this.applicant.mailingIsHeadquarters = applicantInfo.mailingIsHeadquarters;
    this.applicant.mailingIsPhysical = applicantInfo.mailingIsPhysical;
    if (applicantInfo.mailingIsHeadquarters) {
      this.applicant.mailingState = applicantInfo.headquartersState;
      this.applicant.mailingCity = applicantInfo.headquartersCity;
      this.applicant.mailingStreet = applicantInfo.headquartersStreet;
      this.applicant.mailingZip = applicantInfo.headquartersZip;
      this.applicant.mailingCounty = applicantInfo.headquartersCounty;
      this.applicantForm.patchValue({
        mailingState: applicantInfo.headquartersState,
        mailingCity: applicantInfo.headquartersCity,
        mailingStreet: applicantInfo.headquartersStreet,
        mailingZip: applicantInfo.headquartersZip,
        mailingCounty: applicantInfo.headquartersCounty
      });
    }
    else if (applicantInfo.mailingIsPhysical) {
      this.applicant.mailingState = applicantInfo.physicalState;
      this.applicant.mailingCity = applicantInfo.physicalCity;
      this.applicant.mailingStreet = applicantInfo.physicalStreet;
      this.applicant.mailingZip = applicantInfo.physicalZip;
      this.applicant.mailingCounty = applicantInfo.physicalCounty;
      this.applicantForm.patchValue({
        mailingState: applicantInfo.physicalState,
        mailingCity: applicantInfo.physicalCity,
        mailingStreet: applicantInfo.physicalStreet,
        mailingZip: applicantInfo.physicalZip,
        mailingCounty: applicantInfo.physicalCounty
      });
    }
    else {
      this.applicant.mailingState = applicantInfo.mailingState;
      this.applicant.mailingCity = applicantInfo.mailingCity;
      this.applicant.mailingStreet = applicantInfo.mailingStreet;
      this.applicant.mailingZip = applicantInfo.mailingZip;
      this.applicant.mailingCounty = applicantInfo.mailingCounty;
    }
    this.applicant.primaryContactEmail = applicantInfo.primaryContactEmail;
    this.applicant.primaryContactNumber = applicantInfo.primaryContactNumber;
    this.applicant.previousLicense = applicantInfo.previousLicense;
    this.applicant.affiliatedLicenses = applicantInfo.affiliatedLicenses;
    this.applicant.applicantPageCorrect = applicantInfo.applicantPageCorrect;
    this.applicant.applicantPageCorrectReason = applicantInfo.applicantPageCorrectReason;
  }

  updateApplicantForm(): void {
    if(this.applicant.businessInOregon)
      this.applicantForm.patchValue({
        businessInOregon: this.applicant.businessInOregon,
      });

    if(!this.applicant.businessInOregon)
      this.applicantForm.patchValue({
        businessInOregon: this.applicant.businessInOregon,
      });

    this.applicantForm.patchValue({
      entityName: this.applicant.entityName,
      secretaryofStateNumber: this.applicant.secretaryofStateNumber,
      registeredBusiness: this.applicant.registeredBusiness,
      registeredIn: this.applicant.registeredIn,
      registryNumber: this.applicant.registryNumber,
      registrationAttestation: this.applicant.registrationAttestation,
      oregonResidency: this.applicant.oregonResidency,
      headquartersState: this.applicant.headquartersState,
      headquartersCity: this.applicant.headquartersCity,
      headquartersStreet: this.applicant.headquartersStreet,
      headquartersZip: this.applicant.headquartersZip,
      headquartersCounty: this.applicant.headquartersCounty,
      physicalIsHeadquarters: this.applicant.physicalIsHeadquarters,
      physicalStreet: this.applicant.physicalStreet,
      physicalCity: this.applicant.physicalCity,
      physicalState: this.applicant.physicalState,
      physicalZip: this.applicant.physicalZip,
      physicalCounty: this.applicant.physicalCounty,
      mailingIsHeadquarters: this.applicant.mailingIsHeadquarters,
      mailingIsPhysical: this.applicant.mailingIsPhysical,
      mailingStreet: this.applicant.mailingStreet,
      mailingCity: this.applicant.mailingCity,
      mailingState: this.applicant.mailingState,
      mailingZip: this.applicant.mailingZip,
      mailingCounty: this.applicant.mailingCounty,
      primaryContactEmail: this.applicant.primaryContactEmail,
      confirmEmail: this.applicant.primaryContactEmail,
      primaryContactNumber: this.applicant.primaryContactNumber,
      previousLicense: this.applicant.previousLicense,
      affiliatedLicenses: this.applicant.affiliatedLicenses,
      applicantPageCorrect: this.applicant.applicantPageCorrect,
      applicantPageCorrectReason: this.applicant.applicantPageCorrectReason,
      language: this.applicant.language
    });
  }

  cancel(): void {
    let applicantId: number = this.applicant.id;
    if(this.addingApplicant){
      this.sharedService.openConfirm("Your work will not be saved. Continue?");
      this.sharedService.confirmed().subscribe(
        confirmed => {
          if(confirmed){
            this.applicantService.deleteEntityApplicant(applicantId, this.licenseType, this.licenseId).subscribe(
              () => {
                this.applicants = this.applicants.filter(a => a.id !== applicantId);
                this.applicant = this.getEmptyApplicant();
                this.addingApplicant = false;
                this.updateApplicantForm();
                this.notifyParent.emit(false);
                setTimeout(
                  () => this.updateApplicantTable(), 250
                );
              },
              error => console.log('error', error)
            );
          }
        }
      );
    }
    else{
      this.applicant = this.getEmptyApplicant();
      this.updateApplicantForm();
      this.notifyParent.emit(false);
      setTimeout(
        () => this.updateApplicantTable(), 250
      );
    }
  }

  deleteApplicant(applicant: IEntityApplicant): void {
    this.sharedService.openConfirm("Remove " + applicant.entityName + "?");
    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          this.applicantService.deleteEntityApplicant(applicant.id, this.licenseType, this.licenseId).subscribe(
            () => {
              this.applicants = this.applicants.filter(a => a.id !== applicant.id);
              this.updateApplicantTable();
            },
            error => console.log('error', error)
          );
        }
      }
    );
  }

  addIndividualApplicant(applicantId: number): void {
    this.applicantService.addIndividualApplicant(this.applicant.id, applicantId).subscribe(
      response => {
        if(response){
          this.applicant.applicants.push(this.individualApplicants.find(ia => ia.id === applicantId));
          this.updateAssociatedApplicantTable();
          this.filterIndividualApplicants();
        }
      },
      error => console.log('error', error)
    );
  }

  deleteIndividualApplicant(applicantId: number): void {
    this.sharedService.openConfirm('Remove applicant association?');
    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          this.applicantService.deleteIndividualApplicant(this.applicant.id, applicantId).subscribe(
            response => {
              if(response){
                this.applicant.applicants = this.applicant.applicants.filter(a => a.id !== applicantId);
                this.updateAssociatedApplicantTable();
                this.filterIndividualApplicants();
              }
            },
            error => console.log('error', error)
          );
        }
      }
    );
  }

  validateDocuments(): void {
    this.documentsValid = this.applicant.applicantDocuments.filter(d => !d.deprecated).length > 0;
  }

  uploadDocument(event: Event): void {
    let dirtyFile = (event.target as HTMLInputElement).files[0];
    let file = new File([dirtyFile], dirtyFile.name.replace(/[^A-Za-z0-9.]/g, ''));
    if(this.sharedService.validateFile(file))
    {
      let upload: IDocument = {
        id: 0,
        name: file.name, 
        comments: "",
        extenstion: "",
        dateCreated: "",
        dateLastUpdated: "",
        createdBy: "",
        lastUpdatedBy: "",
        parentId: this.applicant.id,
        types: [this.applicantInfoType],
        adminOnly: false,
        deprecated: false
      }

      const formData = new FormData();
      formData.append("file", file, file.name);
      formData.append("document", JSON.stringify(upload));
      this.applicantService.uploadEntityDocument(formData).subscribe(
        response => this.applicant.applicantDocuments.push(response),
        error => {
          (event.target as HTMLInputElement).value = '';
          console.log('error', error);
        },
        () => {
          (event.target as HTMLInputElement).value = '';
          this.validateDocuments();
        }
      );
    }
    else
    this.toastr.error("Unsupported File Type");
  }

  downloadDocument(fileId: number, fileName: string): void {
    this.applicantService.downloadFile(fileId).subscribe(
      (response) => this.saveFile(fileName, response),
      (error) => console.log("error", error)
    );
  }

  saveFile(fileName: string, blob: Blob) {
    let file = URL.createObjectURL(blob);
    var fileDownload = document.createElement("a");
    fileDownload.href = file;
    fileDownload.download = fileName;
    fileDownload.click();
  }

  deleteFile(id: number, name: string) {
    this.sharedService.openConfirm("Delete " + name + "?");
    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          this.applicantService.deleteDocument(id).subscribe(
            () => {
              this.applicant.applicantDocuments = this.applicant.applicantDocuments.filter(item => item.id !== id);
              this.validateDocuments();
            },
            error => console.log('error', error)
          );
        }
      }
    );
  }

  //Applicant Custom Validators

  applicantPageCorrectValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const pageCorrect = control.value.applicantPageCorrect;
      const reason = control.value.applicantPageCorrectReason;
      if (!pageCorrect && this.renewal) {
        return (reason !== null && reason !== '' && reason !== undefined) ? null : { applicantPageCorrectReasonRequired: true };
      }
      return null;
    };
  }

  validateApplicantPageCorrect(): boolean {
    if (this.applicantForm.hasError('applicantPageCorrectReasonRequired') && this.applicantForm.get('applicantPageCorrect').value === false) {
      this.applicantForm.get('applicantPageCorrectReason').setErrors(['required']);
      return true;
    }
    this.applicantForm.get('applicantPageCorrectReason').clearValidators();
    this.applicantForm.get('applicantPageCorrectReason').updateValueAndValidity();
    return false;
  }

  emailValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const email = control.value.primaryContactEmail;
      const confirmation = control.value.confirmEmail;
      return email === confirmation ? null : { emailConfirmed: true };
    }
  }

  validateEmail(): boolean {
    if (this.applicantForm.hasError('emailConfirmed') && this.applicantForm.get('confirmEmail').touched) {
      this.applicantForm.get('confirmEmail').setErrors(['emailConfirmed']);
      return true;
    }
    this.applicantForm.get('confirmEmail').clearValidators();
    this.applicantForm.get('confirmEmail').updateValueAndValidity();
    return false;
  }

  previousLicenseValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const hasPreviousLicense = control.value.previousLicense;
      const affiliatedLicenses = control.value.affiliatedLicenses;
      if(!hasPreviousLicense){
        return null;
      }
      return (affiliatedLicenses !== null && affiliatedLicenses !== '' && affiliatedLicenses !== undefined) ? null : { licensesRequired: true };
    }
  }

  validatePreviousLicense(): boolean {
    if (this.applicantForm.hasError('licensesRequired') && this.applicantForm.get('affiliatedLicenses').touched) {
      this.applicantForm.get('affiliatedLicenses').setErrors(['licensesRequired']);
      return true;
    }
    this.applicantForm.get('affiliatedLicenses').clearValidators();
    this.applicantForm.get('affiliatedLicenses').updateValueAndValidity();
    return false;
  }

  physicalIsSame(): void {
    this.applicantForm.get('physicalStreet').updateValueAndValidity();
    this.applicantForm.get('physicalCity').updateValueAndValidity();
    this.applicantForm.get('physicalState').updateValueAndValidity();
    this.applicantForm.get('physicalZip').updateValueAndValidity();
  }

  physicalStreetValidator(): ValidatorFn{
    return (control: AbstractControl): { [key: string]: any } | null => {
      const street = control.value.physicalStreet;
      const sameAsHq = control.value.physicalIsHeadquarters;
      if (!sameAsHq) {
        return (street !== null && street !== '' && street !== undefined) ? null : { physicalStreetRequired: true };
      }
      return null;
    };
  }

  validatePhysicalStreet(): boolean{
    if (this.applicantForm.hasError('physicalStreetRequired') && this.applicantForm.get('physicalStreet').touched) {
      this.applicantForm.get('physicalStreet').setErrors(['required']);
      return true;
    }
    this.applicantForm.get('physicalStreet').clearValidators();
    this.applicantForm.get('physicalStreet').updateValueAndValidity();
    return false;
  }

  physicalCityValidator(): ValidatorFn{
    return (control: AbstractControl): { [key: string]: any } | null => {
      const city = control.value.physicalCity;
      const sameAsHq = control.value.physicalIsHeadquarters;
      if (!sameAsHq) {
        return (city !== null && city !== '' && city !== undefined) ? null : { physicalCityRequired: true };
      }
      return null;
    };
  }

  validatePhysicalCity(): boolean{
    if (this.applicantForm.hasError('physicalCityRequired') && this.applicantForm.get('physicalCity').touched) {
      this.applicantForm.get('physicalCity').setErrors(['required']);
      return true;
    }
    this.applicantForm.get('physicalCity').clearValidators();
    this.applicantForm.get('physicalCity').updateValueAndValidity();
    return false;
  }

  physicalStateValidator(): ValidatorFn{
    return (control: AbstractControl): { [key: string]: any } | null => {
      const state = control.value.physicalState;
      const sameAsHq = control.value.physicalIsHeadquarters;
      if (!sameAsHq) {
        return (state !== null && state !== '' && state !== undefined) ? null : { physicalStateRequired: true };
      }
      return null;
    };
  }

  validatePhysicalState(): boolean{
    if (this.applicantForm.hasError('physicalStateRequired') && this.applicantForm.get('physicalState').touched) {
      this.applicantForm.get('physicalState').setErrors(['required']);
      return true;
    }
    this.applicantForm.get('physicalState').clearValidators();
    this.applicantForm.get('physicalState').updateValueAndValidity();
    return false;
  }

  physicalZipValidator(): ValidatorFn{
    return (control: AbstractControl): { [key: string]: any } | null => {
      const zip = control.value.physicalZip;
      const sameAsHq = control.value.physicalIsHeadquarters;
      if (!sameAsHq) {
        return (zip !== null && zip !== '' && zip !== undefined) ? null : { physicalZipRequired: true };
      }
      return null;
    };
  }

  validatePhysicalZip(): boolean{
    if (this.applicantForm.hasError('physicalZipRequired') && this.applicantForm.get('physicalZip').touched) {
      this.applicantForm.get('physicalZip').setErrors(['required']);
      return true;
    }
    this.applicantForm.get('physicalZip').clearValidators();
    this.applicantForm.get('physicalZip').updateValueAndValidity();
    return false;
  }

  businessInOregonValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const isOregonBusiness = control.value.businessInOregon;

      if(isOregonBusiness != null){
        return null;
      }

      return { businessInOregonRequired: true };
    }
  }

  validateBusinessInOregon(): boolean {
    if (this.applicantForm.hasError('businessInOregonRequired') && this.applicantForm.get('businessInOregon').touched) {
      return true;
    }
    return false;
  }

  businessInOtherStateValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const isOregonBusiness = control.value.businessInOregon;
      const otherState = control.value.registeredBusiness;
      if(isOregonBusiness){
        return null;
      }

      return (otherState != null) ? null : { otherBusinessRequired: true };
    }
  }

  validateBusinessInOtherState(): boolean {
    if (this.applicantForm.hasError('otherBusinessRequired') && this.applicantForm.get('registeredBusiness').touched) {
      return true;
    }
    return false;
  }

  secretaryofStateNumberValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const isOregonBusiness = control.value.businessInOregon;
      const secretaryofStateNumber = control.value.secretaryofStateNumber;
      if(!isOregonBusiness){
        return null;
      }
      if(this.renewal)
        return null;
      return (secretaryofStateNumber != null && secretaryofStateNumber != '' && secretaryofStateNumber != undefined) ? null : { secretaryofStateNumberRequired: true };
    }
  }

  validateSecretaryofStateNumber(): boolean {
    if (this.applicantForm.hasError('secretaryofStateNumberRequired') && this.applicantForm.get('secretaryofStateNumber').touched) {
      return true;
    }
    return false;
  }

  registeredLocationValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const isOtherBusiness = control.value.registeredBusiness;
      const registeredLocation = control.value.registeredIn;
      const isOregonBusiness = control.value.businessInOregon;
      if(!isOtherBusiness || isOregonBusiness){
        return null;
      }
      if(this.renewal)
        return null;
      return (registeredLocation !== null && registeredLocation !== '' && registeredLocation !== undefined) ? null : { registeredLocationRequired: true };
    }
  }

  validateRegisteredLocation(): boolean {
    if (this.applicantForm.hasError('registeredLocationRequired') && this.applicantForm.get('registeredIn').touched) {
      return true;
    }
    return false;
  }

  registryNumberValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const isOtherBusiness = control.value.registeredBusiness;
      const registryNumber = control.value.registryNumber;
      const isOregonBusiness = control.value.businessInOregon;
      if(!isOtherBusiness || isOregonBusiness){
        return null;
      }
      if(this.renewal)
        return null;
      return (registryNumber !== null && registryNumber !== '' && registryNumber !== undefined) ? null : { registryNumberRequired: true };
    }
  }

  validateRegistryNumber(): boolean {
    if (this.applicantForm.hasError('registryNumberRequired') && this.applicantForm.get('registryNumber').touched) {
      return true;
    }
    return false;
  }

  mailingIsSame(): void {
    this.applicantForm.get('mailingStreet').updateValueAndValidity();
    this.applicantForm.get('mailingCity').updateValueAndValidity();
    this.applicantForm.get('mailingState').updateValueAndValidity();
    this.applicantForm.get('mailingZip').updateValueAndValidity();
  }

  mailingStreetValidator(): ValidatorFn{
    return (control: AbstractControl): { [key: string]: any } | null => {
      const street = control.value.mailingStreet;
      const sameAsHq = control.value.mailingIsHeadquarters;
      const sameAsMail = control.value.mailingIsPhysical;
      if (!sameAsHq && !sameAsMail) {
        return (street !== null && street !== '' && street !== undefined) ? null : { mailingStreetRequired: true };
      }
      return null;
    };
  }

  validateMailingStreet(): boolean{
    if (this.applicantForm.hasError('mailingStreetRequired') && this.applicantForm.get('mailingStreet').touched) {
      this.applicantForm.get('mailingStreet').setErrors(['required']);
      return true;
    }
    this.applicantForm.get('mailingStreet').clearValidators();
    this.applicantForm.get('mailingStreet').updateValueAndValidity();
    return false;
  }

  mailingCityValidator(): ValidatorFn{
    return (control: AbstractControl): { [key: string]: any } | null => {
      const city = control.value.mailingCity;
      const sameAsHq = control.value.mailingIsHeadquarters;
      const sameAsMail = control.value.mailingIsPhysical;
      if (!sameAsHq && !sameAsMail) {
        return (city !== null && city !== '' && city !== undefined) ? null : { mailingCityRequired: true };
      }
      return null;
    };
  }

  validateMailingCity(): boolean{
    if (this.applicantForm.hasError('mailingCityRequired') && this.applicantForm.get('mailingCity').touched) {
      this.applicantForm.get('mailingCity').setErrors(['required']);
      return true;
    }
    this.applicantForm.get('mailingCity').clearValidators();
    this.applicantForm.get('mailingCity').updateValueAndValidity();
    return false;
  }

  mailingStateValidator(): ValidatorFn{
    return (control: AbstractControl): { [key: string]: any } | null => {
      const state = control.value.mailingState;
      const sameAsHq = control.value.mailingIsHeadquarters;
      const sameAsMail = control.value.mailingIsPhysical;
      if (!sameAsHq && !sameAsMail) {
        return (state !== null && state !== '' && state !== undefined) ? null : { mailingStateRequired: true };
      }
      return null;
    };
  }

  validateMailingState(): boolean{
    if (this.applicantForm.hasError('mailingStateRequired') && this.applicantForm.get('mailingState').touched) {
      this.applicantForm.get('mailingState').setErrors(['required']);
      return true;
    }
    this.applicantForm.get('mailingState').clearValidators();
    this.applicantForm.get('mailingState').updateValueAndValidity();
    return false;
  }

  mailingZipValidator(): ValidatorFn{
    return (control: AbstractControl): { [key: string]: any } | null => {
      const zip = control.value.mailingZip;
      const sameAsHq = control.value.mailingIsHeadquarters;
      const sameAsMail = control.value.mailingIsPhysical;
      if (!sameAsHq && !sameAsMail) {
        return (zip !== null && zip !== '' && zip !== undefined) ? null : { mailingZipRequired: true };
      }
      return null;
    };
  }

  validateMailingZip(): boolean{
    if (this.applicantForm.hasError('mailingZipRequired') && this.applicantForm.get('mailingZip').touched) {
      this.applicantForm.get('mailingZip').setErrors(['required']);
      return true;
    }
    this.applicantForm.get('mailingZip').clearValidators();
    this.applicantForm.get('mailingZip').updateValueAndValidity();
    return false;
  }
  //End of Validators

  ngOnDestroy() {
    this.routeSubscription.unsubscribe();
  }
}
