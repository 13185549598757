import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { SharedService } from 'app/services/core/shared.service';
import { PaymentService } from '../../service/payment.service';
import { PaymentSlipDialog } from '../payment-slip/payment-slip.component';

@Component({
  selector: 'app-payment-notice',
  templateUrl: './payment-notice.html'
})

export class PaymentNoticeComponent {
  @Input() canPay: boolean = false;
  @Input() entityType: string = '';
  @Input() feeType: string = this.paymentService.applicationFeeType;
  @Input() id: number = 0;
  @Input() cardColor: string = '';
  @Input() renewal: boolean = false;
  @Input() amount: string = '';
  public paymentAmount: string = '';

  staffInbox = environment.staffInbox;
  constructor(public paymentService: PaymentService,
    public sharedService: SharedService,
    public router: Router,
    public dialog: MatDialog) {
  }

  pay() {
    this.dialog.closeAll();
    this.router.navigate(['/pay-online/' + this.id], { queryParams: { entityType: this.entityType}});
  }

  viewRemittanceSlip() {
    this.dialog.open(PaymentSlipDialog, {
      data: {
        feeType: this.feeType,
        entityType: this.entityType,
        id: this.id,
        amount: this.amount,
      },
      role: 'dialog',
      ariaLabel: 'Remittance Slip Dialog',
      minWidth: !this.sharedService.mobile ? '400px' : '300px',
      maxWidth: !this.sharedService.mobile ? '800px' : '300px'
    });
  }
}
