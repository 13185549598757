import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';


const httpOptionsFormData = {
  headers: new HttpHeaders({
    'Cache-Control': 'no-cache',
  }),
  withCredentials: true
};

@Injectable({providedIn: 'root'})

export class PaymentService {

  public applicationFeeType = "ApplicationFee";
  public licenseFeeType = "LicenseFee";
  public reducedLicenseFeeType = "ReducedLicenseFee";
  public CivilPenaltyFeeType = "CivilPenaltyFee"
  public changeRequestFeeType = "ChangeRequestFee"
  public changeLocationFeeType = "ChangeLocationFee";

  public trainingProgramPaymentOption = "TrainingProgram";
  public facilitatorPaymentOption = "FacilitatorLicense";
  public ServiceCenterPaymentOption = "ServiceCenterLicense";
  public WorkerPermitPaymentOption = "WorkerPermitLicense";
  public ManufacturerPaymentOption = "ManufacturerLicense";
  public TestingLabPaymentOption = "TestingLabLicense";
  public PaymentIssuedCommentSearchText = "Online Payment issued";
  public paymentAwaitingClearanceStatus = 'Awaiting Clearance';

  public paymentOptions = [
    {
      Text: "Training Program",
      Value: this.trainingProgramPaymentOption,
      ApplicationFee: "500.00",
      LicenseFee: "500.00",
      ReducedLicenseFee: "500.00",
      ChangeRequestFee: "250.00",
      ChangeLocationFee: "500.00",
      RenewalFee: "",
      ApplicationFeeNotice: "The Training Program application will not be reviewed until payment has been received and cleared. After the Training Program application has been submitted, a “Pay Fee” button will be displayed. By clicking this button, payment information below will be displayed along with a downloadable payment remittance form to submit with your payment. A copy of this information will also be emailed to you after the application is submitted.",
      LicenseFeeNotice: "",
      CivilPenaltyNotice: "",
      ChangeRequestNotice: "",
      ChangeLocationNotice: "",
      ApplicationFeeNoticeRenewal: "",
    },
    {
      Text: "Facilitator",
      Value: this.facilitatorPaymentOption,
      ApplicationFee: "150.00",
      LicenseFee: "2000.00",
      ReducedLicenseFee: "1000.00",
      ChangeRequestFee: "250.00",
      ChangeLocationFee: "500.00",
      RenewalFee: "2,000 or $1,000 if Applicant Qualifies for Reduced License Fee",
      ApplicationFeeNotice: "The Facilitator application will not be reviewed until payment has been received and cleared. After the Facilitator application has been submitted, a “Pay Fee” button will be displayed. By clicking this button, payment information below will be displayed along with a remittance form that can be downloaded and submitted with your payment if you prefer to pay in-person. A copy of this information will also be emailed to you after the application is submitted.",
      LicenseFeeNotice: "The Facilitator license fee is required to complete the approval process.  Your Facilitator license may be revoked if payment is not received within 60 days.",
      CivilPenaltyNotice: "",
      ChangeRequestNotice: "",
      ChangeLocationNotice: "",
      ApplicationFeeNoticeRenewal: "If your license application is approved, pending license fee payment and clearance, you will receive a request to pay the annual licensing fee. Please review the guide for more detailed information on reduced fees. To request a reduced fee, please upload the required supporting document in the “Documents” tab of this application. If you qualify for a reduced license fee, the fee will be reduced accordingly. Please use the same payment instructions above to pay the annual license fee.\n \n \nPlease Note: If payment is not submitted within 30 days from the date that you received notice that your renewal application has been moved to “renewal approved pending payment and clearance” status, your renewal application will be considered incomplete and will be closed. The license will not be renewed until the payment has cleared and processing of the renewal has been completed.\n \n \nAfter the application has been submitted and the review of the application has been completed and approved, a “Pay Fee” button will be displayed. By clicking this button, payment information below will be displayed along with a remittance form that can be downloaded and submitted with your payment if you prefer to pay in-person. A copy of this information will also be emailed to you after the application is submitted.",
    },
    {
      Text: "Service Center",
      Value: this.ServiceCenterPaymentOption,
      ApplicationFee: "500.00",
      LicenseFee: "10000.00",
      ReducedLicenseFee: "500.00",
      ChangeRequestFee: "250.00",
      ChangeLocationFee: "500.00",
      RenewalFee: "10,000 or $5,000 if Applicant Qualifies for Reduced License Fee",
      ApplicationFeeNotice: "The service center application will not be reviewed until payment has been received and cleared. After the application has been submitted, a “Pay Fee” button will be displayed. By clicking this button, payment information below will be displayed along with a remittance form that can be downloaded and submitted with your payment if you prefer to pay in-person. A copy of this information will also be emailed to you after the application is submitted.",
      LicenseFeeNotice: "The Service Center license fee is required to complete the approval process.  Your Service Center license may be revoked if payment is not received within 60 days.",
      CivilPenaltyNotice: "",
      ChangeRequestNotice: "",
      ChangeLocationNotice: "",
      ApplicationFeeNoticeRenewal: "If your license application is approved, pending license fee payment and clearance, you will receive a request to pay the annual licensing fee. Please review the guide for more detailed information on reduced fees. To request a reduced fee, please upload the required supporting document in the “Documents” tab of this application. If you qualify for a reduced license fee, the fee will be reduced accordingly. Please use the same payment instructions above to pay the annual license fee.\n \n \nPlease Note: If payment is not submitted within 30 days from the date that you received notice that your renewal application has been moved to “renewal approved pending payment and clearance” status, your renewal application will be considered incomplete and will be closed. The license will not be renewed until the payment has cleared and processing of the renewal has been completed.\n \n \nAfter the application has been submitted and the review of the application has been completed and approved, a “Pay Fee” button will be displayed. By clicking this button, payment information below will be displayed along with a remittance form that can be downloaded and submitted with your payment if you prefer to pay in-person. A copy of this information will also be emailed to you after the application is submitted.",
    },
    {
      Text: "Worker Permit",
      Value: this.WorkerPermitPaymentOption,
      ApplicationFee: "25.00",
      LicenseFee: "25.00",
      ReducedLicenseFee: "25.00",
      ChangeRequestFee: "250.00",
      ChangeLocationFee: "500.00",
      RenewalFee: "",
      ApplicationFeeNotice: "After the worker permit application has been approved, pending worker permit fee payment and clearance, you will receive a request to pay the worker permit fee. A “Pay Fee” button will be displayed. By clicking this button, payment information below will be displayed along with a remittance form that can be downloaded and submitted with your payment if you prefer to pay in-person. A copy of this information will also be emailed to you after the application is submitted.",
      LicenseFeeNotice: "",
      CivilPenaltyNotice: "",
      ChangeRequestNotice: "",
      ChangeLocationNotice: "",
      ApplicationFeeNoticeRenewal: "",
    },
    {
      Text: "Manufacturer",
      Value: this.ManufacturerPaymentOption,
      ApplicationFee: "500.00",
      LicenseFee: "10000.00",
      ReducedLicenseFee: "5000.00",
      ChangeRequestFee: "250.00",
      ChangeLocationFee: "500.00",
      RenewalFee: "10,000 or $5,000 if Applicant Qualifies for Reduced License Fee",
      ApplicationFeeNotice: "The manufacturer application will not be reviewed until payment has been received and cleared. After the application has been submitted, a “Pay Fee” button will be displayed. By clicking this button, payment information below will be displayed along with a remittance form that can be downloaded and submitted with your payment if you prefer to pay in-person. A copy of this information will also be emailed to you after the application is submitted.",
      LicenseFeeNotice: "The Manufacturer license fee is required to complete the approval process.  Your Manufacturer license may be revoked if payment is not received within 60 days.",
      CivilPenaltyNotice: "",
      ChangeRequestNotice: "",
      ChangeLocationNotice: "",
      ApplicationFeeNoticeRenewal: "If your license application is approved, pending license fee payment and clearance, you will receive a request to pay the annual licensing fee. Please review the guide for more detailed information on reduced fees. To request a reduced fee, please upload the required supporting document in the “Documents” tab of this application. If you qualify for a reduced license fee, the fee will be reduced accordingly. Please use the same payment instructions above to pay the annual license fee.\n \n \nPlease Note: If payment is not submitted within 30 days from the date that you received notice that your renewal application has been moved to “renewal approved pending payment and clearance” status, your renewal application will be considered incomplete and will be closed. The license will not be renewed until the payment has cleared and processing of the renewal has been completed.\n \n \nAfter the application has been submitted and the review of the application has been completed and approved, a “Pay Fee” button will be displayed. By clicking this button, payment information below will be displayed along with a remittance form that can be downloaded and submitted with your payment if you prefer to pay in-person. A copy of this information will also be emailed to you after the application is submitted.",
    },
    {
      Text: "Testing Lab",
      Value: this.TestingLabPaymentOption,
      ApplicationFee: "500.00",
      LicenseFee: "10000.00",
      ReducedLicenseFee: "5000.00",
      ChangeRequestFee: "250.00",
      ChangeLocationFee: "500.00",
      RenewalFee: "10,000 if Applicant Qualifies for Reduced License Fee",
      ApplicationFeeNotice: "The laboratory application will not be reviewed until payment has been received and cleared. After the application has been submitted, a “Pay Fee” button will be displayed. By clicking this button, payment information below will be displayed along with a remittance form that can be downloaded and submitted with your payment if you prefer to pay in-person. A copy of this information will also be emailed to you after the application is submitted.",
      LicenseFeeNotice: "The Laboratory license fee is required to complete the approval process.  Your Laboratory license may be revoked if payment is not received within 60 days.",
      CivilPenaltyNotice: "",
      ChangeRequestNotice: "",
      ChangeLocationNotice: "",
      ApplicationFeeNoticeRenewal: "If your license application is approved, pending license fee payment and clearance, you will receive a request to pay the annual licensing fee. Please review the guide for more detailed information on reduced fees. To request a reduced fee, please upload the required supporting document in the “Documents” tab of this application. If you qualify for a reduced license fee, the fee will be reduced accordingly. Please use the same payment instructions above to pay the annual license fee.\n \n \nPlease Note: If payment is not submitted within 30 days from the date that you received notice that your renewal application has been moved to “renewal approved pending payment and clearance” status, your renewal application will be considered incomplete and will be closed. The license will not be renewed until the payment has cleared and processing of the renewal has been completed.\n \n \nAfter the application has been submitted and the review of the application has been completed and approved, a “Pay Fee” button will be displayed. By clicking this button, payment information below will be displayed along with a remittance form that can be downloaded and submitted with your payment if you prefer to pay in-person. A copy of this information will also be emailed to you after the application is submitted.",
    }
  ]

  public paymentPendingStatus = 'Pending';
  public paymentApprovedAwaitingPayment = 'Approved, Pending Payment and Clearance'
  public paymentPaidStatus = 'Paid'
  public paymentClearedStatus = 'Cleared';
  public paymentStatuses = [this.paymentPendingStatus, this.paymentClearedStatus, this.paymentPaidStatus ];
  public paymentMethods = [
    'Online',
    'Money Order',
    'Personal/Business Check',
    'Cashier\'s Check',
    'Cash'];
  private api: string = environment.privateApi + 'Payments/';

  constructor(private http: HttpClient) { }

  public getPaymentTypeName(entityType: string): string {
    let option = this.paymentOptions.find(po => po.Value == entityType);
    if(option != null && option != undefined) {
      return option.Text;
    }
    return entityType;
  }

  public getPaymentTypeNotice(entityType: string, feeType: string, renewal: boolean): string {
    let option = this.paymentOptions.find(po => po.Value == entityType);
    if(option != null && option != undefined) {
      if(feeType == this.licenseFeeType || feeType == this.reducedLicenseFeeType ) {
          return option.LicenseFeeNotice;
      }
      if(feeType == this.applicationFeeType) {
        if(!renewal)
          return option.ApplicationFeeNotice;
        else
          return option.ApplicationFeeNoticeRenewal;
      }
      if(feeType == this.CivilPenaltyFeeType) {
        return option.CivilPenaltyNotice;
      }
      if(feeType == this.changeRequestFeeType) {
        return option.ChangeRequestNotice;
      }
      if(feeType == this.changeLocationFeeType) {
        return option.ChangeLocationNotice;
      }
      return "";

    }
    return "";
  }

  public getPaymentTypeAmount(entityType: string, feeType: string, renewal: boolean, amount: string): string {
    let option = this.paymentOptions.find(po => po.Value == entityType);
    if(option != null && option != undefined) {
      if(feeType == this.licenseFeeType ) {
        return option.LicenseFee;
      }
      if(feeType == this.reducedLicenseFeeType ) {
        return option.ReducedLicenseFee;
      }
      if(feeType == this.applicationFeeType) {
        if(!renewal)
          return option.ApplicationFee;
        if(renewal)
          return option.RenewalFee;
      }
      if(feeType == this.changeRequestFeeType){
        return option.ChangeRequestFee;
      }
      if(feeType == this.changeLocationFeeType){
        return option.ChangeLocationFee;
      }
      if(feeType == this.CivilPenaltyFeeType){
        return amount;
      }
      return "";

    }
    return "";
  }

  public getPaymentTypeDescription(entityType: string, feeType: string, renewal: boolean): string {
    let option = this.paymentOptions.find(po => po.Value == entityType);
    if(option != null && option != undefined) {
      if(feeType == this.licenseFeeType) {
        return option.Text + " License Fee";
      }
      else if(feeType == this.reducedLicenseFeeType) {
        return option.Text + " Reduced License Fee";
      }
      else if(feeType == this.applicationFeeType) {
        if(!renewal)
          return option.Text + " Application Fee";
        if(renewal)
          return option.Text + " Annual License Fee";
      }
      else if(feeType == this.CivilPenaltyFeeType){
        return option.Text + " Civil Penalty Fee";
      }
      else if(feeType == this.changeRequestFeeType){
        return option.Text + " Change Request Fee";
      }
      else if(feeType == this.changeLocationFeeType){
        return option.Text + " Change of Location Fee";
      }
      else if(feeType != null) {
        return option.Text + " " + feeType;
      }
      return option.Text
    }
    return "";
  }

  public negotiateToken(paymentId: number) : Observable<string> {
    return this.http.post<string>(this.api + 'NegotiateToken/' + paymentId, httpOptionsFormData);
  }

  public updatePaymentSuccess(token: string) : Observable<string> {
    return this.http.post<string>(this.api + 'UpdatePaymentSuccess/' + token, httpOptionsFormData);
  }

}
