<mat-card class="sticky-header p-y-0" [style.background-color]="sharedService.orange" *ngIf="!showSubmitted">
  <mat-card-content>
    <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center" style="color: #303030">
      <mat-icon class="medium-icon m-24">domain</mat-icon>
      <span class="f-s-20" *ngIf="!renewing">Apply For a Laboratory License</span>
      <span class="f-s-20" *ngIf="renewing">Renew Laboratory License</span>
      <span fxFlex></span>
      <button mat-flat-button color="primary"
      *ngIf="viewForm && !editingApplicant && !editingEntityApplicant && hasApplication" (click)="closeApplication()">Close</button>
    </div>
  </mat-card-content>
</mat-card>

<div fxLayout="column" fxLayoutGap="8px" *ngIf="viewForm && !showSubmitted">
  <mat-stepper [orientation]="sharedService.isMobile? 'vertical' : 'horizontal'" #testingLabStepper
    [selectedIndex]="step" (selectionChange)="changeStep($event)">

    <!--Getting Started-->
    <mat-step>
      <ng-template matStepLabel>
        <span matTooltip="Getting Started" matTooltipPosition="below" [matTooltipShowDelay]="500">Getting Started</span>
      </ng-template>
      <div fxLayout="column" fxLayoutGap="8px" class="p-b-8">
        <mat-card >
          <mat-card-title>
            <span *ngIf="!renewing">Thank you for your interest in applying for a psilocybin laboratory license through the Oregon Psilocybin Services - Training Program,
              Licensing, and Compliance system (TLC). </span>
              <span *ngIf="renewing">Thank you for your interest in applying to renew your psilocybin laboratory license through the Oregon Psilocybin Services - Training Program, Licensing, and Compliance system (TLC).</span>
          </mat-card-title>
          <mat-card-content>
           <div fxLayout="column" fxLayoutGap="8px" *ngIf="!renewing">
            <span>More detailed information to support the application process can be found in the <strong>Psilocybin Laboratory License Online Application Guide.</strong></span>
            <span>Please visit <a href="https://www.oregon.gov/oha/PH/PREVENTIONWELLNESS/Pages/Psilocybin-License-Lab-Testing.aspx" target="_blank" rel="noreferrer noopener" style="word-break: break-all;
              white-space: normal;">https://www.oregon.gov/oha/PH/PREVENTIONWELLNESS/Pages/Psilocybin-License-Lab-Testing.aspx</a> to access this guide.</span>
           </div>
           <div fxLayout="column" fxLayoutGap="8px" *ngIf="renewing">
            <span>More detailed information to support the application process can be found in the Psilocybin Laboratory License Renewal Application Guide for Renewal Applications Submitted Online.</span>
            <span>Please visit <a href="https://www.oregon.gov/oha/PH/PREVENTIONWELLNESS/Documents/Laboratory-License-Renewal-Application-Guide-ONLINE.pdf" target="_blank" rel="noreferrer noopener" style="word-break: break-all;
              white-space: normal;">https://www.oregon.gov/oha/PH/PREVENTIONWELLNESS/Documents/Laboratory-License-Renewal-Application-Guide-ONLINE.pdf</a> to access this guide.</span>
           </div>
          <div fxLayout="column" fxLayoutGap="8px" style="padding-top: 1em">
            <span class="p-l-24">
              <strong *ngIf="!renewing"><u>One applicant must be designated as the Primary Point of Contact for communication. The primary point of contact is
                responsible for ensuring all persons identified as licensees or applicants are aware of relevant communications, including but not limited to
                notices issued under ORS Chapter 183. </u></strong>
              <strong *ngIf="renewing"><u>One applicant must be designated as the Primary Point of Contact for communication. The primary point of contact is
                responsible for ensuring all persons identified as licensees or applicants are aware of relevant communications, including but not limited to
                notices issued under ORS Chapter 183. If the primary point of contact will be different upon renewal, please send this through the change request
                process. Change request process documents can be found on the TLC Home Page.</u></strong>
            </span>
            <span class="p-l-24">
              <strong><u>Applicants must read, understand, and agree to adhere to the Oregon Psilocybin Services
                Revised Statutes <a href="https://www.oregonlegislature.gov/bills_laws/ors/ors475a.html" target="_blank" rel="noreferrer noopener" style="
                white-space: normal;">(ORS) Chapter 475A</a>, Oregon Administrative Rules <a href="https://secure.sos.state.or.us/oard/displayDivisionRules.action?selectedDivision=7102"
                target="_blank" rel="noreferrer noopener" style="white-space: normal;">(OAR) Chapter 333</a>, Division 333, as well as
                any other applicable statutes or laws.</u></strong>
            </span>
            <strong *ngIf="!renewing">The completed application packet contains the following:</strong>
            <strong *ngIf="renewing">A completed renewal application packet contains the following: </strong>
            <span class="p-l-24" *ngIf="!renewing">
              <strong>• </strong>Completed and signed laboratory license application
            </span>
            <span class="p-l-24" *ngIf="renewing">
              <strong>• </strong>Completed and signed laboratory license renewal application
            </span>
            <span class="p-l-24" *ngIf="!renewing">
              <strong>• </strong>Uploaded copy of the front and back of a valid, unexpired government-issued photo identification for the applicant(s)
            </span>
            <span class="p-l-24">
              <strong>• </strong>Uploaded individual and entity applicant information forms for each applicant
            </span>
            <span class="p-l-24" *ngIf="!renewing">
              <strong>• </strong>Uploaded copy of ORELAP endorsement
            </span>
            <span class="p-l-24" *ngIf="!renewing">
              <strong>• </strong>Uploaded sketch of premises, floor plan including boundaries, and camera plan
            </span>
            <span class="p-l-24" *ngIf="!renewing">
              <strong>• </strong>Uploaded copy of the social equity plan
            </span>
            <span class="p-l-24" *ngIf="renewing">
              <strong>• </strong>Uploaded evaluation of last year's Social Equity Plan
            </span>
            <span class="p-l-24" *ngIf="!renewing">
              <strong>• $500</strong> Non-refundable laboratory license application fee paid and cleared.
            </span>

            <strong>Important Notes: </strong>
            <span class="p-l-24">
              <strong>• </strong>Please review each section of the application to ensure it is complete
            </span>
            <span class="p-l-24" *ngIf="!renewing">
              <strong>• </strong>Toward the end of the application, a fee page will describe how to submit the application fee which may be paid online or in-person
            </span>
            <span class="p-l-24" *ngIf="renewing">
              <strong>• </strong>Toward the end of the renewal application, a fee page will describe how to submit the renewal license fee which may be paid online, by mail or in-person
            </span>
            <span class="p-l-24" *ngIf="!renewing">
              <strong>• </strong>OPS will not review an application packet until the application fee is paid and cleared. You have 90 days from the date of
              application submission to pay the application fee or it will be closed.
            </span>
            <span class="p-l-24" *ngIf="!renewing">
              <strong>• Incomplete and Insufficient Application Statuses:</strong> Please review the application guide for more detailed information on all application statuses.
            </span>
            <div fxLayout="column" style="padding-left: 1em" fxLayoutGap="8" *ngIf="!renewing">
              <span class="p-l-24">
                <strong>• </strong>Incomplete: An application is considered incomplete if the application is missing required information (e.g., the application fee has not been
                paid or the applicant did not submit fingerprints). If the application is incomplete, we will notify you by email to give you an opportunity to make
                your application complete. You have 90 days from the initial date you are notified to complete your application. If you fail to do so, the application will be closed.
              </span>
              <span class="p-l-24">
                <strong>• </strong>Insufficient: An application is considered insufficient if the complete application or site inspection does not meet requirements
                outlined in statute or rule. If the application is insufficient, we will notify you by email to give you an opportunity to meet requirements. You will
                have 90 days from the initial date you are notified to meet requirements, or your application will be denied.
              </span>
            </div>
            <span class="p-l-24" *ngIf="renewing">
              <strong>• Timely and Untimely Applications:</strong> Please review the application guide for more detailed information on when to submit a renewal application.
            </span>
            <div fxLayout="column" style="padding-left: 1em" fxLayoutGap="8" *ngIf="renewing">
              <span class="p-l-24">
                <strong>• </strong><strong>Timely:</strong> Renewal applications that are complete, submitted according to requirements, and submitted at least 60 days before the license
                expires will be considered timely under OPS rules. Licensees who submit timely applications may continue to operate on an expired license if OPS is unable
                to process the renewal application prior to the license expiration date. Incomplete renewal applications will not be considered timely unless they are made
                complete <strong>at least 60 days</strong> before the license expires.
              </span>
              <span class="p-l-24">
                <strong>• </strong><strong>Untimely:</strong> Renewal applications that are submitted <strong>less than 60 days</strong> before the license expires will be considered untimely under OPS rules.
                Licensees who submit untimely applications are prohibited from continuing to operate on an expired license if OPS is unable to process the renewal application
                prior to the license expiration date. Licensees are prohibited from exercising license privileges on the date their license expires if OPS is unable to process
                their renewal application before the expiration date and the renewal application is untimely.
              </span>
            </div>
            <span *ngIf="!renewing">
            <strong>When you click the continue button at the bottom of this page, you will proceed to the psilocybin laboratory license application. </strong>
              The application automatically saves in TLC. You may exit the application and come back later to complete and submit the application, if needed.
            </span>
            <span *ngIf="renewing">
              <strong>When you click the continue button at the bottom of this page, you will proceed to the psilocybin laboratory license renewal application.</strong>
              The renewal automatically saves in TLC. You may exit the application and come back later to complete and submit the renewal application, if needed.
              </span>
            <div fxLayout="column" fxLayoutGap="8px" style="padding-top: 2em;" >
              <span>
                <strong>DISCLAIMER:</strong> Psilocybin remains a schedule I drug under the federal Controlled Substances Act. Applicants are responsible for making their own determinations regarding the legal risks associated with participating in this program. The
                  information you provide in your application materials, including applicant name, is subject to disclosure under Oregon's public records laws.
              </span>
            </div>
          </div>
          </mat-card-content>
        </mat-card>

        <div *ngIf="!hasApplication && showStartButton">
          <button mat-flat-button type="button" color="primary" (click)="startApplication()">Start Application</button>
        </div>
        <div *ngIf="hasApplication" fxLayout="row" fxFlex="50" fxFlex.lt-md="100" class="p-t-16">
          <button mat-raised-button matStepperNext>Continue</button>
        </div>
      </div>
    </mat-step>

    <!--Testing Lab Information-->
    <mat-step *ngIf="licenses.length > 0">
      <ng-template matStepLabel>
        <span *ngIf="licenseForm.valid || licenseForm.pristine || licenseLocked"
          matTooltip="Laboratory Information" matTooltipPosition="below" [matTooltipShowDelay]="500">Laboratory Information</span>
        <span *ngIf="!licenseForm.valid && !licenseForm.pristine && !licenseLocked" class="text-red-400"
          matTooltip="Laboratory Information Incomplete" matTooltipPosition="below" [matTooltipShowDelay]="500">Laboratory Information Incomplete</span>
      </ng-template>

      <form [formGroup]="licenseForm">
        <div fxLayout="column">
          <div fxLayout="row" fxLayoutGap="8px" fxLayout.lt-lg="column">
            <mat-card  fxFlex="40">
              <mat-card-title>
                <span>Laboratory Information</span>
              </mat-card-title>
              <mat-card-content>
                <div fxLayout="column" fxLayoutGap="8px">

                  <span class="f-s-16"><strong>Operational Name</strong><span style="color: red" *ngIf="licenseForm.get('entityName').value ==''">*</span></span>
                  <mat-form-field>
                    <input matInput formControlName="entityName">
                    <mat-placeholder style="color:red" *ngIf="licenseForm.get('entityName').value == ''">Operational Name*</mat-placeholder>
                    <mat-placeholder style="color:grey" *ngIf="licenseForm.get('entityName').value != ''">Operational Name</mat-placeholder>
                    <mat-error *ngIf="licenseForm.get('entityName').invalid">
                      A name is required
                    </mat-error>
                  </mat-form-field>

                  <span class="f-s-16"><strong>Website</strong></span>
                  <mat-form-field>

                    <input matInput placeholder="Website" formControlName="entityWebsite">
                    <mat-error *ngIf="licenseForm.get('entityWebsite').hasError('pattern')">
                      Please enter a valid URL (http:// or https://)
                    </mat-error>
                  </mat-form-field>

                  <span class="f-s-16"><strong>Registered Business</strong></span>
                  <div fxLayout="row">
                    <div fxLayout="column">
                    <span class="text-wrap">Is the applicant registered as a business with the Secretary of State (SOS) in Oregon?</span>
                    <mat-radio-group aria-label="Select an option" formControlName="businessInOregon" fxLayoutGap="8px">
                      <mat-radio-button [value]="true" [disabled]="renewing">Yes</mat-radio-button>
                      <mat-radio-button [value]="false" [disabled]="renewing">No</mat-radio-button>
                    </mat-radio-group>
                    <mat-error *ngIf="validateBusinessInOregon()">This field is required</mat-error>
                  </div>
                  </div>
                  <div fxLayout="column" fxLayoutGap="8px" *ngIf="licenseForm.get('businessInOregon').value == true" [@slideInOut]>
                    <div fxLayout="column">
                      <span>Oregon Secretary of State Registration Number</span>
                      <mat-form-field>
                        <input matInput placeholder="Registry Number" formControlName="secretaryofStateNumber">
                      </mat-form-field>
                      <mat-error *ngIf="validateSecretaryofStateNumber()">
                        Secretary of State Registration Number is required
                      </mat-error>
                    </div>
                  </div>
                  <div fxLayout="row" *ngIf="!licenseForm.get('businessInOregon').value">
                    <div fxLayout="column">
                    <span class="text-wrap">Is the applicant registered as a business in another state, US territory or a freely associated state?</span>
                    <mat-radio-group aria-label="Select an option" formControlName="registeredBusiness" fxLayoutGap="8px">
                      <mat-radio-button [value]="true" [disabled]="renewing">Yes</mat-radio-button>
                      <mat-radio-button [value]="false" [disabled]="renewing">No</mat-radio-button>
                    </mat-radio-group>
                    <mat-error *ngIf="validateBusinessInOtherState()">This field is required</mat-error>
                  </div>
                  </div>
                  <div fxLayout="column" fxLayoutGap="8px" *ngIf="licenseForm.get('registeredBusiness').value == true && licenseForm.get('businessInOregon').value == false" [@slideInOut]>
                    <div fxLayout="column">
                      <span>Please provide the location (state, US territory, or freely associated state) where the laboratory is registered as a business and the registration number.</span>

                      <mat-form-field>
                        <input matInput placeholder="Location Registered" formControlName="registeredIn">
                      </mat-form-field>
                      <mat-error *ngIf="validateRegisteredLocation()">Business Location is required</mat-error>

                      <mat-form-field>
                        <input matInput placeholder="Registration Number" formControlName="registryNumber">
                      </mat-form-field>
                      <mat-error *ngIf="validateRegistryNumber()">Registration Number is required</mat-error>
                    </div>
                  </div>
                  <mat-checkbox formControlName="registrationAttestation">
                    <span [ngClass]="!licenseForm.get('registrationAttestation').value && licenseForm.get('registrationAttestation').touched? 'text-red-400' : ''"
                          class="text-wrap"><strong *ngIf="!renewing">I understand licensees that are required to be registered with the Oregon Secretary of State must register prior
                            to receiving a license and maintain registration per OAR 333-333-4400 (11).</strong>
                            <strong *ngIf="renewing">I understand that applicants are required to be registered with the Oregon Secretary of State and must ensure they are still registered prior
                              to applying for a renewal and maintain registration per OAR 333-333-4400 (11).</strong></span>
                  </mat-checkbox>

                  <div fxLayout="column" fxLayoutGap="8px">
                    <span class="f-s-16"><strong>Other OPS Licenses or Permits</strong></span>
                    <mat-checkbox formControlName="previousLicense">
                      <span class="text-wrap">Check the box if the applicant applied for or received any other licenses or permits from Oregon Psilocybin Services</span>
                    </mat-checkbox>
                    <div fxLayout="column" *ngIf="this.licenseForm.get('previousLicense').value" [@slideInOut]>
                      <span>Please provide the license id(s) for each Oregon Psilocybin Services license</span>
                      <mat-form-field>
                        <input matInput formControlName="affiliatedLicenses">
                          <mat-placeholder style="color:red" *ngIf="validatePreviousLicense()">Affiliated Licenses*</mat-placeholder>
                        <mat-placeholder style="color:grey" *ngIf="!validatePreviousLicense()">Affiliated Licenses</mat-placeholder>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>

            <mat-card fxFlex="60">
              <mat-card-title>
                <span>Laboratory Address</span>
              </mat-card-title>
              <mat-card-content>
                <div fxLayout="column">
                  <span class="f-s-18"><strong>Physical Address</strong><span style="color: red" *ngIf="licenseForm.get('physicalStreet').value == '' ||
                                                                                                          licenseForm.get('physicalCity').value == '' ||
                                                                                                          licenseForm.get('physicalZip').value == ''">*</span></span>
                  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
                    <mat-form-field fxFlex="30" fxFlex.lt-md="100">
                      <input matInput formControlName="physicalStreet">
                      <mat-placeholder style="color:red" *ngIf="licenseForm.get('physicalStreet').value == ''">Street Apt/Unit/Suite*</mat-placeholder>
                      <mat-placeholder style="color:grey" *ngIf="licenseForm.get('physicalStreet').value != ''">Street Apt/Unit/Suite</mat-placeholder>
                      <mat-error *ngIf="licenseForm.get('physicalStreet').invalid">
                        Please provide a street
                      </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="30" fxFlex.lt-md="100">
                      <input matInput formControlName="physicalCity">
                      <mat-placeholder style="color:red" *ngIf="licenseForm.get('physicalCity').value == ''">City*</mat-placeholder>
                      <mat-placeholder style="color:grey" *ngIf="licenseForm.get('physicalCity').value != ''">City</mat-placeholder>
                      <mat-error *ngIf="licenseForm.get('physicalCity').invalid">
                        Please provide a city
                      </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="40" fxFlex.lt-md="100">
                      <input matInput placeholder="State" value="OR" disabled>
                    </mat-form-field>
                  </div>

                  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" class="m-b-8">
                    <mat-form-field fxFlex="20" fxFlex.lt-md="100">
                      <input matInput formControlName="physicalZip">
                      <mat-placeholder style="color:red" *ngIf="licenseForm.get('physicalZip').value == ''">Zip*</mat-placeholder>
                      <mat-placeholder style="color:grey" *ngIf="licenseForm.get('physicalZip').value != ''">Zip</mat-placeholder>
                      <mat-error *ngIf="licenseForm.get('physicalZip').hasError('required')">
                        Please provide a zipcode
                      </mat-error>
                      <mat-error *ngIf="licenseForm.get('physicalZip').hasError('pattern')">
                        Zipcode should match this format: 99999 or 99999-1234
                      </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="20" fxFlex.lt-md="100">
                      <mat-placeholder style="color:red" *ngIf="licenseForm.get('physicalCounty').value == ''">County*</mat-placeholder>
                      <mat-placeholder style="color:grey" *ngIf="licenseForm.get('physicalCounty').value != ''">County</mat-placeholder>
                      <mat-select formControlName="physicalCounty">
                        <mat-option [disabled]="renewing">-- None --</mat-option>
                        <mat-option [disabled]="renewing" *ngFor="let county of sharedService.counties" value={{county}}>{{county}}</mat-option>
                      </mat-select>
                      <mat-error *ngIf="licenseForm.get('physicalCounty').hasError('required')">
                        Please provide a county
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <span>Please provide a brief description of the premises</span>
                  <div>
                    <mat-form-field fxFlex="75">
                      <textarea matInput placeholder="Describe the Premises" cols="2" formControlName="premisesDescription"></textarea>
                    </mat-form-field>
                  </div>
                </div>

                <div fxLayout="column" class="m-t-8">
                  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="24px">
                    <span class="f-s-18"><strong>Mailing Address</strong><span style="color: red" *ngIf="validateMailingStreet()">*</span></span>
                    <mat-checkbox formControlName="mailingIsPhysical" (change)="mailingIsSame()">
                      Same as Physical
                    </mat-checkbox>
                  </div>
                  <div fxLayout="column" [@slideInOut] *ngIf="!licenseForm.get('mailingIsPhysical').value">
                    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
                      <mat-form-field fxFlex="30" fxFlex.lt-md="100">
                        <input matInput formControlName="mailingStreet"
                          (blur)="validateMailingStreet()">
                          <mat-placeholder style="color:red" *ngIf="licenseForm.get('mailingStreet').value == ''">Street Apt/Unit/Suite*</mat-placeholder>
                        <mat-placeholder style="color:grey" *ngIf="licenseForm.get('mailingStreet').value != ''">Street Apt/Unit/Suite</mat-placeholder>
                        <mat-error *ngIf="validateMailingStreet()">
                          Please provide a street
                        </mat-error>
                      </mat-form-field>

                      <mat-form-field fxFlex="30" fxFlex.lt-md="100">
                        <input matInput formControlName="mailingCity" (blur)="validateMailingCity()">
                        <mat-placeholder style="color:red" *ngIf="licenseForm.get('mailingCity').value == ''">City*</mat-placeholder>
                        <mat-placeholder style="color:grey" *ngIf="licenseForm.get('mailingCity').value != ''">City</mat-placeholder>
                        <mat-error *ngIf="validateMailingCity()">
                          Please provide a city
                        </mat-error>
                      </mat-form-field>

                      <mat-form-field fxFlex="40" fxFlex.lt-md="100">
                        <mat-label style="color:red" *ngIf="licenseForm.get('mailingState').value == ''">State/US territory/freely associated state*</mat-label>
                        <mat-label *ngIf="licenseForm.get('mailingState').value != ''">State/US territory/freely associated state</mat-label>
                        <mat-select formControlName="mailingState" (blur)="validateMailingState()">
                          <mat-option *ngFor="let state of sharedService.states" value={{state}}>{{state}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="validateMailingState()">
                          Please provide a state
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
                      <mat-form-field fxFlex="20" fxFlex.lt-md="100">
                        <input matInput formControlName="mailingZip" (blur)="validateMailingZip()">
                        <mat-placeholder style="color:red" *ngIf="licenseForm.get('mailingZip').value == ''">Zip*</mat-placeholder>
                      <mat-placeholder style="color:grey" *ngIf="licenseForm.get('mailingZip').value != ''">Zip</mat-placeholder>
                        <mat-error *ngIf="validateMailingZip()">
                          Please provide a zipcode
                        </mat-error>
                        <mat-error *ngIf="licenseForm.get('mailingZip').hasError('pattern')">
                          Zipcode should match this format: 99999 or 99999-1234
                        </mat-error>
                      </mat-form-field>

                      <mat-form-field fxFlex="20" fxFlex.lt-md="100"
                        *ngIf="licenseForm.get('mailingState').value === 'OR'">
                        <mat-label style="color:red" *ngIf="licenseForm.get('mailingCounty').value == ''">County*</mat-label>
                      <mat-label *ngIf="licenseForm.get('mailingCounty').value != ''">County</mat-label>
                        <mat-select formControlName="mailingCounty">
                          <mat-option>-- None --</mat-option>
                          <mat-option *ngFor="let county of sharedService.counties" value={{county}}>{{county}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
        <div fxLayout="row" *ngIf="renewing">

          <mat-card fxLayoutGap="8px" fxLayout="column" >
            <div fxLayout="row">
                <div fxLayout="column" fxLayoutGap="8px">
                  <span class="text-wrap" [ngClass]="licenseForm.get('testingLabInformationCorrect').value !== true && licenseForm.get('testingLabInformationCorrect').value !== false? 'text-red-400' : ''">Is all the information on this screen current and correct?</span>
                  <span class="text-wrap"><i>Some information may be outdated or incorrect but you cannot change it on this application.
                    These changes require additional documentation and possibly additional fees. These changes are made by a Change Request.
                    If information is incorrect or outdated, please select "No," below and follow the instructions for a Change Request.</i></span>
                  <mat-radio-group aria-label="Select an option" formControlName="testingLabInformationCorrect" fxLayoutGap="8px">
                    <mat-radio-button [value]="true">Yes</mat-radio-button>
                    <mat-radio-button [value]="false">No</mat-radio-button>
                  </mat-radio-group>
                  <mat-error *ngIf="licenseForm.get('testingLabInformationCorrect').hasError('required')">This field is required</mat-error>
                </div>
            </div>

            <div fxLayout="row" *ngIf="licenseForm.get('testingLabInformationCorrect').value == false">
              <div fxLayout="column" fxFlex="40">
                <mat-form-field>
                  <textarea rows="2" matInput placeholder="Please list the information that is not current and correct" formControlName="testingLabInformationCorrectReason"></textarea>
                  <mat-error *ngIf="validateTestingLabInformationCorrect()">
                    This field is required
                  </mat-error>
                 </mat-form-field>
                 <span>You are required to submit a Change Request to update this information. For more information on Change Requests, please see the TLC Home Page, Change Requests section. This is the first page you see when you log into TLC.</span>
              </div>
            </div>
          </mat-card>
        </div>
      </form>

      <div fxLayout="row" fxLayoutGap="8px" fxFlex="50" fxFlex.lt-md="100" class="p-t-16">
        <button mat-raised-button matStepperPrevious>{{!licenseLocked? "Save and " : ""}}Back</button>
        <button mat-raised-button matStepperNext>{{!licenseLocked? "Save and " : ""}}Continue</button>
      </div>
    </mat-step>

    <!--Applicant Information-->
    <mat-step *ngIf="licenses.length > 0">
      <ng-template matStepLabel>
        <span *ngIf="(applicantsValid && entityApplicantsValid && contactValid && applicantAttestationValid) || licenseLocked"
              matTooltip="Applicant Information"
              matTooltipPosition="below"
              [matTooltipShowDelay]="500">Applicant Information</span>
        <span *ngIf="(!applicantsValid || !entityApplicantsValid || !contactValid || !applicantAttestationValid) && !licenseLocked"
              class="text-red-400"
              matTooltip="Applicant Information Incomplete"
              matTooltipPosition="below"
              [matTooltipShowDelay]="500">Applicant Information Incomplete</span>
      </ng-template>

      <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
        <mat-card fxFlex="50" fxFlex.lt-lg="100" *ngIf="!editingApplicant && !editingEntityApplicant" [@slideInOut]>
          <mat-card-title>
            <span>Manage Applicants</span>
          </mat-card-title>
          <mat-card-content>
            <span *ngIf="!renewing"><strong>An application for a psilocybin laboratory license must identify all individuals and legal entities who qualify
              as applicants as described in OAR 333-333-1010 and OAR 333-333-4030. Applicants are responsible for ensuring all individuals and
              legal entities who meet the definition of an applicant are identified.</strong></span>
            <span *ngIf="renewing"><strong>A renewal application for a psilocybin laboratory license must identify all individuals and legal entities who qualify
              as applicants as described in OAR 333-333-1010 and OAR 333-333-4030. Applicants are responsible for ensuring all individuals and legal entities who
              meet the definition of an applicant are identified. </strong></span>
              <div fxLayout="column" fxLayoutGap="8px" style="padding-top: 1em">
                <span><strong>Applicants are individuals or legal entities who:</strong></span>
                <span class="p-l-24">
                  <strong>• </strong>Hold or control an interest of more than 20 percent in the entity proposed to be licensed;
                </span>
                <span class="p-l-24">
                  <strong>• </strong>Are entitled to receive 20 percent or more of revenue, profits or proceeds from the entity proposed to be licensed; or
                </span>
                <span class="p-l-24">
                  <strong>• </strong>Are entitled to exercise control over the entity proposed to be licensed.
                </span>
              </div>

              <div fxLayout="column" fxLayoutGap="8px" style="padding-top: 1em">
                <span><strong>If a legal entity is an applicant, the following individuals within the legal entity are also
                  applicants:</strong></span>
                <span class="p-l-24">
                  <strong>• </strong>If an applicant is a limited partnership, each general partner in the limited partnership.
                </span>
                <span class="p-l-24">
                  <strong>• </strong>If an applicant is a limited liability company, each manager and managing member of the limited liability company.
                </span>
                <span class="p-l-24">
                  <strong>• </strong>If the applicant is a for-profit corporation, each principal officer of the corporation.
                </span>
                <span class="p-l-24">
                  <strong>• </strong>If the applicant is a non-profit entity, each principal officer of the entity.
                </span>
                <span class="p-l-24">
                  <strong>• </strong>Any individual within the legal entity who meets the definition of applicant in OAR 333-333-
                  1010.
                </span>
              </div>

              <div fxLayout="column" fxLayoutGap="8px" style="padding-top: 1em">
                <span><strong>Guidance for entering applicants on this screen</strong></span>
                <span class="p-l-24">
                  <strong>• </strong>All applicants must be entered on this screen
                </span>
                <span class="p-l-24">
                  <strong>• </strong>If an individual within a legal entity is identified as an applicant, please add
                  them as an individual applicant
                </span>
              </div>

              <div fxLayout="column" fxLayoutGap="8px" style="padding-top: 1em">
                <span><strong>Individual and Legal Entity Applicant Information Forms</strong></span>
                <span class="p-l-24">
                  Each individual and legal entity applicant must complete an applicant information form where they will provide their applicant information,
                  agree to be added as an applicant to the application, agree to the designated point of contact for the application, and complete attestations.
                  Applicants are responsible for completing and ensuring all applicant information forms are uploaded on the Applicant Information tab,
                  in the Individual Applicants and/or Entity Applicants sections. Individual and legal entity applicant information forms can be found on the
                  <a href="https://www.oregon.gov/oha/PH/PREVENTIONWELLNESS/Pages/Psilocybin-License-Lab-Testing.aspx" target="_blank" rel="noreferrer noopener" style="word-break: break-all;
                  white-space: normal;">Oregon Psilocybin Services - Apply for a Laboratory License webpage.</a>.
                </span>
              </div>

              <form [formGroup]="licenseForm" style="padding-top: 1em">
                <div fxLayout="column" fxLayoutGap="8px">
                  <mat-checkbox formControlName="applicantFeeAttestation">
                    <span [ngClass]="!licenseForm.get('applicantFeeAttestation').value &&
                                     !applicantAttestationValid? 'text-red-400' : ''"
                          class="text-wrap">
                      <strong>I understand Oregon Psilocybin Services will charge a change fee of $250 per applicant for any change
                        to a previously approved license that results in the addition of an applicant. This change fee applies regardless of whether the licensee
                        requests the change at renewal or during the term of their license.</strong></span>
                  </mat-checkbox>

                  <mat-checkbox formControlName="applicantDenialAttestation">
                    <span [ngClass]="!licenseForm.get('applicantDenialAttestation').value &&
                                     !applicantAttestationValid? 'text-red-400' : ''"
                          class="text-wrap">
                      <strong>I understand Oregon Psilocybin Services may deny an application if a person identified as an applicant for
                        the entity proposed to be licensed does not meet the definition of applicant in OAR 333-333-1010; or a person who meets the definition of
                        applicant in OAR 333-333-1010 has not been disclosed on the application.</strong></span>
                  </mat-checkbox>
                  <div fxLayout="row" *ngIf="renewing">
                    <mat-card fxLayoutGap="8px" fxLayout="column" >
                      <div fxLayout="row">
                          <div fxLayout="column" fxLayoutGap="8px">
                            <span class="text-wrap" [ngClass]="licenseForm.get('applicantsCorrect').value != false && licenseForm.get('applicantsCorrect').value != true? 'text-red-400' : ''">Is all the information on this screen current and correct, including the applicants listed?</span>
                            <span class="text-wrap"><i>Some information may be outdated or incorrect but you cannot change it on this application.
                              These changes require additional documentation and possibly additional fees. These changes are made by a Change Request.
                              If information is incorrect or outdated, please select "No," below and follow the instructions for a Change Request.</i></span>
                            <mat-radio-group aria-label="Select an option" formControlName="applicantsCorrect" fxLayoutGap="8px">
                              <mat-radio-button [value]="true">Yes</mat-radio-button>
                              <mat-radio-button [value]="false">No</mat-radio-button>
                            </mat-radio-group>
                            <mat-error *ngIf="licenseForm.get('applicantsCorrect').hasError('required')">This field is required</mat-error>
                          </div>
                      </div>
                      <div fxLayout="row" *ngIf="licenseForm.get('applicantsCorrect').value == false">
                        <div fxLayout="column">
                          <mat-form-field>
                            <textarea rows="2" matInput placeholder="Please list the information that is not current and correct" formControlName="applicantsCorrectReason"></textarea>
                          </mat-form-field>
                           <span *ngIf="!licenseForm.get('applicantsCorrect').value && (licenseForm.get('applicantsCorrectReason').value == '' || licenseForm.get('applicantsCorrectReason').value == null)" style="color:red">
                            This field is required
                          </span>
                           <span>You are required to submit a Change Request to update this information. For more information on Change Requests, please see the TLC Home Page, Change Requests section. This is the first page you see when you log into TLC.</span>
                        </div>
                      </div>
                    </mat-card>
                  </div>
                </div>
              </form>

          </mat-card-content>
        </mat-card>

        <div fxLayout="column" [fxFlex]="editingApplicant || editingEntityApplicant? '100': '50'" fxFlex.lt-lg="100">
          <mat-card *ngIf="!editingApplicant && !editingEntityApplicant" [@slideInOut]>
            <mat-card-title>
              <span *ngIf="contactValid">Primary Point of Contact</span>
              <span class="text-red-400" *ngIf="!contactValid">Primary Point of Contact Incomplete</span>
            </mat-card-title>
            <mat-card-content>
              <div fxLayout="column" fxLayoutGap="8px">

                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">
                  <div fxLayout="column" fxFlex="50" fxFlex.lt-md="100">
                    <span class="f-s-16"><strong>Contact Name</strong></span>
                    {{license.primaryContactName}}
                  </div>
                  <div fxLayout="column" fxFlex="50" fxFlex.lt-md="100">
                    <span class="f-s-16"><strong>Contact Phone</strong></span>
                    {{license.primaryContactNumber | mask: '(000) 000-0000 000000'}}
                  </div>
                </div>

                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">
                  <div fxLayout="column" fxFlex="50" fxFlex.lt-md="100">
                    <span class="f-s-16"><strong>Email Address</strong></span>
                    <span>{{license.primaryContactEmail}}</span>
                  </div>
                  <div fxLayout="column" fxFlex="50" fxFlex.lt-md="100">
                    <span class="f-s-16"><strong>Preferred Language</strong></span>
                    <span>{{license.language}}</span>
                  </div>
                </div>

              </div>
            </mat-card-content>
          </mat-card>

          <app-applicant *ngIf="license.id > 0"
                         [fxFlex]="editingApplicant? '100': '50'"
                         fxFlex.lt-md="100"
                         [@slideInOut] 
                         [ngStyle]="{'display': !editingEntityApplicant? '' : 'none'}"
                         [licenseId]="license.id"
                         [status]="license.status"
                         [licenseType]="sharedService.testingLab"
                         [documentTypes]="documentTypes"
                         [primaryContactId]="license.primaryContactId"
                         tableColor="orange"
                         [renewal]="renewing"
                         (notifyParent)="editApplicant($event)"
                         (setPointOfContact)="setPointOfContact($event)"
                         [cardColor]="cardColor"
                         [licenseLocked]="licenseLocked">
          </app-applicant>
          <app-entity-applicant *ngIf="!editingApplicant && license.id > 0"
                                [fxFlex]="editingEntityApplicant? '100': '50'"
                                fxFlex.lt-md="100"
                                [@slideInOut]
                                [licenseId]="license.id"
                                [status]="license.status"
                                [licenseType]="sharedService.testingLab"
                                tableColor="orange"
                                [renewal]="renewing"
                                [cardColor]="cardColor"
                                [documentTypes]="documentTypes"
                                [licenseLocked]="licenseLocked"
                                [individualApplicants]="individualApplicants?.applicants"
                                (notifyParent)="editEntityApplicant($event)">
          </app-entity-applicant>
        </div>
      </div>

      <div fxLayout="row" fxLayoutGap="8px" fxFlex="50" fxFlex.lt-md="100" class="p-t-16">
        <button *ngIf="!editingApplicant && !editingEntityApplicant" mat-raised-button matStepperPrevious>{{!licenseLocked? "Save and " : ""}}Back</button>
        <button *ngIf="!editingApplicant && !editingEntityApplicant" mat-raised-button matStepperNext>{{!licenseLocked? "Save and " : ""}}Continue</button>
      </div>
    </mat-step>

    <!--Financial Interest-->
    <mat-step *ngIf="licenses.length > 0">
      <ng-template matStepLabel>
        <span *ngIf="financialAttestationValid || licenseLocked"
              matTooltip="Financial Interest"
              matTooltipPosition="below"
              [matTooltipShowDelay]="500">Financial Interest</span>
        <span *ngIf="!financialAttestationValid && !licenseLocked"
              matTooltip="Financial Interest Incomplete"
              matTooltipPosition="below"
              [matTooltipShowDelay]="500"
              class="text-red-400">Financial Interest Incomplete</span>
      </ng-template>

      <mat-card>
        <mat-card-title>
          <span>Financial Interest Disclosures</span>
        </mat-card-title>
        <mat-card-content>
          <div fxLayout="column" fxLayoutGap="8px" style="padding-top: 1em">
            <span><strong>Restriction on financial interests on multiple licenses (ORS 475A.280)</strong></span>
            <span>An individual may not have a financial interest in:</span>
            <span style="padding-left: 1em"><strong>• </strong>More than one psilocybin product manufacturer; or;</span>
            <span style="padding-left: 1em"><strong>• </strong>More than five psilocybin service center operators Financial Interests (OAR 333-333-4040)</span>
            <span>Applicants and licensees must create and maintain complete lists of all individuals and legal
              entities that hold a financial interest in the laboratory, including contact information for each individual
              or entity and a description of their financial interest. Applicants and licensees must provide the information
              required by this section to Oregon Psilocybin Services within 15 calendar days of the written request for such information.
              </span>

              <span><strong>If a legal entity holds a financial interest, the following individuals within the legal entity also hold a financial interest:</strong></span>
              <span style="padding-left: 1em"><strong>• </strong>For limited partnerships, each general partner in the limited partnership.</span>
              <span style="padding-left: 1em"><strong>• </strong>For limited liability companies, each manager and managing member of the limited liability	company.</span>
              <span style="padding-left: 1em"><strong>• </strong>For for-profit corporations, each principal officer of the corporation.</span>
              <span style="padding-left: 1em"><strong>• </strong>For non-profit entities, each principal officer of the entity.</span>
            <form [formGroup]="licenseForm" style="padding-top: 1em">
              <div fxLayout="column" fxLayoutGap="12px">
                <div fxLayout="row">
                  <mat-checkbox formControlName="financialAttestation">
                    <span [ngClass]="!licenseForm.get('financialAttestation').value &&
                                 !financialAttestationValid? 'text-red-400' : ''"
                      class="text-wrap"><strong>I have read, understand, and agree to adhere to the financial interest requirements outlined in ORS 475A and OAR, Chapter 333.
                        I understand every individual and legal entity applicant in the proposed laboratory to be licensed must comply with the requirements of ORS 475A.280, Restriction on
                        financial interests in multiple licenses. I understand Oregon Psilocybin Services shall deny an application for a laboratory license if an individual or legal entity that holds a
                        financial interest in the entity proposed to be licensed holds a financial interest in five or more service center licenses.</strong></span>
                  </mat-checkbox>
                </div>
                <div fxLayout="row" *ngIf="renewing">

                  <mat-card fxLayoutGap="8px" fxLayout="column" >
                    <div fxLayout="row">
                        <div fxLayout="column" fxLayoutGap="8px">
                          <span class="text-wrap" [ngClass]="licenseForm.get('financialInterestCorrect').value !== true && licenseForm.get('financialInterestCorrect').value !== false? 'text-red-400' : ''">Is all the information on this screen current and correct?</span>
                          <span class="text-wrap"><i>Some information may be outdated or incorrect but you cannot change it on this application.
                            These changes require additional documentation and possibly additional fees. These changes are made by a Change Request.
                            If information is incorrect or outdated, please select "No," below and follow the instructions for a Change Request.</i></span>
                          <mat-radio-group aria-label="Select an option" formControlName="financialInterestCorrect" fxLayoutGap="8px">
                            <mat-radio-button [value]="true">Yes</mat-radio-button>
                            <mat-radio-button [value]="false">No</mat-radio-button>
                          </mat-radio-group>
                          <mat-error *ngIf="licenseForm.get('financialInterestCorrect').hasError('required')">This field is required</mat-error>
                        </div>
                    </div>

                    <div fxLayout="row" *ngIf="licenseForm.get('financialInterestCorrect').value == false">
                      <div fxLayout="column" fxFlex="40">
                        <mat-form-field>
                          <textarea rows="2" matInput placeholder="Please list the information that is not current and correct" formControlName="financialInterestCorrectReason"></textarea>
                          <span class="f-s-12" style="color: red" *ngIf="!validateFinancialInterestCorrect()">
                            This field is required*
                          </span>
                         </mat-form-field>
                         <span>You are required to submit a Change Request to update this information. For more information on Change Requests, please see the TLC Home Page, Change Requests section. This is the first page you see when you log into TLC.</span>
                      </div>
                    </div>
                  </mat-card>
                </div>
              </div>
            </form>
          </div>
        </mat-card-content>
      </mat-card>

      <div fxLayout="row" fxLayoutGap="8px" fxFlex="50" fxFlex.lt-md="100" class="p-t-16">
        <button mat-raised-button matStepperPrevious>{{!licenseLocked? "Save and " : ""}}Back</button>
        <button mat-raised-button matStepperNext>{{!licenseLocked? "Save and " : ""}}Continue</button>
      </div>
    </mat-step>

    <!--Documents-->
    <mat-step *ngIf="licenses.length > 0">
      <ng-template matStepLabel>
        <span *ngIf="documentsValid || licenseLocked" matTooltip="Documents" matTooltipPosition="below" [matTooltipShowDelay]="500">Documents</span>
        <span *ngIf="!documentsValid && !licenseLocked" class="text-red-400"
          matTooltip="Documents Incomplete" matTooltipPosition="below" [matTooltipShowDelay]="500">Documents Incomplete</span>
      </ng-template>

      <form [formGroup]="documentsForm">
        <div fxLayout="column" fxLayoutGap="8px">
          <div fxLayout="row" fxLayout.lt-lg="column">
            <mat-card fxFlex="100">
              <mat-card-title *ngIf="!renewing">
                The following documentation is required to apply for a Psilocybin Laboratory License
              </mat-card-title>
              <mat-card-title *ngIf="renewing">
                Upload the Following Documentation to apply for a Psilocybin Laboratory License
              </mat-card-title>
              <mat-card-content>
                <div fxLayout="column" fxLayoutGap="8px" class="p-b-8" *ngIf="!renewing">
                  <span class="p-l-24"><strong>• </strong>ORELAP Accreditation</span>
                  <span class="p-l-24"><strong>• </strong>Premises Plan Including Boundaries and Camera Plan</span>
                  <span class="p-l-24"><strong>• </strong>Social Equity Plan</span>
                  <span class="p-l-24"><strong>• </strong>Proposed Premises Pictures</span>
                </div>
                <div fxLayout="column" fxLayoutGap="8px" class="p-b-8" *ngIf="renewing">
                  <span class="p-l-24"><strong>• </strong>Social Equity Plan Evaluation</span>
                  <span class="p-l-24"><strong>• </strong>Updated Social Equity Plan (not required)</span>
                  <span class="p-l-24"><strong>• </strong>ORELAP Accreditation (not required)</span>
                  <span class="p-l-24"><strong>• </strong>Premises Plan Including Boundaries and Camera Plan (not required)</span>
                  <span class="p-l-24"><strong>• </strong>Updated premises pictures (not required at this time but will be required before renewal site inspection)</span>
                </div>
                <div fxLayout="column" fxLayoutGap="8px" style="padding-bottom: 1em" *ngIf="!renewing">
                  <span><strong>Proposed Premises Pictures</strong></span>
                  <div fxLayout="column" fxLayoutGap="8px">
                    <span>Laboratories must upload pictures of their proposed premises as part of their application. Pictures submitted must clearly show the following:</span>
                    <span style="padding-left: 1em"><strong>• </strong>All ingress and egress of a proposed licensed premises</span>
                    <span style="padding-left: 1em"><strong>• </strong>Psilocybin secure storage area</span>
                    <span style="padding-left: 1em"><strong>• </strong>Psilocybin secure waste storage</span>
                    <span style="padding-left: 1em"><strong>• </strong>All limited access areas</span>
                    <span style="padding-left: 1em"><strong>• </strong>Client administration area</span>
                    <span style="padding-left: 1em"><strong>• </strong>Outdoor areas included on proposed premises</span>
                    <span style="padding-left: 1em"><strong>• </strong>Secured storage of video surveillance equipment (not fixed cameras)</span>
                    <span style="padding-left: 1em"><strong>• </strong>All four internal corners of the proposed premises</span>
                    <span style="padding-left: 1em"><strong>• </strong>All four external corners of the proposed premises</span>
                    <span>Please include additional pictures of any areas that are potentially hazardous, unsafe, or may not meet rules requirements.
                      Submitting a complete catalog of pictures will ensure we are able to review your proposed premises in a timely manner and work with you to make any potentially necessary changes.</span>
                    <span style="padding-left: 1em"><strong>* </strong>Pictures may be submitted after initial submission of application but are required prior to scheduling a site inspection</span>
                    <span style="padding-left: 1em"><strong>** </strong>Do not include any people in your pictures</span>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>

          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxFlex="100">
            <mat-card fxFlex="50" fxFlex.lt-lg="100">
              <mat-card-title>
                Upload Supporting Documents
              </mat-card-title>
              <mat-card-content>
                <div fxLayout="column" fxLayoutGap="8px">
                  <span class="f-2-14">For each document select the requirements it verifies</span>
                  <div fxLayout="column" fxLayoutGap="8px" class="p-t-8">

                    <div fxLayout="column">
                      <mat-checkbox formControlName="orelap" [ngStyle]="{ 'opacity': renewing && !missingTypes[orelapType] ? 0.5 : 1 }">
                        <span class="text-wrap"
                              [ngClass]="!documentsValid && missingTypes[orelapType] ? 'text-red-400' : ''">
                          ORELAP Accreditation
                        </span>
                      </mat-checkbox>

                      <mat-checkbox formControlName="premisesSketch" [ngStyle]="{ 'opacity': renewing && !missingTypes[premisesType] ? 0.5 : 1 }">
                        <span class="text-wrap"
                              [ngClass]="!documentsValid && missingTypes[premisesType] ? 'text-red-400' : ''">
                          Premises Plan Including Boundaries and Camera Plan
                        </span>
                      </mat-checkbox>

                      <mat-checkbox formControlName="premisePicture" [ngStyle]="{ 'opacity': renewing && !missingTypes[premisePictureType] ? 0.5 : 1 }" *ngIf="!renewing">
                        <span class="text-wrap"
                          [ngClass]="!documentsValid && missingTypes[premisePictureType] ? 'text-red-400' : ''">
                          Proposed Premises Pictures
                        </span>
                      </mat-checkbox>

                      <mat-checkbox formControlName="socialEquityPlan" *ngIf="!renewing">
                        <span class="text-wrap"
                              [ngClass]="!documentsValid && missingTypes[socialEquityType] ? 'text-red-400' : ''">
                          Social Equity Plan
                        </span>
                      </mat-checkbox>


                      <mat-checkbox formControlName="updatedEquityPlan" *ngIf="renewing">
                        <span [ngClass]="!documentsValid && missingTypes[updatedEquityPlanType]? 'text-red-400' : ''">
                          Updated Social Equity Plan
                        </span>
                      </mat-checkbox>

                      <mat-checkbox formControlName="equityPlanEvaluation" *ngIf="renewing">
                        <span [ngClass]="!documentsValid && missingTypes[equityPlanEvaluationType]? 'text-red-400' : ''">
                          Social Equity Plan Evaluation
                        </span>
                      </mat-checkbox>

                      <mat-checkbox formControlName="updatedPremisePicture" *ngIf="renewing">
                        <span [ngClass]="!documentsValid && missingTypes[updatedPremisePictureType]? 'text-red-400' : ''">
                          Updated premises pictures
                        </span>
                      </mat-checkbox>
                    </div>

                    <input type="file" accept=".doc,.docx,.rtf,.xls,.xlsx,.pdf,.txt,.png,.jpg,.jpeg,.bmp,.mp4" class="file-input" (change)="uploadLicenseDocument($event)" #fileUpload />
                    <div fxLayout="row" fxLayoutGap="8px">
                      <div class="file-upload">
                        <button [disabled]="!documentsForm.get('premisesSketch').value &&
                                            !documentsForm.get('orelap').value &&
                                            !documentsForm.get('socialEquityPlan').value &&
                                            !documentsForm.get('premisePicture').value &&
                                            !documentsForm.get('updatedEquityPlan').value &&
                                            !documentsForm.get('equityPlanEvaluation').value &&
                                            !documentsForm.get('updatedPremisePicture').value"
                                mat-raised-button color="primary" class="upload-btn" (click)="fileUpload.click()">
                          Browse
                          <mat-icon>attach_file</mat-icon>
                        </button>
                      </div>
                    </div>

                  </div>
                </div>
              </mat-card-content>
            </mat-card>

            <mat-card fxFlex="50" fxFlex.lt-lg="100">
              <mat-card-title>
                <span>Current Documents</span>
              </mat-card-title>
              <mat-card-content>
                <div fxLayout="column">
                  <div fxLayout="row" *ngIf="!sharedService.mobile">
                    <span class="f-s-16" fxFlex="30" fxFlex.lt-lg="40"><strong>File</strong></span>
                    <span class="f-s-16"><strong>Requirement</strong></span>
                  </div>
                  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center" fxLayoutAlign.lt-md=""
                    *ngFor="let document of this.license.licenseDocuments">
                    <span fxFlex="30" fxFlex.lt-lg="40" class="text-indigo-A700" style="cursor: pointer"
                      (click)="downloadDocument(document.id, document.name)" title="{{document.name}}">{{document.name.length > 15 ? document.name.substring(0,15) + "..." : document.name}}</span>
                    <div fxFlex="70" fxFlex.lt-lg="60" fxLayout="row" fxLayoutAlign="start center">
                      <div fxFlex="40" fxFlex.lt-lg="90" fxLayout="column">
                        <span *ngFor="let type of document.types">
                          {{documentTypeReference[type]}}
                        </span>
                      </div>
                      <button *ngIf="!licenseLocked" type="button" mat-icon-button class="m-8" (click)="deleteFile(document.id, document.name)"
                        color="warn" aria-label="Delete">
                        <mat-icon>delete</mat-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </form>

      <div fxLayout="row" fxLayoutGap="8px" fxFlex="50" fxFlex.lt-md="100" class="p-t-16">
        <button mat-raised-button matStepperPrevious>Back</button>
        <button mat-raised-button matStepperNext>Continue</button>
      </div>
    </mat-step>

    <!--Fees-->
    <mat-step *ngIf="licenses.length > 0">
      <ng-template matStepLabel>
        <span matTooltip="Fee Information" matTooltipPosition="below" [matTooltipShowDelay]="500">Fee Information</span>
      </ng-template>
      <div fxLayout="column" fxLayoutGap="8px" fxFlex="50" fxFlex.lt-lg="100">
        <span *ngIf="sharedService.tablet" class="f-s-18">Fees</span>
        <app-payment-notice [canPay]="false" entityType="TestingLabLicense" feeType="ApplicationFee" [renewal]="renewing"></app-payment-notice>
        <div fxLayout="row" fxLayoutGap="8px" fxFlex="50" fxFlex.lt-md="100">
          <button mat-raised-button matStepperPrevious>Back</button>
          <button mat-raised-button matStepperNext>Continue</button>
        </div>
      </div>
    </mat-step>

    <!--Review-->
    <mat-step *ngIf="licenses.length > 0">
      <ng-template matStepLabel>
        <span matTooltip="Review and Submit" matTooltipPosition="below" [matTooltipShowDelay]="500">Review and Submit</span>
      </ng-template>

      <div fxLayout="column">
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">
          <mat-card fxFlex="50" fxFlex.lt-md="100">
            <mat-card-title>
              <span *ngIf="licenseForm.valid || licenseForm.disabled">Laboratory Information</span>
              <span *ngIf="!licenseForm.valid && !licenseForm.disabled" class="text-red-400">Laboratory Information Incomplete</span>
            </mat-card-title>
            <mat-card-content>
              <div fxLayout="column" fxLayoutGap="8px" style="font-size: large">
                <div fxLayout="row" fxLayout.lt-lg="column">
                  <span fxFlex="30" fxFlex.lt-lg="100">
                    <strong>Operational Name</strong>
                  </span>
                  <span fxFlex="70" fxFlex.lt-lg="100">
                    {{license.entityName}}
                  </span>
                </div>
                <div fxLayout="row" fxLayout.lt-lg="column">
                  <span fxFlex="30" fxFlex.lt-lg="100">
                    <strong>Website</strong>
                  </span>
                  <span fxFlex="70" fxFlex.lt-lg="100">
                    {{license.entityWebsite}}
                  </span>
                </div>
                <span class="text-red-400" *ngIf="!contactValid">Primary Contact is Required</span>
                <div fxLayout="row" fxLayout.lt-lg="column">
                  <span fxFlex="30" fxFlex.lt-lg="100">
                    <strong>Primary Contact</strong>
                  </span>
                  <span fxFlex="70" fxFlex.lt-lg="100">
                    {{license.primaryContactName}}
                  </span>
                </div>
                <div fxLayout="row" fxLayout.lt-lg="column">
                  <span fxFlex="30" fxFlex.lt-lg="100">
                    <strong>Contact Phone</strong>
                  </span>
                  <span fxFlex="70" fxFlex.lt-lg="100">
                    {{license.primaryContactNumber | mask: '(000) 000-0000 000000'}}
                  </span>
                </div>
                <div fxLayout="row" fxLayout.lt-lg="column">
                  <span fxFlex="30" fxFlex.lt-lg="100">
                    <strong>Contact Email</strong>
                  </span>
                  <span fxFlex="70" fxFlex.lt-lg="100">
                    {{license.primaryContactEmail}}
                  </span>
                </div>
                <div fxLayout="row" fxLayout.lt-lg="column">
                  <span fxFlex="30" fxFlex.lt-lg="100">
                    <strong>Preferred Language</strong>
                  </span>
                  <span fxFlex="70" fxFlex.lt-lg="100">
                    {{license.language}}
                  </span>
                </div>

                <div fxLayout="column" fxLayoutGap="8px" class="m-t-8">
                  <div fxLayout="row" fxLayout.lt-lg="column">
                    <span fxFlex="30" fxFlex.lt-lg="100"><strong>Headquarters Address</strong></span>
                    <div fxLayout="row" fxLayoutGap="16px">
                      {{license.physicalStreet}}
                      {{license.physicalCity}}
                      {{license.physicalState}}
                      {{license.physicalZip}}
                      {{license.physicalCounty}}
                    </div>
                  </div>
                  <span>{{license.premisesDescription}}</span>
                  <div fxLayout="row" fxLayout.lt-lg="column">
                    <span fxFlex="30" fxFlex.lt-lg="100"><strong>Mailing Address</strong></span>
                    <div fxLayout="row" fxLayoutGap="16px" *ngIf="!license.mailingIsPhysical">
                      {{license.mailingStreet}}
                      {{license.mailingCity}}
                      {{license.mailingState}}
                      {{license.mailingZip}}
                      {{license.mailingCounty}}
                    </div>
                    <span *ngIf="license.mailingIsPhysical">Same as physical</span>
                  </div>
                </div>

                <mat-checkbox [checked]="license.businessInOregon" disabled>
                  <span class="text-wrap">Laboratory is registered as a business with the Secretary of State (SOS) in Oregon</span>
                </mat-checkbox>
                <div fxLayout="column" *ngIf="license.businessInOregon">
                  <span><strong>Oregon Secretary of State Registry Number</strong></span>
                  <span>{{license.secretaryofStateNumber}}</span>
                </div>
                <mat-checkbox [checked]="license.registeredBusiness" disabled *ngIf="!license.businessInOregon">
                  <span class="text-wrap">Laboratory is registered as a business in another state, US territory or a freely associated state</span>
                </mat-checkbox>
                <div fxLayout="column" *ngIf="!license.businessInOregon && license.registeredBusiness ">
                  <span><strong>Location Registered</strong></span>
                  <span>{{license.registeredIn}}</span>
                </div>
                <div fxLayout="column" *ngIf="!license.businessInOregon && license.registeredBusiness ">
                  <span><strong>Registration Number</strong></span>
                  <span>{{license.registryNumber}}</span>
                </div>

                <mat-checkbox [checked]="license.previousLicense" disabled>
                  <span class="text-wrap">Laboratory has applied for or received any other Oregon Psilocybin licenses</span>
                </mat-checkbox>
                <span><strong>Other Licenses</strong></span>
                <span>{{license.affiliatedLicenses}}</span>
              </div>
            </mat-card-content>
          </mat-card>

          <div fxLayout="column" fxFlex="50" fxFlex.lt-md="100">
            <mat-card>
              <mat-card-title>
                <div fxLayout="column">
                  <span>Current Documents</span>
                  <span *ngIf="!documentsValid" class="text-red-400">Not All Requirements Met</span>
                </div>
              </mat-card-title>
              <mat-card-content>
                <div fxLayout="column">
                  <span class="text-red-400" *ngIf="missingTypes[orelapType]">{{documentTypeReference[orelapType]}} required</span>
                  <span class="text-red-400" *ngIf="missingTypes[premisesType]">{{documentTypeReference[premisesType]}} required</span>
                  <span class="text-red-400" *ngIf="missingTypes[socialEquityType]">{{documentTypeReference[socialEquityType]}} required</span>
                  <span class="text-red-400" *ngIf="missingTypes[premisePictureType]">{{documentTypeReference[premisePictureType]}} required</span>
                  <span class="text-red-400" *ngIf="missingTypes[equityPlanEvaluationType]">{{documentTypeReference[equityPlanEvaluationType]}} required</span>
                </div>
                <div fxLayout="column" style="font-size: large">
                  <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutAlign="start center" fxLayoutAlign.lt-lg=""
                    *ngFor="let document of this.license.licenseDocuments">
                    <span fxFlex="30" fxFlex.lt-lg="" class="text-indigo-A700" style="cursor: pointer"
                      (click)="downloadDocument(document.id, document.name)" title="{{document.name}}">{{document.name.length > 15 ? document.name.substring(0,15) + "..." : document.name}}</span>
                    <div fxFlex="70" fxFlex.lt-lg="" fxLayout="row" fxLayoutAlign="start center">
                      <div fxLayout="column">
                        <span *ngFor="let type of document.types">
                          {{documentTypeReference[type]}}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>

            <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
              <mat-card fxFlex="50" fxFlex.lt-lg="100">
                <mat-card-title>
                  <span *ngIf="applicantsValid">Individual Applicants</span>
                  <span *ngIf="!applicantsValid" class="text-red-400">Individual Applicants Incomplete</span>
                </mat-card-title>
                <mat-card-content>
                  <div fxLayout="column" style="font-size: large">
                    <div fxLayout="row" fxLayoutGap="8px" *ngFor="let applicant of individualApplicants?.applicants">
                      <span>{{applicant.legalFirstName}}</span>
                      <span>{{applicant.legalMiddleName}}</span>
                      <span>{{applicant.legalLastName}}</span>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>

              <mat-card fxFlex="50" fxFlex.lt-lg="100">
                <mat-card-title>
                  <span *ngIf="entityApplicantsValid">Entity Applicants</span>
                  <span *ngIf="!entityApplicantsValid" class="text-red-400">Entity Applicants Incomplete</span>
                </mat-card-title>
                <mat-card-content>
                  <div fxLayout="column" style="font-size: large">
                    <div fxLayout="row" fxLayoutGap="8px" *ngFor="let applicant of entityApplicants?.applicants">
                      <span>{{applicant.entityName}}</span>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>

            <mat-card>
              <mat-card-title>
                Sign and Submit
              </mat-card-title>
              <mat-card-content>
                <form [formGroup]="verifyForm">
                  <div fxLayout="column" fxLayoutGap="8px">
                    <span class="p-l-24">
                      <strong>• </strong>I certify the information provided in this application is true and complete to the best of my knowledge. I understand falsifying an application, supplying misleading information, or withholding information is grounds for denial of my application or revocation of my license.
                    </span>
                    <span class="p-l-24">
                      <strong>• </strong>I understand I am responsible for making my own determination regarding the legal risks associated with a Schedule 1 substance under the Federal Controlled Substances Act.
                    </span>
                    <span class="p-l-24">
                      <strong>• </strong>I understand laboratory licenses are only valid for the licensed premises and are only issued to the individuals or entities listed on the application or subsequently approved by the Authority.
                    </span>
                    <span class="p-l-24">
                      <strong>• </strong>
                      I understand the information provided in application materials, including applicant name, is subject to disclosure
                      under Oregon's public records laws and that certain information may be posted on OHA's website for purposes of license verification.
                    </span>
                    <span class="p-l-24">
                      <strong>• </strong>I understand all individual applicants must complete and pass a background check as a condition of licensure.
                    </span>
                    <span class="p-l-24">
                      <strong>• </strong>I understand individual applicants are required to obtain a psilocybin worker permit if they participate in activities outlined in OAR 333-333-4070 (1) (a), (b), (c), (d).
                    </span>
                    <span class="p-l-24">
                      <strong>• </strong>I understand and will adhere to the requirements for Notification of Changes as outlined in OAR 333-333-4200.
                    </span>
                    <span class="p-l-24">
                      <strong>• </strong>I understand Oregon Psilocybin Services will charge a change fee of $250 per inspection for any change to a previously approved license that requires an inspection of the licensed premises. This change fee applies regardless of whether the licensee requests the change at renewal or during the term of their license.
                    </span>
                    <span class="p-l-24">
                      <strong>• </strong>I have read, understand, and agree to adhere to the Oregon Psilocybin Services Revised Statutes (ORS) Chapter 475A, Oregon Administrative Rules (OAR) Chapter 333, Division 333, as well as any other applicable statutes or laws.
                    </span>
                    <span class="p-l-24">
                      <strong>• </strong>I understand that failure to adhere to these statutes and rules may result in my application being denied or disciplinary action taken against any future licenses issued by Oregon Psilocybin Services.
                    </span>
                    <span class="p-l-24">
                      <strong>• </strong>I certify the proposed licensed premise meets the location requirements outlined in OAR 333-333-4300.
                    </span>
                    <span class="p-l-24">
                      <strong>• </strong>I understand a laboratory licensee may not perform any required psilocybin sampling or testing using any sampling or testing methods or equipment not permitted under the laboratory’s accreditation through the Oregon Environmental Laboratory Accreditation Program.
                    </span>
                    <span class="p-l-24">
                      <strong>• </strong>I understand the laboratory license has a term of one year from the effective date of licensure. If I wish to renew the license after that term, a renewal application must be submitted.
                    </span>
                    <span class="p-l-24">
                      <strong>• </strong>I understand a license will not be issued until the license fee payment is paid and cleared. A licensee may not operate until the effective date of licensure.
                    </span>
                    <span class="p-l-24" *ngIf="showRequiredApplicantDataAttestation(license.applicationDate)">
                      <strong>• </strong>Applicant(s) understand and will comply with the requirement to complete and submit the Required 303 Applicant and Licensee REAL & SOGI
                      Data form in accordance with ORS 475A.374.
                    </span>
                    <span class="p-l-24">
                      <strong>• </strong>I understand the laboratory application and annual license fees are non-refundable.
                    </span>
                    <mat-checkbox formControlName="attestation">
                      <span style="white-space: normal;" [ngStyle]="{'color': verifyForm.get('attestation').hasError('required')? 'red' : ''}">I have read and I understand the above statements.</span>
                    </mat-checkbox>

                    <mat-radio-group aria-label="Select an option" formControlName="directoryConsent" fxLayoutGap="16px" style="padding-top: 1em">
                      <div fxLayout="column" fxLayoutGap="16px">
                        <div fxLayout="row">
                          <mat-radio-button [value]="true">
                            <span class="text-wrap">
                              I consent to having my license name, license type, and the contact information selected below listed in the voluntary OPS Licensee Directory.
                            </span>
                          </mat-radio-button>
                        </div>

                        <div fxLayout="column" style="padding-left: 1em" *ngIf="verifyForm.get('directoryConsent').value">
                          <div fxLayou="row" fxLayoutGap="5px">
                            <mat-checkbox formControlName="directoryConsentWebsite">
                              <span style="white-space: normal;">Website:</span>
                            </mat-checkbox>
                            <mat-form-field *ngIf="verifyForm.get('directoryConsentWebsite').value">
                              <mat-label>
                                Website
                              </mat-label>
                              <input matInput formControlName="directoryWebsite">
                              <mat-error>
                                <span *ngIf="verifyForm.get('directoryWebsite').hasError('required')">Directory website URL is required</span>
                                <span *ngIf="verifyForm.get('directoryWebsite').hasError('pattern')">Please enter a valid URL (http:// or https://)</span>
                              </mat-error>
                            </mat-form-field>
                          </div>

                          <div fxLayou="row" fxLayoutGap="5px">
                            <mat-checkbox formControlName="directoryConsentEmail">
                              <span style="white-space: normal;">Email:</span>
                            </mat-checkbox>
                            <mat-form-field *ngIf="verifyForm.get('directoryConsentEmail').value">
                              <mat-label>
                                Email
                              </mat-label>
                              <input matInput formControlName="directoryEmail">
                              <mat-error *ngIf="validateDirectoryConsentEmail()">
                                This field is required
                              </mat-error>
                            </mat-form-field>
                          </div>

                          <div fxLayou="row" fxLayoutGap="5px">
                            <mat-checkbox formControlName="directoryConsentPhone">
                              <span style="white-space: normal;">Phone Number:</span>
                            </mat-checkbox>
                            <mat-form-field *ngIf="verifyForm.get('directoryConsentPhone').value">
                              <mat-label>
                                Phone number
                              </mat-label>
                              <input matInput formControlName="directoryPhone" mask="(000) 000-0000 000000">
                              <mat-error *ngIf="validateDirectoryConsentPhone()">
                                This field is required
                              </mat-error>
                            </mat-form-field>
                          </div>
                        </div>

                        <div fxLayout="row">
                          <mat-radio-button [value]="false">
                            <span class="text-wrap">
                              I <strong>do not</strong> consent to having my license name, license type, and contact information listed in the voluntary OPS Licensee Directory.
                            </span>
                          </mat-radio-button>
                        </div>
                      </div>
                    </mat-radio-group>
                    <mat-error *ngIf="verifyForm.get('directoryConsent').invalid">You must select one statement regarding consent</mat-error>
                    <span class="text-wrap"><em>Please note, if you do not consent, you may still be licensed with Oregon Psilocybin Services.</em></span>

                    <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px" fxLayoutAlign="start center" fxLayoutAlign.lt-lg="">
                      <mat-form-field fxFlex="50" fxFlex.lt-md="100">
                        <mat-label>
                          Signature
                        </mat-label>
                        <input matInput placeholder="Signature" formControlName="electronicSignature">
                        <mat-error *ngIf="verifyForm.get('electronicSignature').invalid">
                          Signature is required
                        </mat-error>
                      </mat-form-field>
                      <button [disabled]="!applicationValid() || !verifyForm.valid && !licenseLocked || validateDirectoryConsentWebsite()"
                        type="submit" mat-flat-button
                        color="primary" (click)="submitApplication()">Submit</button>
                    </div>
                  </div>
                </form>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </div>

      <div fxLayout="row" fxLayoutGap="8px" fxFlex="50" fxFlex.lt-md="100" class="p-t-16">
        <button mat-raised-button matStepperPrevious>Back</button>
      </div>
    </mat-step>

  </mat-stepper>
</div>

<div *ngIf="!viewForm && !showSubmitted">
  <div fxLayout="column" fxLayoutGap="8px">
    <div>
      <button mat-flat-button type="button" *ngIf="showStartButton" color="primary" (click)="startApplication()">Start New Application</button>
    </div>
    <div *ngFor="let license of licenses">
      <mat-card [style.background]="cardColor" fxFlex="100">
        <mat-card-content>
          <div fxLayout="row" fxLayout.lt-xl="column" fxLayoutGap="8px">
            <mat-card fxFlex="70" fxFlex.lt-lg="100">
              <mat-card-title>
                <div fxLayout="row" fxLayoutGap="16px">
                  <span>Laboratory</span>
                  <span>{{license.entityName}}</span>
                </div>
              </mat-card-title>
              <mat-card-content>
                <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="24px" style="font-size: large">
                  <div fxLayout="column" fxLayoutGap="8px" fxFlex>
                    <div fxLayout="row" fxLayoutAlign="start center" class="m-b-0">
                      <span fxFlex="30" fxFlex.lt-lg="40"><strong>Laboratory Id</strong></span>
                      <span class="f-s-16" id="programId">{{license.licenseId}}</span>
                      <button mat-icon-button (click)="sharedService.copyToClipboard(license.licenseId)" color="primary"
                              matTooltip="Copy to Clipboard" matTooltipShowDelay=800 aria-label="Copy to Clipboard">
                        <mat-icon>content_copy</mat-icon>
                      </button>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center">
                      <span fxFlex="30" fxFlex.lt-lg="40"><strong>Status</strong></span>
                      <div fxLayout="column">
                        <span class="f-s-20 f-w-700" [ngClass]="license.status === sharedService.approved? 'text-light-green-600':
                                        (license.status === sharedService.submitted ||
                                         license.status === sharedService.pending ||
                                         license.status === sharedService.awaitingPayment ||
                                         license.status === sharedService.underReview)? 'text-orange-900' : 'text-red-900'" *ngIf="license.status != sharedService.submittedForApproval && license.status != sharedService.renewalSubmittedForApproval">
                          {{license.status}}
                      </span>
                      <span class="f-s-20 f-w-700" [ngClass]="'text-orange-900'" *ngIf="license.status == sharedService.submittedForApproval || license.status == sharedService.renewalSubmittedForApproval">{{sharedService.underReview}}</span>
                      </div>
                    </div>
                    <div fxLayout="row" *ngIf="license.status === sharedService.submitted">
                      <span fxFlex="30" fxFlex.lt-lg="40"><strong>Date Submitted</strong></span>
                      <span class="f-s-18">{{license.applicationDate | date}}</span>
                    </div>
                    <div fxLayout="row" *ngIf="license.status === sharedService.approved">
                      <span fxFlex="30" fxFlex.lt-lg="40"><strong>Date Approved</strong></span>
                      <span class="f-s-18">{{license.approvedDate | date}}</span>
                    </div>
                    <div fxLayout="row" *ngIf="license.status === sharedService.approved || license.status === sharedService.expired">
                      <span fxFlex="30" fxFlex.lt-lg="40"><strong>Expiration Date</strong></span>
                      <span class="f-s-18">{{license.expirationDate | date}}</span>
                    </div>
                    <div fxLayout="row" *ngIf="license.status === sharedService.approved && license.isRenewal">
                      <span fxFlex="30" fxFlex.lt-lg="40"><strong>Date Renewal Approved</strong></span>
                      <span class="f-s-18">{{license.renewedDate | date}}</span>
                    </div>

                  </div>
                  <div fxLayout="column" fxLayout.lt-lg="row" fxLayoutAlign="start start" fxLayoutGap="8px">
                    <button *ngIf="license.status !== sharedService.pendingRenewal && license.status !== sharedService.renewalSubmitted && !license.isRenewal"
                      mat-flat-button type="button" color="primary" (click)="viewApplication(license)">
                      <div fxLayout="row" fxLayoutGap="8px" *ngIf="loadingIndex[license.id]">
                        <span>Loading</span>
                        <mat-spinner color="accent" [diameter]="25" [strokeWidth]="5" aria-label="Loading"></mat-spinner>
                      </div>
                      <span *ngIf="!loadingIndex[license.id] && license.status != 'Pending'">View</span>
                      <span *ngIf="!loadingIndex[license.id] && license.status == 'Pending'">Edit</span>
                    </button>
              <button *ngIf="license.isRenewal"
                mat-flat-button type="button" color="primary" (click)="viewRenewal(license)">
                <div fxLayout="row" fxLayoutGap="8px" *ngIf="loadingIndex[license.id]">
                  <span>Loading</span>
                <mat-spinner color="accent" [diameter]="25" [strokeWidth]="5" aria-label="Loading"></mat-spinner>
              </div>
              <span *ngIf="license.status === sharedService.pendingRenewal && !loadingIndex[license.id]">Continue Renewal</span>
              <span *ngIf="license.status !== sharedService.pendingRenewal">View</span>
              </button>
            <button *ngIf="license.canRenew && !hasRenewal(license) && license.status == sharedService.approved && !startedRenewal"
              mat-flat-button type="button" color="primary" (click)="createRenewal(license)">
              <span>Start Renewal</span>
            </button>
                    <button *ngIf="license.status == 'Submitted'" mat-flat-button type="button" color="accent"
                      (click)="requestWithdraw(license)">Request Withdraw
                    </button>
                    <button *ngIf="license.status == 'Approved'" mat-flat-button type="button" color="accent"
                      (click)="requestSurrender(license)">Request Surrender
                    </button>
                    <!-- <button *ngIf="license.status !== sharedService.withdrawn && license.status !== sharedService.closed"
                            mat-flat-button type="button" color="warn" (click)="withdrawApplication(license)">
                      <span *ngIf="license.status === sharedService.submitted || license.status === sharedService.underReview">Request Withdrawal</span>
                      <span *ngIf="license.status !== sharedService.submitted && license.status !== sharedService.underReview">Withdraw</span>
                    </button> -->
                    <a *ngIf="licenseHasUnpaidFees(license)" mat-flat-button type="button" color="warn" routerLink="/payments">Pay Fees</a>
                    <button *ngIf="license.status == 'Approved'" mat-flat-button type="button" (click)="viewPrintScreen(license)">Print License</button>
                    <button *ngIf="license.status == 'Approved'" color="primary" mat-flat-button type="button" (click)="changeDirectory(license)">
                      <span class="text-wrap">Change OPS Licensee Directory Consent</span>
                    </button>
                  </div>
                </div>

                <div fxLayout="row" fxLayout.lt-lg="column" class="m-t-8">
                  <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                    <div fxLayout="row" fxFlex="100">
                      <span fxFlex="50"><strong>Application Fee Status</strong></span>
                      <span [ngClass]="hasPaidFee(license, paymentService.applicationFeeType) ? 'text-light-green-600':  'text-red-900'">
                        <span class="f-s-18">{{getPaymentFeeStatus(license, paymentService.applicationFeeType)}}</span>
                      </span>
                    </div>

                    <div fxLayout="row" *ngIf="hasPaidFee(license, paymentService.applicationFeeType)" fxFlex="100">
                      <span fxFlex="50"><strong>Application Fee Cleared</strong></span>
                      <span class="f-s-18">{{getFeeCleared(license, paymentService.applicationFeeType)}}</span>
                    </div>
                  </div>

                  <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                    <div fxLayout="row" fxFlex="100">
                      <span fxFlex="50"><strong>License Fee Status</strong></span>
                      <span [ngClass]="hasPaidFee(license, paymentService.licenseFeeType) ? 'text-light-green-600':  'text-red-900'">
                        <span class="f-s-18">{{getPaymentFeeStatus(license, paymentService.licenseFeeType)}}</span>
                      </span>
                    </div>

                    <div fxLayout="row" *ngIf="hasPaidFee(license, paymentService.licenseFeeType)" fxFlex="50">
                      <span fxFlex="50"><strong>License Fee Cleared</strong></span>
                      <span class="f-s-18">{{getFeeCleared(license, paymentService.licenseFeeType)}}</span>
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>

            <mat-card fxFlex="30" fxFlex.lt-lg="100" *ngIf="license.status === sharedService.approved">
              <mat-card-title>
                <div fxLayout="row" fxLayoutGap="8px">
                  <span fxFlex>TLC-PTS (Product Tracking) Worker Permits</span>
                  <button mat-button type="button" color="primary" (click)="addWorkerPermit(license)">Add Permits</button>
                </div>
                <div fxLayout="column" fxLayoutGap="8px">
                  <span class="f-s-16"><strong>•</strong> Click Add Permits to give access to permitted workers to add, change and delete data for the <strong>TLC-PTS (Product Tracking)</strong> account for this license.</span>
                  <span class="f-s-16"><strong>•</strong> If the license holder will be updating TLC-PTS, add their worker permit also.</span>
                  <br/>
                  <span class="f-s-16" style="padding-left: 2%; text-decoration: underline;">Added Worker Permits</span>
                </div>
              </mat-card-title>
              <mat-card-content>
                <div fxLayout="column">
                  <div fxLayout="row" fxLayoutGap="8px" *ngFor="let permit of license.workerPermits">
                    <span class="f-s-16">{{ permit.licenseId }}:</span>
                    <span class="f-s-16">{{ permit.applicants[0].legalFirstName }}</span>
                    <span class="f-s-16">{{ permit.applicants[0].legalMiddleName }}</span>
                    <span class="f-s-16" fxFlex>{{ permit.applicants[0].legalLastName }}</span>
                    <button mat-button type="button" color="warn" (click)="removeWorkerPermit(license, permit.licenseId)">Remove</button>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>

<div *ngIf="showSubmitted">
  <mat-card class="sticky-header p-y-0" [style.background-color]="sharedService.orange">
    <mat-card-content>
      <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center" style="color: #303030">
        <mat-icon class="medium-icon m-24">domain</mat-icon>
        <span class="f-s-20" fxFlex *ngIf="!renewing">Laboratory Application Submitted</span>
        <span class="f-s-20" fxFlex *ngIf="renewing">Laboratory Application Renewal Submitted</span>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card *ngIf="!renewing">
    <mat-card-content>
      <span class="f-s-16">Thank you for submitting the Oregon Psilocybin Services laboratory license application.
        You can review your application status and updates to the status in TLC. Please be sure to monitor your email
        for follow-up communication from the Oregon Psilocybin Services Licensing Team. To ensure you receive emails from OPS,
        please consider adding Licensing.Psilocybin@oha.oregon.gov to your email contacts to prevent the email going to your spam folder. </span>
    </mat-card-content>
  </mat-card>
  <mat-card *ngIf="renewing">
    <mat-card-content>
      <span class="f-s-16">Thank you for submitting the Oregon Psilocybin Services laboratory license renewal application.
        You can review your application status and updates to the status in TLC. Please be sure to monitor your email
        for follow-up communication from the Oregon Psilocybin Services Licensing Team. To ensure you receive emails from OPS,
        please consider adding Licensing.Psilocybin@oha.oregon.gov to your email contacts to prevent the email going to your spam folder. </span>
    </mat-card-content>
  </mat-card>

  <app-payment-notice *ngIf="license != null && license.payments != null && licenseHasUnpaidFees(license)"
    feeType="ApplicationFee" entityType="TestingLabLicense" [canPay]="true" [id]="license.id">
  </app-payment-notice>

  <div fxLayout="row" fxLayoutGap="16px" class="m-t-16">
    <button mat-raised-button  type="button" (click)="continue()">Continue</button>
  </div>
</div>

<div *ngIf="loadingTestingLab">
  <div [ngClass]="{'global-loader': true, 'global-loader-dark': sharedService.darkMode}">
    <h1>Loading</h1>
  </div>
</div>
