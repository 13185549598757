import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from '@env/environment';
import { IFacilitatorLicense } from 'app/models/licenses/facilitator-license';
import { IManufacturerLicense } from 'app/models/licenses/manufacturer-license';
import { IServiceCenterLicense } from 'app/models/licenses/service-center-license';
import { ITestingLabLicense } from 'app/models/licenses/testing-lab-license';
import { IWorkerLicense } from 'app/models/licenses/worker-license';
import { ITrainingProgram } from 'app/models/training/training-program';
import { FacilitatorService } from 'app/services/licenses/facilitator.service';
import { ManufacturerService } from 'app/services/licenses/manufacturer.service';
import { ServiceCenterService } from 'app/services/licenses/service-center.service';
import { TestingLabService } from 'app/services/licenses/testing-lab.service';
import { WorkerService } from 'app/services/licenses/worker.service';
import { TrainingProgramService } from 'app/services/training/training-program.service';
import { ToastrService } from 'ngx-toastr';
import { PaymentService } from '../../service/payment.service';

@Component({
  selector: 'app-payment-slip',
  templateUrl: './payment-slip.component.html'
})

export class PaymentSlipDialog implements OnInit {
  public generating: boolean = false;
  public staffInbox: string = environment.staffInbox;
  public applicationSlipReference: string = 'AOBJ 2082';
  public annualSlipReference: string = 'AOBJ 2105';
  public individualType: string = '';
  public feeType: string = '';
  public application: boolean = false;
  public annual: boolean = false;
  public reduced: boolean = false;
  public changeRequest: boolean = false;
  public changeLocation: boolean = false;
  public civilPenalty: boolean = false;
  public applicationFee: string = '$500';
  public annualFee: string = '$10,000';
  public reducedFee: string = '$5,000';
  public changeRequestFee: string = "$250.00"
  public changeLocationFee: string = "$500.00"
  public program: ITrainingProgram = undefined;
  public facilitator: IFacilitatorLicense = undefined;
  public manufacturer: IManufacturerLicense = undefined;
  public workerPermit: IWorkerLicense = undefined;
  public serviceCenter: IServiceCenterLicense = undefined;
  public testingLab: ITestingLabLicense = undefined;
  public responsiblePartyName: string = ''
  public responsiblePartyPhone: string = ''
  public responsiblePartyEmail: string = ''
  public showTrainingProgram: boolean = false;
  public showFacilitator: boolean = false;
  public showWorkerPermit: boolean = false;
  public showManufacturer: boolean = false;
  public showServiceCenter: boolean = false;
  public showTestingLab: boolean = false;
  public isEntityLicense: boolean = false;
  public pageTitle:string = "";
  public licenseName:string = "";
  public licenseId: string = "";
  public applicationDate: string = "";

  constructor(public dialogRef: MatDialogRef<PaymentSlipDialog>,
              public paymentService: PaymentService,
              private trainingProgramService: TrainingProgramService,
              private facilitatorService: FacilitatorService,
              private workerPermitService: WorkerService,
              private manufacturerService: ManufacturerService,
              private serviceCenterService: ServiceCenterService,
              private testingLabService: TestingLabService,
              private toastr: ToastrService,
              @Inject(MAT_DIALOG_DATA) public data: any){}

  ngOnInit(): void {
    this.determineFeeType();
    this.determineEntityType();
  }

  determineFeeType(): void{
    switch(this.data.feeType){
      case this.paymentService.applicationFeeType:
        this.feeType = 'Application Fee';
        this.application = true;
        break;
      case this.paymentService.licenseFeeType:
        this.feeType = 'Annual Fee';
        this.annual = true;
        break;
      case this.paymentService.reducedLicenseFeeType:
        this.feeType = 'Reduced Annual Fee';
        this.reduced = true;
        break;
      case this.paymentService.changeRequestFeeType:
        this.feeType = 'Change Request Fee';
        this.changeRequest = true;
        break;
      case this.paymentService.changeLocationFeeType:
        this.feeType = 'Change of Location Fee';
        this.changeLocation = true;
        break;
      case this.paymentService.CivilPenaltyFeeType:
        this.feeType = 'Civil Penalty Fee';
        this.civilPenalty = true;
        break;
    }
  }

  determineEntityType(): void{
    switch (this.data.entityType) {
      //Training Programs
      case this.paymentService.trainingProgramPaymentOption:
        this.generating = true;
        this.trainingProgramService.getTrainingPrograms().subscribe(
          response => {
            this.program = response.find(r => r.id == this.data.id);
            let rp = this.program.trainingProgramContacts.find(tpc => tpc.isResponsible);
            if(rp != null) {
              this.responsiblePartyName = rp.firstName + " " + rp.lastName;
              this.responsiblePartyPhone = rp.phone;
              this.responsiblePartyEmail = rp.email;
            }
            this.licenseId = this.program.programId;
            this.licenseName = this.program.programName;
            this.applicationDate = this.program.applicationDate;
          },
          error => {
            this.toastr.error(error);
          },
          () => {
            this.showTrainingProgram = true;
            this.pageTitle = "Training Program Curriculum Payment Remittance Form";
            this.generating = false;
            this.applicationSlipReference = 'AOBJ 2670'
          }
        );
        break;

      //Facilitators
      case this.paymentService.facilitatorPaymentOption:
      this.generating = true;
        this.facilitatorService.getFacilitatorLicenses().subscribe(
          response => {
            this.facilitator = response.find(r => r.id == this.data.id);
            if(this.facilitator.applicants != null && this.facilitator.applicants.length > 0) {
              this.responsiblePartyName = this.facilitator.applicants[0].legalFirstName + " " + this.facilitator.applicants[0].legalLastName;
              this.responsiblePartyPhone = this.facilitator.applicants[0].phone;
              this.responsiblePartyEmail = this.facilitator.applicants[0].email;
            }
            this.licenseId = this.facilitator.licenseId;
            this.licenseName = this.responsiblePartyName;
            this.applicationDate = this.facilitator.applicationDate;
          },
          error => {
            this.toastr.error(error);
          },
          () => {
            this.showFacilitator = true;
            this.individualType = 'Facilitator';
            this.pageTitle = "Psilocybin Facilitator License " + this.feeType + " Payment Remittance Form";
            this.generating = false;
            this.applicationFee = '$150';
            this.annualFee = '$2000';
            this.reducedFee = '$1000';
          }
        );
        break;

      //Worker Permit
      case this.paymentService.WorkerPermitPaymentOption:
        this.generating = true;
        this.workerPermitService.getWorkerLicenses().subscribe(
          response => {
            this.workerPermit = response.find(r => r.id == this.data.id);
            if(this.workerPermit.applicants != null && this.workerPermit.applicants.length > 0) {
              this.responsiblePartyName = this.workerPermit.applicants[0].legalFirstName + " " + this.workerPermit.applicants[0].legalLastName;
              this.responsiblePartyPhone = this.workerPermit.applicants[0].phone;
              this.responsiblePartyEmail = this.workerPermit.applicants[0].email;
            }
            this.licenseId = this.workerPermit.licenseId;
            this.licenseName = this.responsiblePartyName;
            this.applicationDate = this.workerPermit.applicationDate;
          },
          error => {
            this.toastr.error(error);
          },
          () => {
            this.individualType = 'Worker Permit';
            this.showWorkerPermit = true;
            this.pageTitle = "Worker Permit Fee Payment Remittance Form";
            this.generating = false;
            this.applicationSlipReference = 'AOBJ 2130'
          }
        );
        break;

      //Manufacturer
      case this.paymentService.ManufacturerPaymentOption:
        this.generating = true;
        this.manufacturerService.getLicenses().subscribe(
          response => {
            this.manufacturer = response.find(r => r.id == this.data.id);

            if(this.manufacturer != null) {
              this.responsiblePartyName = this.manufacturer.primaryContactName;
              this.responsiblePartyPhone = this.manufacturer.primaryContactNumber;
              this.responsiblePartyEmail = this.manufacturer.primaryContactEmail;
              this.licenseId = this.manufacturer.licenseId;
              this.licenseName = this.manufacturer.entityName;
              this.applicationDate = this.manufacturer.applicationDate;
            }
          },
          error => {
            this.toastr.error(error);
          },
          () => {
            this.isEntityLicense = true;
            this.showManufacturer = true;
            this.pageTitle = "Psilocybin Manufacturer License " + this.feeType + " Payment Remittance Form";
            this.generating = false;
          }
        );
        break;

      //Testing Lab
      case this.paymentService.TestingLabPaymentOption:
        this.generating = true;
        this.testingLabService.getLicenses().subscribe(
          response => {
            this.testingLab = response.find(r => r.id == this.data.id);

            if(this.testingLab != null) {
              this.responsiblePartyName = this.testingLab.primaryContactName;
              this.responsiblePartyPhone = this.testingLab.primaryContactNumber;
              this.responsiblePartyEmail = this.testingLab.primaryContactEmail;
              this.licenseId = this.testingLab.licenseId;
              this.licenseName = this.testingLab.entityName;
              this.applicationDate = this.testingLab.applicationDate;
            }
          },
          error => {
            this.toastr.error(error);
          },
          () => {
            this.isEntityLicense = true;
            this.showTestingLab = true;
            this.pageTitle = "Psilocybin Laboratory License " + this.feeType + " Payment Remittance Form";
            this.generating = false;
          }
        );
        break;

      //Service Center
      case this.paymentService.ServiceCenterPaymentOption:
        this.generating = true;
        this.serviceCenterService.getLicenses().subscribe(
          response => {
            this.serviceCenter = response.find(r => r.id == this.data.id);

            if(this.serviceCenter != null) {
              this.responsiblePartyName = this.serviceCenter.primaryContactName;
              this.responsiblePartyPhone = this.serviceCenter.primaryContactNumber;
              this.responsiblePartyEmail = this.serviceCenter.primaryContactEmail;
              this.licenseId = this.serviceCenter.licenseId;
              this.licenseName = this.serviceCenter.entityName;
              this.applicationDate = this.serviceCenter.applicationDate;
            }
          },
          error => {
            this.toastr.error(error);
          },
          () => {
            this.isEntityLicense = true;
            this.showServiceCenter = true;
            this.pageTitle = "Psilocybin Service Center License " + this.feeType + " Payment Remittance Form";
            this.generating = false;
          }
        );
        break;
    }
  }

  cancel(): void {
    this.dialogRef.close('cancel');
  }

}
