<!--Header -->
<mat-card class="sticky-header p-y-0" [style.background-color]="sharedService.red" *ngIf="!showConfirmed">
  <mat-card-content>
    <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
      <mat-icon class="medium-icon m-24">badge</mat-icon>
      <span class="f-s-20" *ngIf="workerLicense.status !== 'Renewal Submitted'">Apply For a Worker Permit</span>
      <span class="f-s-20" *ngIf="workerLicense.status === 'Renewal Submitted'">Renew Your Worker Permit</span>
      <span fxFlex></span>
      <button mat-raised-button type="button"  color="primary" *ngIf="viewEdit  && ((workerLicenses.length > 1) ||(workerLicenses.length == 1 && workerLicense.status != 'Pending'))" (click)="toggleView()">Close</button>
    </div>
  </mat-card-content>
</mat-card>
<!--End of Header -->

<div fxLayout="column" fxLayoutGap="8px" *ngIf="showForm">
  <mat-stepper [orientation]="sharedService.isMobile? 'vertical' : 'horizontal'" #WorkerStepper [selectedIndex]="step"
    (selectionChange)="changeStep($event)">
    <!-- Start here section -->
    <mat-step>
      <ng-template matStepLabel>
        <span>Start Here</span>
      </ng-template>
      <div fxLayout="column" fxLayoutGap="8px" class="p-b-8">
        <span class="f-s-20" style="font-weight: bold;">Thank you for your interest in applying for a psilocybin worker
          permit through the Oregon Psilocybin Services -
          Training Program, Licensing, and Compliance system (TLC). </span>

        <div fxLayout="column" fxLayoutGap="8px">
          <span>
            More detailed information to support the application process can be found in the Psilocybin Worker Permit Online Application Guide.
          </span>
          <span>
            Please visit <a href="https://www.oregon.gov/oha/PH/PREVENTIONWELLNESS/Pages/Psilocybin-Worker-Permit.aspx"
            target="_blank" rel="noreferrer noopener"
            style="word-break: break-all;
            white-space: normal;">https://www.oregon.gov/oha/PH/PREVENTIONWELLNESS/Pages/Psilocybin-Worker-Permit.aspx</a>
            to access this guide.
          </span>
        </div>

        <div fxLayout="row" fxLayout.lt-lg="column" style="padding-top: 2em" fxLayoutGap="12px">
          <div fxLayout="column" fxLayoutGap="8px" fxFlex="40">
            <strong>Psilocybin Worker Permit Requirements:</strong>
            <span class="p-l-24">
              <strong>• </strong>Must be 21 years of age or older
            </span>
            <span class="p-l-24">
              <strong>• </strong>Must pass a criminal background check
            </span>
            <span class="p-l-24">
              <strong>• </strong>Must pay a worker permit fee
            </span>
          </div>

          <div fxLayout="column" fxLayoutGap="8px">
            <strong>Who must have a valid psilocybin worker permit?</strong>
            <span class="p-l-24">
              An individual who is a licensee or a licensee representative must have a valid worker permit if the
              individual participates in:
            </span>
            <div style="padding-left: 1em" fxLayout="column" fxLayoutGap="12px">
              <span class="p-l-24">
                <strong>• </strong>The provision of psilocybin services at a licensed premises.
              </span>
              <span class="p-l-24">
                <strong>• </strong>The possession, manufacturing, securing or selling of psilocybin products at a
                licensed premises.
              </span>
              <span class="p-l-24">
                <strong>• </strong>The recording of the possession, manufacturing, securing or selling of psilocybin
                products at a licensed premises.
              </span>
              <span class="p-l-24">
                <strong>• </strong>The verification of any document described in ORS 475A.445.
              </span>
            </div>
          </div>
        </div>
        <div fxLayout="column" fxLayoutGap="8px" style="padding-top: 2em">
          <strong>The completed application packet contains the following::</strong>
          <span class="p-l-24">
            <strong>• </strong>Completed and signed worker permit application
          </span>
          <span class="p-l-24">
            <strong>• </strong>Uploaded copy of the front and back of a valid, unexpired government-issued photo
            identification for the applicant
          </span>
        </div>
        <div fxLayout="row" style="padding-top: 2em">
          <div fxLayout="column" fxLayoutGap="8px">
            <strong>Important Notes: </strong>
            <span class="p-l-24">
              <strong>• </strong>Please review each section of the application to ensure it is complete
            </span>
            <span class="p-l-24">
              <strong>• </strong>A fee page will describe when and how to submit the worker permit fee which may be paid
              online or in-person
            </span>
            <span class="p-l-24">
              <strong>• Incomplete and Insufficient Application Statuses:</strong> Please review the application guide
              for more detailed information on all application statuses.
            </span>
            <div fxLayout="column" style="padding-left: 1em;" fxLayoutGap="8px">
              <span class="p-l-24">
                <strong>• </strong>Incomplete: An application is considered incomplete if the application is missing
                required information (e.g., the applicant did not submit fingerprints).
                If the application is incomplete, we will notify you by email to give you an opportunity to make your
                application complete. You have 90 days from the initial date you are notified to complete your
                application. If you fail to do so, the application will be closed.
              </span>
              <span class="p-l-24">
                <strong>• </strong>Insufficient: An application is considered insufficient if the complete application
                or site inspection does not meet requirements outlined in statute or rule. If the application is
                insufficient,
                we will notify you by email to give you an opportunity to meet requirements. You will have 90 days from
                the initial date you are notified to meet requirements, or your application will be denied.
              </span>
            </div>
          </div>
        </div>


        <div fxLayout="column" fxLayoutGap="8px" style="padding-top: 2em">
          <span>
            <strong>When you click the continue button at the bottom of this page, you will proceed to the psilocybin
              worker permit application. </strong> The application automatically saves in TLC. You may exit the
            application and come back later to complete and submit the application, if needed.
          </span>
        </div>
        <div fxLayout="column" fxLayoutGap="8px" style="padding-top: 2em;">
          <span>
            <strong>DISCLAIMER:</strong> Psilocybin remains a schedule I drug under the federal Controlled Substances
            Act. Applicants are responsible for
            making their own determinations regarding the legal risks associated with participating in this program. The
            information you provide in your application
            materials, including applicant name, is subject to disclosure under Oregon's public records laws.
          </span>
        </div>

      </div>
      <div fxLayout="row" fxFlex="50" fxFlex.lt-md="100" class="p-t-16">
        <button mat-raised-button matStepperNext *ngIf="!canApplyAgain">Continue</button>
        <button mat-flat-button type="button" color="primary" *ngIf="showStartButton && canApplyAgain"
          (click)="startApplication()">Start Application</button>
      </div>
    </mat-step>
    <!-- End of Start here section -->

    <!-- Start of Personal Information -->
    <mat-step [stepControl]="applicantForm" *ngIf="workerLicenses.length >= 1">
      <form [formGroup]="applicantForm">
        <ng-template matStepLabel>
          <span class="text-red-400" *ngIf="!applicantForm.valid && applicantForm.touched && !viewEdit">
            Applicant Information Incomplete</span>
          <span *ngIf="applicantForm.valid || !applicantForm.touched">Applicant Information</span>
          <span *ngIf="viewEdit && !applicantForm.valid && applicantForm.touched">Applicant Information</span>

        </ng-template>
        <div fxLayout="column">
          <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
            <mat-card fxFlex="50" fxFlex.lt-lg="100">
              <mat-card-title>
                <div fxLayout="row" fxLayout.lt-lg="column">
                  <span fxFlex>Applicant Information</span>
                </div>
              </mat-card-title>
              <mat-card-content>
                <div fxLayout="column" fxLayoutGap="8px">
                  <span><strong>Legal Name</strong></span>
                  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" fxLayoutGap.lt-lg="">
                    <mat-form-field fxFlex="40" fxFlex.lt-md="100">
                      <input matInput placeholder="First" formControlName="legalFirstName">
                      <mat-error *ngIf="applicantForm.get('legalFirstName').invalid">
                        Please enter your first name
                      </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="40" fxFlex.lt-md="100">
                      <input matInput placeholder="Last" formControlName="legalLastName">
                      <mat-error *ngIf="applicantForm.get('legalLastName').invalid">
                        Please enter your last name
                      </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="20" fxFlex.lt-md="100">
                      <input matInput placeholder="Middle" formControlName="legalMiddleName">
                    </mat-form-field>
                  </div>

                  <span><strong>Preferred Name</strong></span>
                  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" fxLayoutGap.lt-lg="">
                    <mat-form-field fxFlex="40" fxFlex.lt-md="100">
                      <input matInput placeholder="First" formControlName="preferredFirstName">
                    </mat-form-field>

                    <mat-form-field fxFlex="40" fxFlex.lt-md="100">
                      <input matInput placeholder="Last" formControlName="preferredLastName">
                    </mat-form-field>

                    <mat-form-field fxFlex="20" fxFlex.lt-md="100">
                      <input matInput placeholder="Middle" formControlName="preferredMiddleName">
                    </mat-form-field>
                  </div>

                  <div fxLayout="column">
                    <span><strong>Prior Names and Aliases</strong></span>
                    <span>This information is used for the purposes of the background check</span>
                    <div fxLayout="row" fxLayoutGap="8px" fxLayout.lt-lg="column">
                      <mat-form-field fxFlex="80" fxFlex.lt-md="100">
                        <input matInput placeholder="Name, other name, any other names"
                          formControlName="alternateNames">
                      </mat-form-field>

                      <mat-form-field>
                        <mat-label>Date of Birth</mat-label>
                        <input placeholder="mm/dd/yyyy" formControlName="dob" matInput [matDatepicker]="dob"
                          [max]="sharedService.today" />
                        <mat-datepicker-toggle matSuffix [for]="dob"></mat-datepicker-toggle>
                        <mat-datepicker #dob>
                          <mat-datepicker-actions>
                            <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
                            <button mat-button matDatepickerCancel>Cancel</button>
                          </mat-datepicker-actions>
                        </mat-datepicker>
                        <mat-error *ngIf="applicantForm.get('dob').value > sharedService.today">
                          Please choose an earlier date.
                        </mat-error>
                        <mat-error *ngIf="applicantForm.get('dob').hasError('required')">
                          Date of birth is required
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>

                  <div fxLayout="column">
                    <span><strong>*SOCIAL SECURITY NUMBER DISCLOSURE:</strong></span>
                    <span style="padding-top: 1em; padding-left: 1em"><strong>• </strong>As part of your application for
                      an initial or renewal license, Federal and State laws require you to provide your Social Security
                      Number (SSN), if you have one,
                      to Oregon Psilocybin Services for child support enforcement purposes (42 USC § 666(a)(13) & ORS
                      25.785). If you are an applicant or licensee and you have an SSN but fail to provide your SSN, Oregon Psilocybin
                      Services may refuse to process your application.
                      Your SSN will be used only for child support enforcement purposes unless you confirm your
                      agreement that OPS can use it for administrative purposes as well.
                    </span>

                    <span style="padding-top: 1em; padding-left: 1em">
                      <strong>IMPORTANT NOTE: If you do not have a social security number, you may still be licensed or permitted by Oregon Psilocybin Services.</strong>
                    </span>

                    <span style="padding-top: 1em; padding-left: 1em"><strong>• </strong>
                      Based on our authority under OAR 333-333-4100, we are requesting your voluntary consent to use
                      your SSN for the following administrative purposes only: to positively confirm your identity
                      during the criminal records check. Oregon
                      Psilocybin Services will not deny you any rights, benefits or privileges otherwise provided by law
                      if you do not consent to use of your SSN for these administrative purposes (5 USC§ 552(a)).
                      <strong>Please check the appropriate box next to the social security field indicating whether you
                        consent or do not consent.</strong>
                    </span>
                    <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="16px" fxLayoutGap.lt-lg=""
                      fxLayoutAlign="start center" fxLayoutAlign.lt-lg="">
                      <mat-form-field fxFlex="25" fxFlex.lt-md="100">
                        <input matInput placeholder="SSN" formControlName="ssn" mask="000-00-0000">
                        <mat-error *ngIf="validateSsn()">
                          SSN is Required
                        </mat-error>
                      </mat-form-field>
                      <mat-checkbox formControlName="noSsn">
                        I don't have a social security number
                      </mat-checkbox>
                    </div>
                    <span>Do you consent to use of SSN for purposes of confirming identity during a criminal records
                      check?</span>
                    <mat-error *ngIf="validateSsnAttestation()">
                      Consent choice is Required
                    </mat-error>

                    <mat-checkbox formControlName="ssnAttestation" (click)="changeAttestation(true)">
                      Yes
                    </mat-checkbox>
                    <mat-checkbox formControlName="ssnNoAttestation" (click)="changeAttestation(false)">
                      No
                    </mat-checkbox>

                  </div>
                </div>
              </mat-card-content>
            </mat-card>

            <mat-card fxFlex="50" fxFlex.lt-lg="100">
              <mat-card-title>
                Contact Information
              </mat-card-title>
              <mat-card-content>
                <div fxLayout="column" fxLayoutGap="8px">
                  <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
                    <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                      <span class="f-2-20"><strong>Primary Phone</strong></span>
                      <mat-form-field>
                        <input matInput placeholder="Phone" formControlName="phone" mask="(000) 000-0000 000000"
                          [validation]="false">
                        <mat-error *ngIf="applicantForm.get('phone').hasError('required')">
                          A primary phone number is required
                        </mat-error>
                        <mat-error *ngIf="applicantForm.get('phone').hasError('pattern')">
                          Phone should match this format: 999-999-9999 x1234
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                      <span class="f-2-20"><strong>Alternate Phone</strong></span>
                      <mat-form-field>
                        <input matInput placeholder="Alt Phone" formControlName="alternatePhone"
                          mask="(000) 000-0000 000000" [validation]="false">
                        <mat-error *ngIf="applicantForm.get('alternatePhone').hasError('pattern')">
                          Phone should match this format: 999-999-9999 x1234
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>

                  <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
                    <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                      <span class="f-2-20"><strong>Primary Email Address</strong></span>
                      <mat-form-field>
                        <input matInput placeholder="Email" formControlName="email">
                        <mat-error *ngIf="applicantForm.get('email').hasError('required')">
                          Please enter an email adress
                        </mat-error>
                        <mat-error *ngIf="applicantForm.get('email').hasError('email')">
                          This doesn't look like a valid email address
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                      <span class="f-2-20"><strong>Confirm Email Address</strong></span>
                      <mat-form-field>
                        <input matInput placeholder="Confirm Email" formControlName="confirmEmail">
                        <mat-error *ngIf="validateEmail()">
                          Email does not match
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>

                  <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
                    <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                      <span class="f-2-18"><strong>Preferred Language</strong></span>
                      <mat-form-field>
                        <input matInput placeholder="Language" formControlName="language">
                      </mat-form-field>
                    </div>
                  </div>

              <div fxLayout="column">
                <span class="f-2-20"><strong>Other OPS Licenses or Permits</strong></span>
                <mat-checkbox formControlName="previousLicense">
                  Check the box if the applicant applied for or received any other licenses or permits from Oregon Psilocybin Services
                </mat-checkbox>
                <div fxLayout="column" class="m-t-8" *ngIf="this.applicantForm.get('previousLicense').value">
                  <span>Please provide the license id(s) for each Oregon Psilocybin Services license.</span>
                  <mat-form-field>
                    <input matInput formControlName="affiliatedLicenses">
                    <mat-placeholder style="color:red" *ngIf="applicantForm.get('affiliatedLicenses').value == ''">Affiliated Licenses*</mat-placeholder>
                    <mat-placeholder style="color:grey" *ngIf="applicantForm.get('affiliatedLicenses').value != ''">Affiliated Licenses</mat-placeholder>
                  </mat-form-field>
                </div>
              </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>

          <mat-card>
            <mat-card-title>Address</mat-card-title>
            <mat-card-content>
              <div fxLayout="column">
                <span><strong>Physical Address</strong></span>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
                  <mat-form-field fxFlex="20" fxFlex.lt-md="100">
                    <input matInput placeholder="Street Apt/Unit/Suite" formControlName="physicalStreet">
                    <mat-error *ngIf="applicantForm.get('physicalStreet').invalid">
                      Please provide a street
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field fxFlex="20" fxFlex.lt-md="100">
                    <input matInput placeholder="City" formControlName="physicalCity">
                    <mat-error *ngIf="applicantForm.get('physicalCity').invalid">
                      Please provide a city
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field fxFlex="40" fxFlex.lt-md="100">
                    <mat-label style="color:red" *ngIf="applicantForm.get('physicalState').value == ''">State/US territory/freely associated state*</mat-label>
                    <mat-label *ngIf="applicantForm.get('physicalState').value != ''">State/US territory/freely associated state</mat-label>
                    <mat-select formControlName="physicalState">
                      <mat-option *ngFor="let state of sharedService.states" value={{state}}>{{state}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="applicantForm.get('physicalState').invalid">
                      Please select a state
                    </mat-error>
                  </mat-form-field>
                </div>

                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
                  <mat-form-field fxFlex="20" fxFlex.lt-md="100">
                    <input matInput placeholder="Zip" formControlName="physicalZip">
                    <mat-error *ngIf="applicantForm.get('physicalZip').hasError('required')">
                      Please provide a zipcode
                    </mat-error>
                    <mat-error *ngIf="applicantForm.get('physicalZip').hasError('pattern')">
                      Zipcode should match this format: 99999 or 99999-1234
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field fxFlex="20" fxFlex.lt-md="100" *ngIf="applicantForm.get('physicalState').value === 'OR'">
                    <mat-label>County</mat-label>
                    <mat-select formControlName="physicalCounty">
                      <mat-option>-- None --</mat-option>
                      <mat-option *ngFor="let county of sharedService.counties" value={{county}}>{{county}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>

              <div fxLayout="column" class="m-t-8">
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="24px">
                  <span><strong>Mailing Address</strong></span>
                  <mat-checkbox formControlName="mailingIsPhysical" (change)="mailingIsSame()">
                    Same as physical
                  </mat-checkbox>
                </div>
                <div fxLayout="column" [@slideInOut] *ngIf="!applicantForm.get('mailingIsPhysical').value">
                  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
                    <mat-form-field fxFlex="20" fxFlex.lt-md="100">
                      <input matInput placeholder="Street Apt/Unit/Suite" formControlName="mailingStreet"
                        (blur)="validateMailingStreet()">
                      <mat-error *ngIf="validateMailingStreet()">
                        Please provide a street
                      </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="20" fxFlex.lt-md="100">
                      <input matInput placeholder="City" formControlName="mailingCity" (blur)="validateMailingCity()">
                      <mat-error *ngIf="validateMailingCity()">
                        Please provide a city
                      </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="30" fxFlex.lt-md="100">
                      <mat-label>State/US territory/freely associated state</mat-label>
                      <mat-select formControlName="mailingState" (blur)="validateMailingState()">
                        <mat-option *ngFor="let state of sharedService.states" value={{state}}>{{state}}</mat-option>
                      </mat-select>
                      <mat-error *ngIf="validateMailingState()">
                        Please provide a state
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
                    <mat-form-field fxFlex="20" fxFlex.lt-md="100">
                      <input matInput placeholder="Zip" formControlName="mailingZip" (blur)="validateMailingZip()">
                      <mat-error *ngIf="validateMailingZip()">
                        Please provide a zipcode
                      </mat-error>
                      <mat-error *ngIf="applicantForm.get('mailingZip').hasError('pattern')">
                        Zipcode should match this format: 99999 or 99999-1234
                      </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="20" fxFlex.lt-md="100"
                      *ngIf="applicantForm.get('mailingState').value === 'OR'">
                      <mat-label>County</mat-label>
                      <mat-select formControlName="mailingCounty">
                        <mat-option>-- None --</mat-option>
                        <mat-option *ngFor="let county of sharedService.counties" value={{county}}>{{county}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>

              <app-residential-history *ngIf="showResidentialHistory" [applicantId]="workerLicense.applicants[0]?.id"
                [residentialHistory]="workerLicense.applicants[0]?.residentialHistory">
              </app-residential-history>
            </mat-card-content>
          </mat-card>
        </div>
      </form>

      <div class="m-t-16" fxLayout="row" fxLayoutGap="8px" fxFlex="50" fxFlex.lt-md="100">
        <button mat-raised-button matStepperPrevious>Save and Back</button>
        <button mat-raised-button matStepperNext>Save and Continue</button>
      </div>
    </mat-step>
    <!-- End of Personal Information -->

    <!-- Start of Documents -->
    <mat-step [stepControl]="applicantForm" *ngIf="workerLicenses.length >= 1">
      <form [formGroup]="applicantForm">
        <ng-template matStepLabel>
          <span *ngIf="workerLicense.applicants[0].applicantDocuments.length < 1 && applicantForm.touched "
            class="text-red-400">Documents (Incomplete) </span>
          <span
            *ngIf="workerLicense.applicants[0].applicantDocuments.length >= 1 || !applicantForm.touched">Documents</span>
        </ng-template>

        <div fxLayout="column" fxLayoutGap="8px">
          <div fxLayout="row" fxLayoutGap="8px" class="p-b-8">
            <div fxLayout="column" fxLayoutGap="8px" class="p-b-8">
              <span class="f-s-20" style="font-weight: bold;">Identity Verification</span>
              <span class="f-s-20">You must upload a copy of the front and back of a valid, unexpired government-issued
                photo identification. Accepted identification includes:</span>
              <span class="p-l-24">
                <strong>* </strong>Passport
              </span>
              <span class="p-l-24">
                <strong>* </strong>Driver license, whether issued by the State of Oregon or by another state of the
                United States;
              </span>
              <span class="p-l-24">
                <strong>* </strong>Identification card issued under ORS 807.400;
              </span>
              <span class="p-l-24">
                <strong>* </strong>United States military identification card;
              </span>
              <span class="p-l-24">
                <strong>* </strong>An identification card issued by a federally recognized Indian tribe with photo, name
                and date of birth; or any other identification card issued by a state or territory that bears a picture
                of the person, the name of the person, the person’s date of birth and a physical description of the
                person.
              </span>
            </div>
          </div>

          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
            <div fxLayout="column" fxLayoutGap="8px" fxFlex="35">
              <span class="f-s-20" style="font-weight: bold;">Upload Identity Document</span>
              <div fxLayout="column" fxLayoutGap="8px" class="p-t-8">
                <input type="file" accept=".doc,.docx,.rtf,.xls,.xlsx,.pdf,.txt,.png,.jpg,.jpeg,.bmp,.mp4" class="file-input" (change)="uploadApplicantDocument($event)" #fileUpload />
                <div fxLayout="row" fxLayoutGap="8px">
                  <div class="file-upload">
                    <button [disabled]="workerLicense.status !== sharedService.pending" mat-raised-button color="primary" class="upload-btn" (click)="fileUpload.click()">
                      Browse
                      <mat-icon>attach_file</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div fxLayout="column" fxLayoutGap="8px" fxFlex.lt-md="100">
              <span class="f-s-20" style="font-weight: bold;">Current Documents</span>
              <div fxLayout="column" *ngIf="workerLicense.applicants[0].applicantDocuments.length != 0">
                <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center"
                  *ngFor="let document of workerLicense.applicants[0].applicantDocuments">
                  <div fxLayout="row">
                    <button mat-icon-button class="m-8" (click)="downloadDocument(document.id, document.name, true)"
                      color="primary" aria-label="Download File">
                      <mat-icon>save_alt</mat-icon>
                    </button>
                    <button mat-icon-button class="m-8" *ngIf="workerLicense.status == sharedService.pending"
                      (click)="deleteFile(document.id, document.name, true)" color="warn" aria-label="Delete">
                      <mat-icon>delete</mat-icon>
                    </button>
                  </div>
                  <div fxLayout="column">
                    <div fxFlex.lt-md="100" *ngFor="let type of document.types">
                      <div *ngFor="let docType of documentTypes">
                        <span *ngIf="docType.id == type"> {{docType.type}} </span>
                      </div>
                    </div>
                  </div>
                  <div fxFlex.lt-md="70" fxLayout="column"></div>
                  <span title="{{document.name}}"><strong>{{document.name.length > 15 ? document.name.substring(0,15) + "..." : document.name}}</strong></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div fxLayout="row" fxLayoutGap="8px" fxFlex="50" fxFlex.lt-md="100" class="p-t-16">
        <button mat-raised-button matStepperPrevious>Back</button>
        <button mat-raised-button matStepperNext>Next</button>
      </div>
    </mat-step>
    <!-- End of Documents -->

    <!-- Start of Fees-->
    <mat-step *ngIf="workerLicenses.length >= 1">
      <ng-template matStepLabel>
        <span>Fee Information</span>
      </ng-template>
      <div fxLayout="column" fxLayoutGap="8px" fxFlex="50" fxFlex.lt-lg="100">
        <span *ngIf="sharedService.tablet" class="f-s-18">Fees</span>

        <app-payment-notice [canPay]="false" [entityType]="paymentService.WorkerPermitPaymentOption"
          [feeType]="paymentService.applicationFeeType"></app-payment-notice>
      </div>
      <div fxLayout="row" fxLayoutGap="8px" fxFlex="50" fxFlex.lt-md="100" fxLayout.lt-md="row">
        <button mat-raised-button matStepperPrevious>Back</button>
        <button mat-raised-button matStepperNext>Next</button>
      </div>
    </mat-step>
    <!-- End of Fees-->

    <!-- Start of Review-->
    <mat-step [stepControl]="licenseForm" *ngIf="workerLicenses.length >= 1">
      <form [formGroup]="licenseForm">
        <ng-template matStepLabel>Review and Submit</ng-template>
        <mat-card>
          <mat-card-title>
            <span>Review Your Application</span>
          </mat-card-title>
          <mat-card-content>
            <div fxLayout="column">
              <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
                <mat-card fxFlex="50" fxFlex.lt-lg="100">
                  <mat-card-title>
                    Name and Address
                  </mat-card-title>
                  <mat-card-content>
                    <div fxLayout="column" fxLayoutGap="8px">
                      <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
                        <span fxFlex="20" fxFlex.lt-lg=""><strong>Name:</strong></span>
                        <span>{{workerLicense.applicants[0].legalFirstName}} {{workerLicense.applicants[0].legalMiddleName}} {{workerLicense.applicants[0].legalLastName}}</span>
                        <span></span>
                        <span></span>
                      </div>
                      <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
                        <span fxFlex="20" fxFlex.lt-lg=""><strong>Physical Address</strong></span>
                        <div fxLayout="row" fxLayoutGap="4px">
                          <span>{{workerLicense.applicants[0].physicalStreet}}</span>
                          <span>{{workerLicense.applicants[0].physicalCity}},</span>
                          <span>{{workerLicense.applicants[0].physicalState}}</span>
                        </div>
                        <div fxLayout="row" fxLayoutGap="4px">
                          <span>{{workerLicense.applicants[0].physicalZip}}</span>
                          <span>{{workerLicense.applicants[0].physicalCounty}}</span>
                          <span *ngIf="workerLicense.applicants[0].physicalCounty">County</span>
                        </div>
                      </div>
                      <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px" *ngIf="!workerLicense.applicants[0].mailingIsPhysical">
                        <span fxFlex="20" fxFlex.lt-lg=""><strong>Mailing Address</strong></span>
                        <div fxLayout="row" fxLayoutGap="4px">
                          <span>{{workerLicense.applicants[0].physicalStreet}}</span>
                          <span>{{workerLicense.applicants[0].physicalCity}},</span>
                          <span>{{workerLicense.applicants[0].physicalState}}</span>
                        </div>
                        <div fxLayout="row" fxLayoutGap="4px">
                          <span>{{workerLicense.applicants[0].physicalZip}}</span>
                          <span>{{workerLicense.applicants[0].physicalCounty}}</span>
                          <span *ngIf="workerLicense.applicants[0].physicalCounty">County</span>
                        </div>
                      </div>
                      <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px" *ngIf="workerLicense.applicants[0].mailingIsPhysical">
                        <span fxFlex="20" fxFlex.lt-lg=""><strong>Mailing Address</strong></span>
                        <span>Same As Physical</span>
                      </div>
                      <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
                        <span fxFlex="20" fxFlex.lt-lg=""><strong>DOB:</strong></span>
                        <span>{{workerLicense.applicants[0].dob | date}}</span>
                      </div>
                      <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
                        <span fxFlex="20" fxFlex.lt-lg=""><strong>Contact Information</strong></span>
                        <div fxLayout="column">
                          <div fxLayout="row" fxLayoutGap="8px">
                            <span>Email:</span>
                            <span>{{workerLicense.applicants[0].email}}</span>
                          </div>
                          <div fxLayout="row" fxLayoutGap="8px">
                            <span>Phone:</span>
                            <span>{{workerLicense.applicants[0].phone | mask: '(000) 000-0000 000000'}}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </mat-card-content>
                </mat-card>

                <mat-card fxFlex="50" fxFlex.lt-lg="100">
                  <mat-card-title>
                    Documents
                  </mat-card-title>
                  <mat-card-content>
                    <mat-table [dataSource]="documentSource" matSort #documentSort="matSort" aria-describedby="documents"
                    class="mat-elevation-z8 m-b-0">
                    <ng-container matColumnDef="name">
                      <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
                      <mat-cell *matCellDef="let document">
                        <span>
                          {{document.name}}
                        </span>
                      </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="type">
                      <mat-header-cell *matHeaderCellDef mat-sort-header>Types</mat-header-cell>
                      <mat-cell *matCellDef="let document">
                        <div fxLayout="column">
                          <span *ngFor="let type of document.types">
                            <span>{{documentTypeReference[type]}}</span>
                          </span>
                        </div>
                      </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="documentColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: documentColumns"></mat-row>
                  </mat-table>
                  <mat-paginator [pageSizeOptions]="[5, 10, 20]" #documentPaginator="matPaginator">
                  </mat-paginator>
                  </mat-card-content>
                </mat-card>
              </div>

              <div fxLayout="row">
                <mat-card fxFlex="50" fxFlex.lt-lg="100">
                  <mat-card-title>
                    Signature and Acknowledgments
                  </mat-card-title>
                  <mat-card-content>
                    <div fxLayout="column" fxLayoutGap="16px">
                      <div fxLayout="column" fxLayoutGap="16px" class="p-t-24">
                        <span class="p-l-24">
                          <strong>• </strong>I certify the information provided in this application is true and complete to
                          the best of my knowledge.
                          I understand falsifying an application, supplying misleading information, or withholding information
                          is grounds for denial of my
                          application or revocation of my permit.
                        </span>
                        <span class="p-l-24">
                          <strong>• </strong>I understand I am responsible for making my own determination regarding the legal
                          risks associated with a
                          Schedule 1 substance under the Federal Controlled Substances Act.
                        </span>
                        <span class="p-l-24">
                          <strong>• </strong>
                          I understand the information provided in application materials, including applicant name, is subject to disclosure
                          under Oregon's public records laws and that certain information may be posted on OHA's website for purposes of license verification.
                        </span>
                        <span class="p-l-24">
                          <strong>• </strong>I understand the following information in the application will be used by Oregon
                          Psilocybin Services to start the background
                          check process: legal name; date of birth; physical address; phone number; email address; proof of
                          identity; prior addresses if lived out of state during the past five years; social
                          security number (if consented); and prior names and aliases.
                        </span>
                        <span class="p-l-24">
                          <strong>• </strong>I understand I must complete and pass a background check as a condition of being
                          granted a worker permit.
                        </span>
                        <span class="p-l-24">
                          <strong>• </strong>I understand I must notify Oregon Psilocybin Services in writing within 10
                          business days of any conviction for any misdemeanor
                          or felony committed and I will be required to undergo another criminal background check.
                        </span>
                        <span class="p-l-24">
                          <strong>• </strong>I understand I must notify Oregon Psilocybin Services in writing within 10
                          business days of a change in contact information.
                        </span>
                        <span class="p-l-24">
                          <strong>• </strong>I have read, understand, and agree to adhere to the Oregon Psilocybin Services
                          Revised Statutes (ORS) Chapter 475A, Oregon
                          Administrative Rules (OAR) Chapter 333, Division 333, as well as any other applicable statutes or
                          laws.
                        </span>
                        <span class="p-l-24">
                          <strong>• </strong>I understand that failure to adhere to these statutes and rules may result in
                          denial of my application or disciplinary
                          action against any future permits issued by Oregon Psilocybin Services.
                        </span>
                        <span class="p-l-24" *ngIf="showOregonResidency(workerLicense.applicationDate)">
                          <strong>• </strong>I understand the psilocybin worker permit has a term of five years from the
                          effective date of approval. If I wish to
                          renew my worker permit after that term, l must complete a renewal application.
                        </span>
                        <span class="p-l-24" *ngIf="!showOregonResidency(workerLicense.applicationDate)">
                          <strong>• </strong>I understand the psilocybin worker permit has a term of one year from the
                          effective date of approval. If I wish to
                          renew my worker permit after that term, l must complete a renewal application.
                        </span>
                        <span class="p-l-24">
                          <strong>• </strong>I understand a worker permit will not be issued until the worker permit fee has
                          been paid and has cleared. I understand
                          that I may not perform the duties described in ORS 475A.445until the effective date of approval.
                        </span>
                        <span class="p-l-24">
                          <strong>• </strong>I understand the worker permit fee is non-refundable.
                        </span>

                        <mat-checkbox [checked]="licenseForm.get('attestation').value" formControlName="attestation">
                          <strong [ngClass]="licenseForm.get('attestation').value? '' : 'text-red-400'" class="text-wrap">I have read and I understand the above statements.</strong>
                        </mat-checkbox>
                      </div>

                      <div fxLayout="column" fxLayoutGap="8px">
                        <span><strong>Name or Initials</strong></span>
                        <div fxLayout="row">
                          <mat-form-field fxFlex="60" fxFlex.lt-lg="100">
                            <input matInput placeholder="Signature" formControlName="electronicSignature">
                          </mat-form-field>
                        </div>
                        <div>
                          <button mat-raised-button color="primary" type="button" (click)="submitApplication()"
                          [disabled]="!licenseForm.valid || !applicantForm.valid || workerLicense.applicants[0].applicantDocuments.length < 1">
                          Submit Application
                        </button>
                        </div>
                      </div>
                      <span class="text-red-400" *ngIf="!applicantForm.valid && !viewEdit">Name and address is incomplete</span>
                      <span class="text-red-400" *ngIf="workerLicense.applicants[0].applicantDocuments.length < 1 && !viewEdit">You need to upload proof of identity</span>
                      <span class="text-red-400" *ngIf="!licenseForm.valid && !viewEdit">You need to complete the signature and acknowledgments section</span>
                    </div>
                  </mat-card-content>
                </mat-card>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </form>
    </mat-step>
    <!-- End of Review-->
  </mat-stepper>
</div>

<!-- After Submit Confirmation-->
<mat-card class="sticky-header p-y-0" [style.background-color]="sharedService.red" *ngIf="showConfirmed">
  <mat-card-content>
    <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center" style="color: #303030">
      <mat-icon class="medium-icon m-24">badge</mat-icon>
      <span class="f-s-20" fxFlex>{{pageTitle()}}</span>
    </div>
  </mat-card-content>
</mat-card>

<mat-card *ngIf="showConfirmed">
  <mat-card-content>
    <span class="f-s-16">Thank you for submitting the Oregon Psilocybin Services worker permit application. You can review your application status
      and updates to the status in TLC. Please be sure to monitor your email for follow-up communication from the Oregon Psilocybin Services
      Licensing Team. To ensure you receive emails from OPS, please consider adding Licensing.Psilocybin@oha.oregon.gov to your email contacts to
      prevent the email going to your spam folder. </span>
  </mat-card-content>
</mat-card>

<mat-card *ngIf="showConfirmed">

  <mat-card-content>
    <div fxLayout="column" fxLayoutGap="8px" fxLayoutAlign="center center">
      <app-payment-notice [canPay]="false" [entityType]="paymentService.WorkerPermitPaymentOption"
        [feeType]="paymentService.applicationFeeType" [id]="workerLicense.id"></app-payment-notice>

      <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="center center">
        <button mat-raised-button color="primary" type="button" (click)="continue()">Continue</button>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<!-- After Submit Lists-->
<div *ngIf="showSubmitted">
  <div>
    <button *ngIf="showStartButton && canApplyAgain" mat-flat-button type="button" color="primary" (click)="startApplication()">Start New Application</button>
  </div>
  <div fxLayout="column" fxLayoutGap="8px">
    <div *ngFor="let program of workerLicenses">
      <mat-card [style.background]="cardColor" fxFlex="40" fxFlex.gt-lg="30" fxFlex.lt-lg="100">
        <mat-card-content>
          <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="24px">
            <div fxLayout="column" fxLayoutGap="8px" fxLayoutAlign="center" fxFlex>
              <div fxLayout="row" fxLayoutAlign="start center">
                <span fxFlex="40"><strong>Permit Holder</strong></span>
                <div fxLayout="row" fxLayoutGap="8px">
                  <span class="f-s-18">{{program.applicants[0].legalFirstName}}</span>
                  <span class="f-s-18">{{program.applicants[0].legalLastName}}</span>
                </div>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" class="m-b-0" style="height: 25pt">
                <span fxFlex="40"><strong>Permit Id</strong></span>
                <span class="f-s-16" id="programId">{{program.licenseId}}</span>
                <button mat-icon-button (click)="sharedService.copyToClipboard(program.licenseId)" color="primary"
                  matTooltip="Copy to Clipboard" matTooltipShowDelay=800 aria-label="Copy to Clipboard">
                  <mat-icon>content_copy</mat-icon>
                </button>
              </div>

              <div fxLayout="row" fxLayoutAlign="start center">
                <span fxFlex="40"><strong>Status</strong></span>
                <div fxLayout="column">
                  <span class="f-s-20 f-w-700" [ngClass]="program.status === sharedService.approved? 'text-light-green-600':
                                  (program.status === sharedService.submitted ||
                                  program.status === sharedService.pending ||
                                  program.status === sharedService.underReview)? 'text-orange-900' : 'text-red-900'" *ngIf="program.status != sharedService.submittedForApproval">
                    {{program.status}}
                  </span>
                  <span class="f-s-20 f-w-700" [ngClass]="'text-orange-900'" *ngIf="program.status == sharedService.submittedForApproval">{{sharedService.underReview}}</span>
                </div>
              </div>
              <div fxLayout="row">
                <span fxFlex="40"><strong>Date Submitted</strong></span>
                <span class="f-s-18">{{program.applicationDate | date}}</span>
              </div>
              <div fxLayout="row" *ngIf="program.status === sharedService.approved">
                <span fxFlex="40"><strong>Date Approved</strong></span>
                <span class="f-s-18">{{program.approvedDate | date}}</span>
              </div>
              <div fxLayout="row"
                *ngIf="program.status === sharedService.approved || program.status === sharedService.expired">
                <span fxFlex="40"><strong>Expiration Date</strong></span>
                <span class="f-s-18">{{program.expirationDate | date}}</span>
              </div>
              <div fxLayout="row"
                *ngIf="program.status == 'Approved, Pending Payment and Clearance'|| program.status == 'Approved'">
                <span fxFlex="40"><strong>Payment Status</strong></span>
                <span [ngClass]="hasPaidApplicationFee(program) ? 'text-light-green-600':  'text-red-900'">
                  <span class="f-s-18">{{getPaymentFeeStatus(program)}}</span>
                </span>
              </div>
              <div fxLayout="row" *ngIf="hasPaidApplicationFee(program)">
                <span fxFlex="40"><strong>Payment Cleared</strong></span>
                <span class="f-s-18">{{getapplicationClearedDate(program)}}</span>
              </div>
            </div>

            <div fxLayout="column" fxLayout.lt-lg="row" fxLayoutAlign="center start" fxLayoutGap="8px">
              <button *ngIf="canRenew(program)" mat-flat-button type="button" color="accent"
                (click)="renewPermit(program.id)">Renew</button>
              <button
                *ngIf="!program.canRenew || program.status === 'incomplete' || program.status === 'insufficient'"
                mat-flat-button type="button" color="primary" (click)="editApplication(program)">
                <div fxLayout="row" fxLayoutGap="8px" *ngIf="showEditLoading">
                  <span>Loading</span>
                  <mat-spinner color="accent" [diameter]="25" [strokeWidth]="5" aria-label="Loading"></mat-spinner>
                </div>
                <span *ngIf="!showEditLoading && program.status != 'Pending'">View</span>
                <span *ngIf="!showEditLoading && program.status == 'Pending'">Edit</span>
              </button>
              <button *ngIf="program.status == 'Submitted'" mat-flat-button type="button" color="accent"
              (click)="requestWithdraw(program)">Request Withdraw
            </button>
            <button *ngIf="program.status == 'Approved'" mat-flat-button type="button" color="accent"
            (click)="requestSurrender(program)">Request Surrender
            </button>
                <a  *ngIf="hasUnpaidFees(program) && program.status == sharedService.awaitingPayment" mat-flat-button type="button" color="warn" routerLink="/payments">Pay Fee</a>
                <button  *ngIf="program.status == 'Approved'" mat-flat-button (click)="viewWorkerLicenseDetails(program)">Print Permit</button>
              </div>

            <div *ngIf="program.status == 'Approved'" fxLayout="column" fxLayout.lt-lg="row" fxLayoutAlign="center start" fxLayoutGap="8px">
              <a [routerLink]="['/license-information/WorkerPermit', program.id]">
                <qrcode [qrdata]=" url + '/license-information/WorkerPermit/'+ program.id"></qrcode>
              </a>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>

<div *ngIf="loading">
  <div [ngClass]="{'global-loader': true, 'global-loader-dark': sharedService.darkMode}">
    <h1>Loading</h1>
  </div>
</div>
