<section *ngIf="generating" class="no print">
  Generating...close when complete.
</section>
<section class="p-b-16">
  <div fxLayout="row" fxLayoutGap="8px" class="m-t-16 no print">
    <button type="button" mat-stroked-button color="primary" onclick="printJS({ printable: 'paymentSlip', type: 'html' })">
      Print Form
   </button>

    <button type="button" mat-stroked-button color="primary" (click)="pdf.saveAs('remittance.pdf')">
      Export to PDF
    </button>

    <button type="button" mat-stroked-button color="warn" (click)="cancel()">Close</button>
  </div>
</section>
  <mat-dialog-content>
    <kendo-pdf-export #pdf paperSize="letter" [scale]="0.75" margin="1cm">
      <div fxLayout="column" fxLayoutGap="16px" id="paymentSlip" #paymentSlip class="f-s-16">
        <div fxLayout="row" fxLayoutGap="8px">
          <div fxLayout="column" fxFlex>
            <span class="f-s-18"><strong>PUBLIC HEALTH DIVISION</strong></span>
            <span class="f-s-18"><strong>CENTER FOR HEALTH PROTECTION</strong></span>
            <span><strong>Oregon Psilocybin Services</strong></span>
            <span><a href="http://oregon.gov/psilocybin">http://oregon.gov/psilocybin</a></span>
          </div>
          <img class="p-4" src="../../../../../assets/images/ohaLogoTransparent-small.png" alt="OHA Logo">
        </div>

        <div fxLayout="row" fxLayoutAlign="center">
          <span class="f-s-18"><strong>{{pageTitle}} </strong></span>
        </div>

        <div fxLayout="column" fxLayoutGap="4px" *ngIf="showTrainingProgram">
          <div fxLayout="row">
            <span fxFlex="35"><strong>Name</strong></span>
            <span>{{licenseName}}</span>
          </div>
          <div fxLayout="row">
            <span fxFlex="35"><strong>Responsible Party(RP) Name</strong></span>
            <span>{{responsiblePartyName}} </span>
          </div>
          <div fxLayout="row">
            <span fxFlex="35"><strong>RP Phone Number</strong></span>
            <span>{{responsiblePartyPhone | mask:'(000)-000-0000 000000'}}</span>
          </div>
          <div fxLayout="row">
            <span fxFlex="35"><strong>RP Email</strong></span>
            <span>{{responsiblePartyEmail}}</span>
          </div>
          <div fxLayout="row">
            <span fxFlex="35"><strong>TLC ID Number (if known)</strong></span>
            <span>{{licenseId}}</span>
          </div>
          <div fxLayout="row">
            <span fxFlex="35"><strong>Application Date</strong></span>
            <span>{{applicationDate}}</span>
          </div>
        </div>

        <div fxLayout="column" fxLayoutGap="4px" *ngIf="!showTrainingProgram">
          <div fxLayout="column" fxLayoutGap="4px" *ngIf="!isEntityLicense">
            <div fxLayout="row">
              <span fxFlex="35"><strong>{{individualType}}Name</strong></span>
              <span>{{responsiblePartyName}} </span>
            </div>
            <div fxLayout="row">
              <span fxFlex="35"><strong>{{individualType}} Phone Number</strong></span>
              <span>{{responsiblePartyPhone | mask:'(000)-000-0000 000000'}}</span>
            </div>
            <div fxLayout="row">
              <span fxFlex="35"><strong>{{individualType}} Email</strong></span>
              <span>{{responsiblePartyEmail}}</span>
            </div>
          </div>

          <div fxLayout="column" fxLayoutGap="4px" *ngIf="isEntityLicense">
            <div fxLayout="row">
              <span fxFlex="35"><strong>Licensee Name</strong></span>
              <span>{{licenseName}}</span>
            </div>
            <div fxLayout="row">
              <span fxFlex="35"><strong>Point of Contact Name</strong></span>
              <span>{{responsiblePartyName}} </span>
            </div>
            <div fxLayout="row">
              <span fxFlex="35"><strong>Point of Contact Phone Number</strong></span>
              <span>{{responsiblePartyPhone | mask:'(000)-000-0000 000000'}}</span>
            </div>
            <div fxLayout="row">
              <span fxFlex="35"><strong>Point of Contact Email</strong></span>
              <span>{{responsiblePartyEmail}}</span>
            </div>
          </div>

          <div fxLayout="row">
            <span fxFlex="35"><strong>TLC ID Number</strong></span>
            <span>{{licenseId}}</span>
          </div>
          <div fxLayout="row">
            <span fxFlex="35"><strong>Application Date</strong></span>
            <span>{{applicationDate}}</span>
          </div>
        </div>

        <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="center">
          <div fxLayout="column" fxFlex="50" fxLayoutGap="8px" fxLayoutAlign="start center">
            <span><strong>Type of Payment</strong></span>
            <mat-radio-group fxLayout="column" aria-labelledby="Type of Payment">
              <mat-radio-button value="1" class="radio-button">Cashier's Check</mat-radio-button>
              <mat-radio-button value="2" class="radio-button">Money Order</mat-radio-button>
              <mat-radio-button value="3" class="radio-button">Personal/Business Check</mat-radio-button>
              <mat-radio-button value="4" class="radio-button">Cash <strong>(Please Do Not Mail Cash Payments)</strong>
              </mat-radio-button>
            </mat-radio-group>
          </div>
          <div fxLayout="column" fxFlex="50" fxLayoutGap="8px" fxLayoutAlign="start center">
            <span><strong>Payment Purpose</strong></span>
            <mat-radio-group>
              <mat-radio-group fxLayout="column" aria-labelledby="Payment Purpose">
                <!-- Worker Permit -->
                <mat-radio-button value="5" class="radio-button" *ngIf="showWorkerPermit">New Application Fee $25</mat-radio-button>

                <!--Licenses-->
                <div fxLayout="column" *ngIf="!showWorkerPermit">
                  <mat-radio-button value="5" class="radio-button" *ngIf="application">New Application Fee {{applicationFee}}</mat-radio-button>
                  <mat-radio-button value="6" class="radio-button" *ngIf="annual">Annual License Fee {{annualFee}}</mat-radio-button>
                  <mat-radio-button value="8" class="radio-button" *ngIf="reduced">Reduced Annual License Fee {{reducedFee}}</mat-radio-button>
                  <mat-radio-button value="8" class="radio-button" *ngIf="changeRequest">Change Request Fee{{changeRequestFee}}</mat-radio-button>
                  <mat-radio-button value="8" class="radio-button" *ngIf="changeLocation">Change of Location Fee {{changeLocationFee}}</mat-radio-button>
                  <mat-radio-button value="8" class="radio-button" *ngIf="civilPenalty">Civil Penalty Fee ${{data.amount}}</mat-radio-button>
                </div>
              </mat-radio-group>
            </mat-radio-group>
          </div>
        </div>

        <span fxFlex></span>

        <div fxLayout="row" fxLayoutAlign="center start">
          <strong>Instructions for Submitting the Application and Fee Payment by Mail or In-Person</strong>
        </div>

        <div fxLayout="column">
          <span>Money orders and checks are payable to: <strong>Oregon Psilocybin Services</strong></span>
          <span>
            Personal and business checks will be held for 21 days to ensure payment clearance.
            Applications will be reviewed once payment has been cleared.
          </span>
        </div>

        <div fxLayout="column">
          <span><strong>Mailing the application packet, money order or check, and remittance form:</strong></span>
          <span>
            Oregon Health Authority - Public Health Division - Fiscal Services
          </span>
          <span>
            RE: OPS, PO Box 14260, Portland, OR 97293-0260
          </span>
        </div>

        <div fxLayout="column">
          <span><strong>In-person payment with a money order or check:</strong></span>
          <span>
            To make an in-person fee payment with a money order or check, proceed to Cashier's Office on the
            second floor of the Portland State Office Building at 800 NE Oregon St, Suite 200, Portland, OR 97232 - Hours:
            9am to 4pm, Mon to Fri
          </span>
        </div>

        <span>
          Additional instructions: The Cashier's Office window is located on the second floor next to the Vital Health
          Statistics window.
          If security asks if you have an appointment, you may let them know you are dropping off a payment for Oregon
          Psilocybin Services at the
          Cashier's Office window which does not require an appointment. Please include the remittance form with the
          payment and provide the application,
          if not already submitted by mail.
        </span>

        <div fxLayout="column">
          <span><strong>To make cash payment arrangements or for payment questions, please contact:</strong></span>
          <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
            <span>licensing.psilocybin@oha.oregon.gov </span>
            <span>or (971) 341-1509</span>
          </div>
        </div>

        <span *ngIf="application || showTrainingProgram || showWorkerPermit"><strong>{{applicationSlipReference}}</strong></span>
        <span *ngIf="annual || reduced"><strong>{{annualSlipReference}}</strong></span>

      </div>
    </kendo-pdf-export>
  </mat-dialog-content>
