import {
  Component,
  Output,
  EventEmitter,
  Input,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  OnInit
} from "@angular/core";
import { environment } from "environments/environment";
import { AuthService } from "app/auth/auth.service";
import { MsalBroadcastService } from "@azure/msal-angular";
import { filter, switchMap } from "rxjs/operators";
import { EventMessage, EventType } from "@azure/msal-browser";
import { SharedService } from "app/services/core/shared.service";
import { InternalApplicationMessagingService } from "app/components/internal-app-messaging/internal-app-messaging.service";
import { Subscription, timer } from "rxjs";
import { SB303Service } from "app/services/303/303.service";

@Component({
  selector: "app-navbar",
  host: {
    class: "matero-header",
  },
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default,
})
export class NavbarComponent implements OnInit {
  subscription !: Subscription;
  public environment = environment;
  public serverCenterLoaded: boolean = false;
  public hasServiceCenterLicense: boolean = false;
  @Input() showToggle = true;
  @Output() toggleSidenav = new EventEmitter<void>();

  constructor(
    private authService: AuthService,
    public sharedService: SharedService,
    private msalBroadcastService: MsalBroadcastService,
    public internalApplicationMessagingService: InternalApplicationMessagingService,
    public sb303Service: SB303Service
  ) {}

  ngOnInit(): void{
    this.msalBroadcastService.msalSubject$
    .pipe(
      filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS)
    )
    .subscribe(() => {
      this.toggleSidenav.emit();
      this.internalApplicationMessagingService.loadUnreadCount();

      //timer to check for unread messages - in milliseconds
      this.subscription = timer(0, 300000).pipe(
        switchMap(async () =>{
         this.internalApplicationMessagingService.loadUnreadCount()
      })
      ).subscribe();
    });

  }

  public get username(): string{
    return this.authService.username
  }

  login() {
    this.authService.login();
  }

  isAuthenticated(): boolean {
    return this.authService.isAuthenticated();
  }


  isReportingPeriod(): boolean {
    if(!this.isAuthenticated())
      return false;

    if(!this.serverCenterLoaded)
    {
      this.serverCenterLoaded = true;
      this.sb303Service.getServiceCenterLicenses().subscribe(
        response => {
          this.hasServiceCenterLicense = response;
        },
        error => console.log('error', error)
      );
    }

    const today = new Date();
    const month = today.getMonth() + 1;
    const day = today.getDate();

    const isInRange = (startMonth: number, startDay: number, endMonth: number, endDay: number) => {
      return (month === startMonth && day >= startDay) && (month === endMonth && day <= endDay);
    };

    return (
      isInRange(4, 1, 4, 25) ||
      isInRange(7, 1, 7, 25) ||
      isInRange(10, 1, 10, 25) ||
      isInRange(1, 1, 1, 25)
    );
  }

  logout() {
    this.authService.logout();
  }

  toggleTheme() {
    this.sharedService.darkMode = !this.sharedService.darkMode;
    this.sharedService.userProfile.theme = this.sharedService.darkMode ? "dark" : "light";
    if(this.isAuthenticated()){
      this.sharedService.updateUserProfileState();
    }
    this.sharedService.toggleTheme();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
