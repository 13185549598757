<mat-card class="sticky-header p-y-0" [style.background-color]="sharedService.purple" *ngIf="!showSubmitted">
  <mat-card-content>
    <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
      <mat-icon class="medium-icon m-24">group</mat-icon>
      <span class="f-s-20" *ngIf="!renewing">Apply For a Facilitator License</span>
      <span class="f-s-20" *ngIf="renewing">Renew Facilitator License</span>
      <span fxFlex></span>
      <button mat-flat-button color="primary"
        *ngIf="viewForm && ((facilitatorLicenses.length > 1) || (facilitatorLicenses.length == 1 && facilitatorLicense.status != 'Pending'))" (click)="closeApplication()">Close</button>
        <button mat-flat-button color="primary"
        *ngIf="renewing" (click)="closeApplication()">Close</button>
    </div>
  </mat-card-content>
</mat-card>

<div fxLayout="column" fxLayoutGap="8px" *ngIf="viewForm && !showSubmitted">
  <mat-stepper [orientation]="sharedService.isMobile? 'vertical' : 'horizontal'" #facilitatorStepper
    [selectedIndex]="step" (selectionChange)="changeStep($event)">
    <!--Getting Started-->
    <mat-step>
      <ng-template matStepLabel>
        <span matTooltip="Getting Started" matTooltipPosition="below" [matTooltipShowDelay]="500">Getting Started</span>
      </ng-template>
      <div fxLayout="column" fxLayoutGap="8px" class="p-b-8">
        <mat-card>
          <mat-card-title>
            <span>
              Thank you for your interest in applying for a psilocybin facilitator license
              through the Oregon Psilocybin Services - Training Program, Licensing, and Compliance system (TLC).
            </span>
          </mat-card-title>
          <mat-card-content>
            <div fxLayout="column">
              <span>More detailed information to support the application process can be found in the Psilocybin Facilitator License Online Application Guide.</span>

              <span>
                Please visit
                <a href="https://www.oregon.gov/oha/PH/PREVENTIONWELLNESS/Pages/Psilocybin-License-Facilitator.aspx" target="_blank" rel="noreferrer noopener" style="word-break: break-all;
                white-space: normal;">https://www.oregon.gov/oha/PH/PREVENTIONWELLNESS/Pages/Psilocybin-License-Facilitator.aspx</a> to access this guide.
              </span>
            </div>

            <div fxLayout="row" fxLayout.lt-lg="column" style="padding-top: 2em" fxLayoutGap="12px">
            <div fxLayout="column" fxLayoutGap="8px">
              <strong>Facilitator License Requirements:</strong>
              <span class="p-l-24">
                <strong>• </strong>Must be 21 years of age or older
              </span>
              <span class="p-l-24">
                <strong>• </strong>Must have a high school diploma (or equivalent)
              </span>
              <span class="p-l-24">
                <strong>• </strong>Must be a resident of Oregon (provision expires in 2025)
              </span>
              <span class="p-l-24">
                <strong>• </strong>Must complete a facilitator training program with curriculum that has been approved by OPS
              </span>
              <span class="p-l-24">
                <strong>• </strong>Must pass the OPS Regulations Exam
              </span>
              <span class="p-l-24">
                <strong>• </strong>Must pass a criminal background check
              </span>
              <span class="p-l-24">
                <strong>• </strong>Must pay a license application fee
              </span>
              <span class="p-l-24">
                <strong>• </strong>Must pay an annual license fee
              </span>
            </div>

            <div fxLayout="column"  fxLayoutGap="8px">
              <strong>The completed application packet contains the following:</strong>
              <span class="p-l-24">
                <strong>• </strong>Completed and signed facilitator license application
              </span>
              <span class="p-l-24">
                <strong>• </strong>Uploaded copy of the front and back of a valid, unexpired government-issued photo identification for the applicant
              </span>
              <span class="p-l-24">
                <strong>• </strong>Uploaded certificate of completion from a psilocybin facilitator training program with OPS approved curriculum
              </span>
              <span class="p-l-24">
                <strong>• </strong>Uploaded copy of the applicant's social equity plan
              </span>
              <span class="p-l-24">
                <strong>• </strong>A passing score of 75 percent or better on the OPS administered exam
              </span>
              <span class="p-l-24">
                <strong>• </strong><strong>$150</strong> Non-refundable facilitator license application fee paid and cleared
              </span>
            </div>
          </div>

          <div fxLayout="row" style="padding-top: 2em">
            <div fxLayout="column" fxLayoutGap="8px">
              <strong>Important Notes: </strong>
              <span class="p-l-24">
                <strong>• </strong>Please review each section of the application to ensure it is complete
              </span>
              <span class="p-l-24">
                <strong>• </strong>Toward the end of the application, a fee page will describe how to submit the application fee which may be paid online or in-person
              </span>
              <span class="p-l-24">
                <strong>• </strong>
                OPS will not review an application packet until the application fee is paid and cleared.
                You have 90 days from the date of application submission to pay the application fee or it will be closed.
              </span>
              <span class="p-l-24">
                <strong>• Incomplete and Insufficient Application Statuses:</strong>
                Please review the application guide for more detailed information on all application statuses.
              </span>
              <div fxLayout="column" style="padding-left: 1em;" fxLayoutGap="8px">
                <span class="p-l-24">
                  <strong>• </strong>
                  Incomplete: An application is considered incomplete if the application is missing required information
                  (e.g., the application fee has not been paid or the applicant did not submit fingerprints).
                  If the application is incomplete, we will notify you by email to give you an opportunity to make your application complete.
                  You have 90 days from the initial date you are notified to complete your application. If you fail to do so, the application will be closed.
                </span>
                <span class="p-l-24">
                  <strong>• </strong>
                  Insufficient: An application is considered insufficient if the complete application or site inspection
                  does not meet requirements outlined in statute or rule. If the application is insufficient,
                  we will notify you by email to give you an opportunity to meet requirements.
                  You will have 90 days from the initial date you are notified to meet requirements, or your application will be denied.
                </span>
              </div>
            </div>
          </div>

          <div fxLayout="column" fxLayoutGap="8px" style="padding-top: 2em">
            <span>
              <strong>When you click the continue button at the bottom of this page, you will proceed to the psilocybin facilitator license application.</strong>
              The application automatically saves in TLC. You may exit the application and come back later to complete and submit the application, if needed.
            </span>
          </div>
          <div fxLayout="column" fxLayoutGap="8px" style="padding-top: 2em;" >
            <span>
              <strong>DISCLAIMER:</strong>
              Psilocybin remains a schedule I drug under the federal Controlled Substances Act.
              Applicants are responsible for making their own determinations regarding the legal risks associated with participating in this program.
              The information you provide in your application materials, including applicant name, is subject to disclosure under Oregon's public records laws.
            </span>
          </div>
          </mat-card-content>
        </mat-card>

        <div *ngIf="!hasApplication && showStartButton">
          <button mat-flat-button type="button" color="primary" (click)="startApplication()">Start Application</button>
        </div>
        <div *ngIf="hasApplication" fxLayout="row" fxFlex="50" fxFlex.lt-md="100" class="p-t-16">
          <button mat-raised-button matStepperNext>Continue</button>
        </div>
      </div>
    </mat-step>

    <!--Applicant-->
    <mat-step [stepControl]="applicantForm" *ngIf="facilitatorLicenses.length > 0">
      <form [formGroup]="applicantForm">
        <ng-template matStepLabel>
          <span class="text-red-400" *ngIf="(!applicantForm.valid && !applicantForm.pristine && !licenseLocked) || !applicantDocumentsValid || !additionalDocumentsValid"
            matTooltip="Personal Information Incomplete" matTooltipPosition="below" [matTooltipShowDelay]="500">
            Applicant Information Incomplete</span>
          <span *ngIf="(applicantForm.valid || applicantForm.pristine || licenseLocked) && (applicantDocumentsValid && additionalDocumentsValid)"
            matTooltip="Applicant Information" matTooltipPosition="below" [matTooltipShowDelay]="500">Applicant Information</span>
        </ng-template>
        <div fxLayout="column">
          <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
            <mat-card fxFlex="50" fxFlex.lt-lg="100">
              <mat-card-title>
                <div fxLayout="row" fxLayout.lt-lg="column">
                  <span fxFlex>Applicant Information</span>
                  <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
                    <span class="f-s-16"><strong>Facilitator Id</strong></span>
                    <span class="f-s-16" id="programId">{{facilitatorLicense.licenseId}}</span>
                    <button mat-icon-button (click)="sharedService.copyToClipboard(facilitatorLicense.licenseId)" color="primary"
                            matTooltip="Copy to Clipboard" matTooltipShowDelay=800 aria-label="Copy to Clipboard">
                      <mat-icon>content_copy</mat-icon>
                    </button>
                  </div>
                </div>
              </mat-card-title>
              <mat-card-content>
                <div fxLayout="column" fxLayoutGap="8px">
                  <span><strong>Legal Name</strong></span>
                  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" fxLayoutGap.lt-lg="">
                    <mat-form-field fxFlex="40" fxFlex.lt-md="100">
                      <input matInput placeholder="First" formControlName="legalFirstName">
                      <mat-error *ngIf="applicantForm.get('legalFirstName').invalid">
                        Please enter your first name
                      </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="40" fxFlex.lt-md="100">
                      <input matInput placeholder="Last" formControlName="legalLastName">
                      <mat-error *ngIf="applicantForm.get('legalLastName').invalid">
                        Please enter your last name
                      </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="20" fxFlex.lt-md="100">
                      <input matInput placeholder="Middle" formControlName="legalMiddleName">
                    </mat-form-field>
                  </div>

                  <span><strong>Preferred Name</strong></span>
                  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" fxLayoutGap.lt-lg="">
                    <mat-form-field fxFlex="40" fxFlex.lt-md="100">
                      <input matInput placeholder="First" formControlName="preferredFirstName">
                    </mat-form-field>

                    <mat-form-field fxFlex="40" fxFlex.lt-md="100">
                      <input matInput placeholder="Last" formControlName="preferredLastName">
                    </mat-form-field>

                    <mat-form-field fxFlex="20" fxFlex.lt-md="100">
                      <input matInput placeholder="Middle" formControlName="preferredMiddleName">
                    </mat-form-field>
                  </div>

                  <div fxLayout="column">
                    <span><strong>Prior Names and Aliases</strong></span>
                    <span>This information is used for the purposes of the background check</span>
                    <div fxLayout="row" fxLayoutGap="8px" fxLayout.lt-lg="column">
                      <mat-form-field fxFlex="80" fxFlex.lt-md="100">
                        <input matInput placeholder="Name, other name, any other names"
                          formControlName="alternateNames">
                      </mat-form-field>

                      <mat-form-field>
                        <mat-label>Date of Birth</mat-label>
                        <input placeholder="mm/dd/yyyy" formControlName="dob" matInput [matDatepicker]="dob"
                          [max]="sharedService.today" />
                        <mat-datepicker-toggle matSuffix [for]="dob"></mat-datepicker-toggle>
                        <mat-datepicker #dob>
                          <mat-datepicker-actions>
                            <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
                            <button mat-button matDatepickerCancel>Cancel</button>
                          </mat-datepicker-actions>
                        </mat-datepicker>
                        <mat-error *ngIf="applicantForm.get('dob').value > sharedService.today">
                          Please choose an earlier date.
                        </mat-error>
                        <mat-error *ngIf="applicantForm.get('dob').hasError('required')">
                          Date of birth is required
                        </mat-error>
                      </mat-form-field>

                    </div>
                  </div>

                  <div fxLayout="column">
                    <span><strong>*SOCIAL SECURITY NUMBER DISCLOSURE:</strong></span>
                    <span style="padding-top: 1em; padding-left: 1em"><strong>• </strong>
                      As part of your application for an initial or renewal license, Federal and State laws require you to provide your
                      Social Security Number (SSN),
                      if you have one, to Oregon Psilocybin Services for child support enforcement purposes (42 USC § 666(a)(13) & ORS
                      25.785).
                      If you are an applicant or licensee and you have an SSN but fail to provide your SSN, Oregon Psilocybin Services may
                      refuse to process your application.
                      Your SSN will be used only for child support enforcement purposes unless you confirm your agreement that OPS can use
                      it for administrative purposes
                      as well.
                    </span>

                    <span style="padding-top: 1em; padding-left: 1em">
                      <strong>IMPORTANT NOTE: If you do not have a social security number, you may still be licensed or permitted by Oregon
                        Psilocybin Services.</strong>
                    </span>

                    <span style="padding-top: 1em; padding-left: 1em"><strong>• </strong>
                      Based on our authority under OAR 333-333-4100, we are requesting your voluntary consent to use your SSN for the
                      following administrative purposes only:
                      to positively confirm your identity during the criminal records check. Oregon Psilocybin Services will not deny you
                      any rights, benefits or privileges
                      otherwise provided by law if you do not consent to use of your SSN for these administrative purposes (5 USC§
                      552(a)).
                      <strong>Please check the appropriate box next to the social security field indicating whether you consent or do not
                        consent.</strong>
                    </span>
                    <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="16px" fxLayoutGap.lt-lg="" fxLayoutAlign="start center"
                      fxLayoutAlign.lt-lg="">
                      <mat-form-field fxFlex="25" fxFlex.lt-md="100">
                        <input matInput placeholder="SSN" formControlName="ssn" mask="000-00-0000">
                        <mat-error *ngIf="validateSsn()">
                          SSN is Required
                        </mat-error>
                      </mat-form-field>
                      <mat-checkbox formControlName="noSsn">
                        I don't have a social security number
                      </mat-checkbox>
                    </div>
                    <span>Do you consent to use of SSN for purposes of confirming identity during a criminal records check?</span>
                    <mat-error *ngIf="validateSsnAttestation()">
                      Consent choice is Required
                    </mat-error>
                    <div fxLayout="row" fxLayoutGap="8px">
                      <div fxLayout="column" fxLayoutGap="8px">
                        <mat-checkbox formControlName="ssnAttestation" (click)="changeAttestation(true)">
                          Yes
                        </mat-checkbox>
                      </div>
                      <div fxLayout="column" fxLayoutGap="8px">
                        <mat-checkbox formControlName="ssnNoAttestation" (click)="changeAttestation(false)">
                          No
                        </mat-checkbox>
                      </div>
                    </div>
                  </div>
                  <div fxLayout="column" fxLayoutGap="12px">
                    <span><strong>
                      *For purposes of these questions, “license” means any professional or occupational license, permit or certification that authorizes an individual
                      to engage in an occupation or business, issued by a local, state, or tribal governmental entity, or by a governmental body in another country.
                    </strong></span>
                    <span class="text-wrap" [ngStyle]="{'color': applicantForm.get('heldLicense').value == null ? 'red' : ''}">In the last 10 years, have you held a license in Oregon or in another state or country? If yes, please describe the type of license, what state or country it was issued in, and provide dates that the license was in effect.</span>
                    <mat-radio-group aria-label="Select an option" formControlName="heldLicense" fxLayoutGap="8px">
                      <mat-radio-button [value]="true">Yes</mat-radio-button>
                      <mat-radio-button [value]="false">No</mat-radio-button>
                    </mat-radio-group>
                    <mat-error *ngIf="validateHeldLicense()">This field is required</mat-error>
                    <mat-form-field fxFlex="75" fxFlex.lt-lg="100" *ngIf="applicantForm.get('heldLicense').value">
                      <textarea matInput placeholder="Previous Licenses Held" cols="2" formControlName="licensesHeld"></textarea>
                    </mat-form-field>
                    <mat-error *ngIf="validateHeldLicenses()"></mat-error>

                    <span class="text-wrap" [ngStyle]="{'color': applicantForm.get('deniedLicense').value == null ? 'red' : ''}">In the last 10 years, have you been denied a license? If yes, please upload documentation explaining why and provide all relevant information.</span>
                    <mat-radio-group aria-label="Select an option" formControlName="deniedLicense" fxLayoutGap="8px">
                      <mat-radio-button [value]="true">Yes</mat-radio-button>
                      <mat-radio-button [value]="false">No</mat-radio-button>
                    </mat-radio-group>
                    <mat-error *ngIf="validateDeniedLicense()">This field is required</mat-error>
                    <mat-error *ngIf="!validDeniedLicenseDocument()">You must upload a supporting document</mat-error>
                    <div *ngIf="applicantForm.get('deniedLicense').value">
                      <input type="file" class="file-input" (change)="uploadAdditionalDocument($event, deniedLicenseType)"
                        #deniedLicenseUpload />
                      <div fxLayout="row" fxLayoutGap="8px">
                        <div class="file-upload">
                          <button type="button" [disabled]="licenseLocked"
                            mat-raised-button color="primary" class="upload-btn" (click)="deniedLicenseUpload.click()">
                            Browse
                            <mat-icon>attach_file</mat-icon>
                          </button>
                        </div>
                      </div>
                    </div>

                    <span class="text-wrap" [ngStyle]="{'color': applicantForm.get('subjectToDiscipline').value == null ? 'red' : ''}">For any license you have held, have you been subject to discipline by the entity that issued the license or had the license suspended or revoked in the last 10 years?
                      If so, please upload documentation explaining the reasons for the discipline or revocation and provide all relevant documentation.</span>
                    <mat-radio-group aria-label="Select an option" formControlName="subjectToDiscipline" fxLayoutGap="8px">
                      <mat-radio-button [value]="true">Yes</mat-radio-button>
                      <mat-radio-button [value]="false">No</mat-radio-button>
                    </mat-radio-group>
                    <mat-error *ngIf="validateSubjectToDiscipline()">This field is required</mat-error>
                    <mat-error *ngIf="!validSubjectToDisciplineDocument()">You must upload a supporting document</mat-error>
                    <div *ngIf="applicantForm.get('subjectToDiscipline').value">
                      <input type="file" class="file-input" (change)="uploadAdditionalDocument($event, subjectToDisciplineType)"
                        #subjectToDisciplineUpload />
                      <div fxLayout="row" fxLayoutGap="8px">
                        <div class="file-upload">
                          <button type="button" [disabled]="licenseLocked"
                            mat-raised-button color="primary" class="upload-btn" (click)="subjectToDisciplineUpload.click()">
                            Browse
                            <mat-icon>attach_file</mat-icon>
                          </button>
                        </div>
                      </div>
                    </div>

                    <span class="text-wrap" [ngStyle]="{'color': applicantForm.get('suedForDamages').value == null ? 'red' : ''}">In the last 10 years, have you been sued for money damages related to work you provided under a license? If yes, please upload documentation to explain and provide all relevant documentation.</span>
                    <mat-radio-group aria-label="Select an option" formControlName="suedForDamages" fxLayoutGap="8px">
                      <mat-radio-button [value]="true">Yes</mat-radio-button>
                      <mat-radio-button [value]="false">No</mat-radio-button>
                    </mat-radio-group>
                    <mat-error *ngIf="validateSuedForDamages()">This field is required</mat-error>
                    <mat-error *ngIf="!validSuedForDamagesDocument()">You must upload a supporting document</mat-error>
                    <div *ngIf="applicantForm.get('suedForDamages').value">
                      <input type="file" class="file-input" (change)="uploadAdditionalDocument($event, suedForDamagesType)"
                        #suedForDamagesUpload />
                      <div fxLayout="row" fxLayoutGap="8px">
                        <div class="file-upload">
                          <button type="button" [disabled]="licenseLocked"
                            mat-raised-button color="primary" class="upload-btn" (click)="suedForDamagesUpload.click()">
                            Browse
                            <mat-icon>attach_file</mat-icon>
                          </button>
                        </div>
                      </div>
                    </div>

                    <span class="text-wrap" [ngStyle]="{'color': applicantForm.get('settledAllegations').value == null ? 'red' : ''}">In the last 10 years, have you settled allegations or claims against you related to a license? If so, please upload documentation to explain and provide all relevant documentation.</span>
                    <mat-radio-group aria-label="Select an option" formControlName="settledAllegations" fxLayoutGap="8px">
                      <mat-radio-button [value]="true">Yes</mat-radio-button>
                      <mat-radio-button [value]="false">No</mat-radio-button>
                    </mat-radio-group>
                    <mat-error *ngIf="validateSettledAllegations()">This field is required</mat-error>
                    <mat-error *ngIf="!validSettledAllegationsDocument()">You must upload a supporting document</mat-error>
                    <div *ngIf="applicantForm.get('settledAllegations').value">
                      <input type="file" class="file-input" (change)="uploadAdditionalDocument($event, settledAllegationsType)"
                        #settledAllegationsUpload />
                      <div fxLayout="row" fxLayoutGap="8px">
                        <div class="file-upload">
                          <button type="button" [disabled]="licenseLocked"
                            mat-raised-button color="primary" class="upload-btn" (click)="settledAllegationsUpload.click()">
                            Browse
                            <mat-icon>attach_file</mat-icon>
                          </button>
                        </div>
                      </div>
                    </div>

                    <span class="text-wrap" [ngStyle]="{'color': applicantForm.get('allegedAbuse').value == null ? 'red' : ''}">Has anyone ever alleged that you abused a child or an adult? If you answered yes, please upload documentation to explain the events and provide all relevant documentation.</span>
                    <mat-radio-group aria-label="Select an option" formControlName="allegedAbuse" fxLayoutGap="8px">
                      <mat-radio-button [value]="true">Yes</mat-radio-button>
                      <mat-radio-button [value]="false">No</mat-radio-button>
                    </mat-radio-group>
                    <mat-error *ngIf="validateAllegedAbuse()">This field is required</mat-error>

                    <div *ngIf="applicantForm.get('allegedAbuse').value">
                      <div fxLayout="row">
                        <span class="text-wrap" [ngStyle]="{'color': applicantForm.get('substantiatedAllegation').value == null ? 'red' : ''}">Was the allegation substantiated? If you answered yes, please upload documentation to explain the events and provide all relevant documentation.</span>
                      </div>
                      <div fxLayout="row">
                        <mat-radio-group aria-label="Select an option" formControlName="substantiatedAllegation" fxLayoutGap="8px">
                          <mat-radio-button [value]="true">Yes</mat-radio-button>
                          <mat-radio-button [value]="false">No</mat-radio-button>
                        </mat-radio-group>
                      </div>
                    </div>
                    <mat-error *ngIf="validateSubstantiatedAllegation()">This field is required</mat-error>
                    <mat-error *ngIf="!validAllegedAbuseDocument()">You must upload a supporting document</mat-error>

                    <div *ngIf="applicantForm.get('allegedAbuse').value">
                      <input type="file" class="file-input" (change)="uploadAdditionalDocument($event, allegedAbuseType)"
                        #allegedAbuseUpload />
                      <div fxLayout="row" fxLayoutGap="8px">
                        <div class="file-upload">
                          <button type="button" [disabled]="licenseLocked"
                            mat-raised-button color="primary" class="upload-btn" (click)="allegedAbuseUpload.click()">
                            Browse
                            <mat-icon>attach_file</mat-icon>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>

            <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
              <mat-card>
                <mat-card-title>
                  Contact Information
                </mat-card-title>
                <mat-card-content>
                  <div fxLayout="column" fxLayoutGap="8px">
                    <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
                      <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                        <span class="f-2-20"><strong>Primary Phone</strong></span>
                        <mat-form-field>
                          <input matInput placeholder="Phone" formControlName="phone" mask="(000) 000-0000 000000" [validation]="false">
                          <mat-error *ngIf="applicantForm.get('phone').hasError('required')">
                            A primary phone number is required
                          </mat-error>
                          <mat-error *ngIf="applicantForm.get('phone').hasError('pattern')">
                            Phone should match this format: 999-999-9999 x1234
                          </mat-error>
                        </mat-form-field>
                      </div>
                      <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                        <span class="f-2-20"><strong>Alternate Phone</strong></span>
                        <mat-form-field>
                          <input matInput placeholder="Alt Phone" formControlName="alternatePhone" mask="(000) 000-0000 000000" [validation]="false">
                          <mat-error *ngIf="applicantForm.get('alternatePhone').hasError('pattern')">
                            Phone should match this format: 999-999-9999 x1234
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>

                    <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
                      <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                        <span class="f-2-20"><strong>Primary Email Address</strong></span>
                        <mat-form-field>
                          <input matInput placeholder="Email" formControlName="email">
                          <mat-error *ngIf="applicantForm.get('email').hasError('required')">
                            Please enter an email adress
                          </mat-error>
                          <mat-error *ngIf="applicantForm.get('email').hasError('email')">
                            This doesn't look like a valid email address
                          </mat-error>
                        </mat-form-field>
                      </div>
                      <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                        <span class="f-2-20"><strong>Confirm Email Address</strong></span>
                        <mat-form-field>
                          <input matInput placeholder="Confirm Email" formControlName="confirmEmail">
                          <mat-error *ngIf="validateEmail()">
                            Email does not match
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>

                    <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
                      <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                        <span class="f-2-18"><strong>Preferred Language</strong></span>
                        <mat-form-field>
                          <input matInput placeholder="Language" formControlName="language">
                        </mat-form-field>
                      </div>
                    </div>

                    <div fxLayout="column">
                      <span class="f-2-20"><strong>Other OPS Licenses or Permits</strong></span>
                      <mat-checkbox formControlName="previousLicense" (change)="validatePreviousLicense()">
                        Check the box if the applicant applied for or received any other licenses or permits from Oregon Psilocybin Services
                      </mat-checkbox>
                      <div fxLayout="column" class="m-t-8" *ngIf="this.applicantForm.get('previousLicense').value">
                        <span>Please provide the license id(s) for each Oregon Psilocybin Services license.</span>
                        <mat-form-field>
                          <input matInput formControlName="affiliatedLicenses">
                          <mat-placeholder style="color:red" *ngIf="applicantForm.get('affiliatedLicenses').value == ''">Affiliated Licenses*</mat-placeholder>
                          <mat-placeholder style="color:grey" *ngIf="applicantForm.get('affiliatedLicenses').value != ''">Affiliated Licenses</mat-placeholder>
                          <mat-error *ngIf="validatePreviousLicense()">
                            Please provide all license numbers
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>

              <mat-card *ngIf="showRequiredApplicantDataAttestation(facilitatorLicense.applicationDate)">
                <mat-card-title>
                  Required 303 Applicant and Licensee REAL & SOGI Data
                </mat-card-title>
                <mat-card-content>
                  <div fxLayout="column" fxLayoutGap="8px">
                    <span>Senate Bill 303 (SB 303) was adopted by the Oregon Legislature in 2023 and is now codified in
                      <a  href="https://www.oregonlegislature.gov/bills_laws/ors/ors475A.html#:~:text=475A.372%20Requirement%20of%20psilocybin%20service%20center%20operator%20to%20collect%2c%20maintain%2c%20aggregate%20and%20submit%20to%20Oregon%20Health%20Authority%20specified%20information"
                        target="_blank" rel="noreferrer noopener" style="word-break: break-all; white-space: normal;">ORS 475A.372</a>
                      and
                      <a  href="https://www.oregonlegislature.gov/bills_laws/ors/ors475A.html#:~:text=475A.374%20Oregon%20Health%20Authority%20collection%2c%20publication%20of%20certain%20data%3b%20rules."
                        target="_blank" rel="noreferrer noopener" style="word-break: break-all; white-space: normal;">ORS 475A.374</a>
                      . SB 303 requires Oregon Psilocybin Services (OPS) to collect and compile information about OPS license applicants and licensees. The 303 Applicant Data is kept separate from your application materials and will not be used to make any decisions related to your license application.
                    </span>
                    <span>All applicants are required to click the link below and complete the questions as part of the OPS application process: </span>
                    <a routerLink="/applicant-data"  target="_blank">https://psilocybin.oregon.gov/applicant-data</a>

                  </div>
                </mat-card-content>
              </mat-card>

              <mat-card>
                <mat-card-title>Address</mat-card-title>
                <mat-card-content>
                  <div fxLayout="column">
                    <span><strong>Physical Address</strong></span>
                    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
                      <mat-form-field fxFlex="30" fxFlex.lt-md="100">
                        <input matInput placeholder="Street Apt/Unit/Suite" formControlName="physicalStreet">
                        <mat-error *ngIf="applicantForm.get('physicalStreet').invalid">
                          Please provide a street
                        </mat-error>
                      </mat-form-field>

                      <mat-form-field fxFlex="30" fxFlex.lt-md="100">
                        <input matInput placeholder="City" formControlName="physicalCity">
                        <mat-error *ngIf="applicantForm.get('physicalCity').invalid">
                          Please provide a city
                        </mat-error>
                      </mat-form-field>

                      <mat-form-field fxFlex="40" fxFlex.lt-md="100">
                        <mat-label>State/US territory/freely associated state</mat-label>
                        <mat-select formControlName="physicalState">
                          <mat-option *ngFor="let state of sharedService.states" value={{state}}>{{state}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="applicantForm.get('physicalState').invalid">
                          Please provide a state
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
                      <mat-form-field fxFlex="40" fxFlex.lt-md="100">
                        <input matInput placeholder="Zip" formControlName="physicalZip">
                        <mat-error *ngIf="applicantForm.get('physicalZip').hasError('required')">
                          Please provide a zipcode
                        </mat-error>
                        <mat-error *ngIf="applicantForm.get('physicalZip').hasError('pattern')">
                          Zipcode should match this format: 99999 or 99999-1234
                        </mat-error>
                      </mat-form-field>

                      <mat-form-field fxFlex="40" fxFlex.lt-md="100"
                      *ngIf="applicantForm.get('physicalState').value === 'OR'">
                        <mat-label>County</mat-label>
                        <mat-select formControlName="physicalCounty">
                          <mat-option>-- None --</mat-option>
                          <mat-option *ngFor="let county of sharedService.counties" value={{county}}>{{county}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>

                  <div fxLayout="column" class="m-t-8">
                    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="24px">
                      <span><strong>Mailing Address</strong></span>
                      <mat-checkbox formControlName="mailingIsPhysical" (change)="mailingIsSame()">
                        Same as physical
                      </mat-checkbox>
                    </div>
                    <div fxLayout="column" [@slideInOut] *ngIf="!applicantForm.get('mailingIsPhysical').value">
                      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
                        <mat-form-field fxFlex="30" fxFlex.lt-md="100">
                          <input matInput placeholder="Street Apt/Unit/Suite" formControlName="mailingStreet"
                            (blur)="validateMailingStreet()">
                          <mat-error *ngIf="validateMailingStreet()">
                            Please provide a street
                          </mat-error>
                        </mat-form-field>



                        <mat-form-field fxFlex="30" fxFlex.lt-md="100">
                          <input matInput placeholder="City" formControlName="mailingCity" (blur)="validateMailingCity()">
                          <mat-error *ngIf="validateMailingCity()">
                            Please provide a city
                          </mat-error>
                        </mat-form-field>

                        <mat-form-field fxFlex="40" fxFlex.lt-md="100">
                          <mat-label>State/US territory/freely associated state</mat-label>
                          <mat-select formControlName="mailingState" (blur)="validateMailingState()">
                            <mat-option *ngFor="let state of sharedService.states" value={{state}}>{{state}}</mat-option>
                          </mat-select>
                          <mat-error *ngIf="validateMailingState()">
                            Please provide a state
                          </mat-error>
                        </mat-form-field>
                      </div>

                      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
                        <mat-form-field fxFlex="40" fxFlex.lt-md="100">
                          <input matInput placeholder="Zip" formControlName="mailingZip" (blur)="validateMailingZip()">
                          <mat-error *ngIf="validateMailingZip()">
                            Please provide a zipcode
                          </mat-error>
                          <mat-error *ngIf="applicantForm.get('mailingZip').hasError('pattern')">
                            Zipcode should match this format: 99999 or 99999-1234
                          </mat-error>
                        </mat-form-field>

                        <mat-form-field fxFlex="40" fxFlex.lt-md="100"
                          *ngIf="applicantForm.get('mailingState').value === 'OR'">
                          <mat-label>County</mat-label>
                          <mat-select formControlName="mailingCounty">
                            <mat-option>-- None --</mat-option>
                            <mat-option *ngFor="let county of sharedService.counties" value={{county}}>{{county}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>

                  <app-residential-history [applicantId]="facilitatorLicense.applicants[0]?.id"
                                           [residentialHistory]="facilitatorLicense.applicants[0]?.residentialHistory"
                                           [licenseLocked]="licenseLocked">
                  </app-residential-history>
                </mat-card-content>
              </mat-card>
            </div>
          </div>

          <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
            <mat-card fxFlex="50" fxFlex.lt-lg="100">
              <mat-card-title>
                <span *ngIf="applicantDocumentsValid && showOregonResidency(facilitatorLicense.applicationDate)">
                  Identity and Residency Verification
                </span>
                <span *ngIf="!applicantDocumentsValid && showOregonResidency(facilitatorLicense.applicationDate)" class="text-red-400">
                  Identity and Residency Verification Incomplete
                </span>
                <span *ngIf="applicantDocumentsValid && !showOregonResidency(facilitatorLicense.applicationDate)">
                  Identity Verification
                </span>
                <span *ngIf="!applicantDocumentsValid && !showOregonResidency(facilitatorLicense.applicationDate)" class="text-red-400">
                  Identity Verification Incomplete
                </span>
              </mat-card-title>
              <mat-card-content>
                <div fxlayout="row" *ngIf="showOregonResidency(facilitatorLicense.applicationDate)">
                <mat-checkbox formControlName="oregonResidency">
                  <span class="text-wrap">I certify that I am a current Oregon resident and have been a resident of Oregon for two or more years</span>
                  <mat-error *ngIf="applicantForm.get('oregonResidency').hasError('required') && applicantForm.get('oregonResidency').touched">
                    You must attest to being an Oregon resident
                  </mat-error>
                </mat-checkbox>
              </div>
              <div fxlayout="row">
                <mat-checkbox formControlName="highSchoolDiploma">
                  <span class="text-wrap">I certify I have a high school diploma or equivalent education as required by ORS 475A.325 (2)(d)</span>
                  <mat-error *ngIf="applicantForm.get('highSchoolDiploma').hasError('required') && applicantForm.get('highSchoolDiploma').touched">
                    You must attest to having a high school diploma or equivalent education
                  </mat-error>
                </mat-checkbox>
              </div>
                <div fxLayout="column" fxLayoutGap.lt-lg="8px" style="padding-top: 2em">
                  <span>You must upload a copy of the front and back of a valid, unexpired government-issued photo identification. Accepted identification includes:</span>
                  <span style="padding-left: 1em">
                    <strong>• </strong>Passport;
                  </span>
                  <span style="padding-left: 1em">
                    <strong>• </strong>Driver license, whether issued by the State of Oregon or by another state of the United States;
                  </span>
                  <span style="padding-left: 1em">
                    <strong>• </strong>Identification card issued under ORS 807.400;
                  </span>
                  <span style="padding-left: 1em">
                    <strong>• </strong>United States military identification card;
                  </span>
                  <span style="padding-left: 1em">
                    <strong>• </strong>An identification card issued by a federally recognized Indian tribe with photo, name and date of birth; or
                  </span>
                  <span style="padding-left: 1em">
                    <strong>• </strong>Any other identification card issued by a state or territory that bears a picture of the person,
                                       the name of the person, the person's date of birth and a physical description of the person
                  </span>
                  <div fxLayout="column" fxLayoutGap="8px" class="m-t-8">
                    <span class="f-s-14" [ngClass]="applicantDocumentsValid ? '' : 'text-red-400'">Upload Your Identification</span>
                    <input type="file" accept=".doc,.docx,.rtf,.xls,.xlsx,.pdf,.txt,.png,.jpg,.jpeg,.bmp,.mp4" class="file-input" (change)="uploadApplicantDocument($event)"
                      #applicantFileUpload />
                    <div fxLayout="row" fxLayoutGap="8px">
                      <div class="file-upload">
                        <button type="button" [disabled]="licenseLocked"
                          mat-raised-button color="primary" class="upload-btn" (click)="applicantFileUpload.click()">
                          Browse
                          <mat-icon>attach_file</mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
            <mat-card fxFlex="50" fxFlex.lt-lg="100">
              <mat-card-title>
                <span>Current Documents</span>
              </mat-card-title>
              <mat-card-content>
                <div fxLayout="column">
                  <div fxLayout="row" *ngIf="!sharedService.mobile">
                    <span class="f-s-16" fxFlex="30"><strong>File</strong></span>
                    <span class="f-s-16"><strong>Requirement</strong></span>
                  </div>
                  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center" fxLayoutAlign.lt-md=""
                    *ngFor="let document of facilitatorLicense.applicants[0]?.applicantDocuments">
                    <span fxFlex="30" fxFlex.lt-md="" class="text-indigo-A700 text-wrap" style="cursor: pointer" (click)="downloadDocument(document.id, document.name, true)" title="{{document.name}}">{{document.name.length > 15 ? document.name.substring(0,15) + "..." : document.name}}</span>
                    <div fxFlex="70" fxFlex.lt-md="" fxLayout="row" fxLayoutAlign="start center">
                      <div fxFlex="40" fxFlex.lt-md="" fxLayout="column">
                        <span *ngFor="let type of document.types">
                          {{documentTypeReference[type]}}
                        </span>
                      </div>
                      <button *ngIf="!licenseLocked" type="button" mat-icon-button class="m-8" (click)="deleteFile(document.id, document.name, true)"
                        color="warn" aria-label="Delete">
                        <mat-icon>delete</mat-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </form>

      <div class="m-t-16" fxLayout="row" fxLayoutGap="8px" fxFlex="50" fxFlex.lt-md="100">
        <button mat-raised-button matStepperPrevious>{{!licenseLocked? "Save and " : ""}}Back</button>
        <button mat-raised-button matStepperNext>{{!licenseLocked? "Save and " : ""}}Continue</button>
      </div>
    </mat-step>

    <!--Documents-->
    <mat-step [stepControl]="documentsForm" *ngIf="facilitatorLicenses.length > 0">
      <form [formGroup]="documentsForm">
        <ng-template matStepLabel>

          <span *ngIf="((documentsForm.valid && licenseDocumentsValid) || documentsForm.pristine || licenseLocked)"
            matTooltip="Documents" matTooltipPosition="below" [matTooltipShowDelay]="500">Documents</span>

          <span class="text-red-400" *ngIf="((!documentsForm.valid && !documentsForm.pristine) || !licenseDocumentsValid) && !licenseLocked"
            matTooltip="Documents Incomplete" matTooltipPosition="below" [matTooltipShowDelay]="500">Documents Incomplete</span>

          </ng-template>
        <div fxLayout="column" fxLayoutGap="8px">
          <div fxLayout="row" fxLayout.lt-lg="column">
            <mat-card fxFlex="100">
              <mat-card-title>
                The following documentation is required to apply for a Psilocybin Facilitator License:
              </mat-card-title>
              <mat-card-content>
                <div fxLayout="column" fxLayoutGap="8px" class="p-b-8">
                  <span class="p-l-24"><strong>• </strong>A copy of your certificate of completion from a psilocybin facilitator training program with an OPS approved curriculum</span>
                  <div fxLayout="column" style="padding-left: 2em">
                    <span class="p-l-24"><strong>• </strong>Please note: the facilitator training program must have an OPS approved curriculum </span>
                  </div>
                  <span class="p-l-24"><strong>• </strong>Social Equity Plan </span>
                  <span class="p-l-24"><strong>• </strong>If you are requesting a reduced licensing fee, please upload one of the following required supporting documents:</span>

                  <div fxLayout="column" style="padding-left: 2em">
                    <span class="p-l-24"><strong>• </strong>A copy of a current monthly Supplemental Security Income benefit statement showing dates of coverage</span>
                  </div>
                  <div fxLayout="column" style="padding-left: 2em">
                    <span class="p-l-24"><strong>• </strong>A copy of the applicant's current eligibility statement or card if enrolled in Oregon Health Plan</span>
                  </div>
                  <div fxLayout="column" style="padding-left: 2em">
                    <span class="p-l-24"><strong>• </strong>A copy of current proof of food stamp benefits from the Oregon Supplemental Nutrition Assistance Program </span>
                  </div>
                  <div fxLayout="column" style="padding-left: 2em">
                    <span class="p-l-24"><strong>• </strong>A copy of proof of having served in the Armed Forces, such as but not limited to, submitting a Veteran’s Administration form DD-214</span>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>

          <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="16px" fxFlex="100">
            <mat-card fxFlex="50" fxFlex.lt-lg="100">
              <mat-card-title>
                Upload Supporting Documents
              </mat-card-title>
              <mat-card-content>
                <div fxLayout="column" fxLayoutGap="8px">
                  <span class="f-2-14">For each document select the requirements it verifies</span>
                  <div fxLayout="column" fxLayoutGap="8px" class="p-t-8">

                    <div fxLayout="column">
                      <mat-checkbox formControlName="trainingCertificate" [labelPosition]="'before'">
                       <span [ngClass]="missingTypes[trainingCertificateType]? 'text-red-400' : ''">Training Certificate</span>
                      </mat-checkbox>

                      <mat-checkbox formControlName="socialEquityPlan" [labelPosition]="'before'">
                        <span [ngClass]="missingTypes[socialEquityPlanType]? 'text-red-400' : ''">
                          Social Equity Plan
                        </span>
                      </mat-checkbox>

                      <mat-checkbox formControlName="reducedFee" [labelPosition]="'before'">Reduced Fee</mat-checkbox>
                    </div>

                    <input type="file" accept=".doc,.docx,.rtf,.xls,.xlsx,.pdf,.txt,.png,.jpg,.jpeg,.bmp,.mp4" class="file-input" (change)="uploadLicenseDocument($event)" #fileUpload />
                    <div fxLayout="row" fxLayoutGap="8px">
                      <div class="file-upload">
                        <button [disabled]="!documentsForm.get('trainingCertificate').value &&
                                            !documentsForm.get('socialEquityPlan').value &&
                                            !documentsForm.get('reducedFee').value"
                                mat-raised-button color="primary" class="upload-btn" (click)="fileUpload.click()">
                          Browse
                          <mat-icon>attach_file</mat-icon>
                        </button>
                      </div>
                    </div>

                  </div>
                </div>
              </mat-card-content>
            </mat-card>

            <mat-card fxFlex="50" fxFlex.lt-lg="100">
              <mat-card-title>
                <span *ngIf="applicantDocumentsValid">Current Documents</span>
              </mat-card-title>
              <mat-card-content>
                <div fxLayout="column">
                  <div fxLayout="row" >
                    <span class="f-s-16" fxFlex="40"><strong>File</strong></span>
                    <span class="f-s-16"><strong>Requirement</strong></span>
                  </div>
                  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutAlign.lt-md=""
                    *ngFor="let document of this.facilitatorLicense.licenseDocuments" fxLayoutGap="8px">
                    <span fxFlex="40" class="text-indigo-A700 text-wrap" style="cursor: pointer" (click)="downloadDocument(document.id, document.name, false)" title="{{document.name}}">{{document.name.length > 15 ? document.name.substring(0,15) + "..." : document.name}}</span>
                    <div fxFlex="70"  fxLayout="row" fxLayoutAlign="start center">
                      <div fxFlex="50" fxLayout="column">
                        <span *ngFor="let type of document.types">
                          {{documentTypeReference[type]}}
                        </span>
                      </div>
                      <div fxLayout="row" *ngIf="!sharedService.mobile">

                      <button *ngIf="!licenseLocked" type="button" mat-icon-button class="m-8" (click)="deleteFile(document.id, document.name, false)"
                        color="warn" aria-label="Delete">
                        <mat-icon>delete</mat-icon>
                      </button>
                    </div>
                    <div fxLayout="column" *ngIf="sharedService.mobile">
                      <button *ngIf="!licenseLocked" type="button" mat-icon-button class="m-8" (click)="deleteFile(document.id, document.name, false)"
                        color="warn" aria-label="Delete">
                        <mat-icon>delete</mat-icon>
                      </button>
                    </div>
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>

          <div fxLayout="row">
            <mat-card fxFlex="100">
              <mat-card-title>
                Training Program Information
              </mat-card-title>
              <mat-card-content>
                <div fxLayout="column" fxLayoutGap="8px" fxFlex="50" fxFlex.lt-lg="100">
                  <span>Name of Training Program with OHA Approved Curriculum</span>
                  <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">

                    <div fxLayout="column" fxLayoutGap="8px" fxFlex="75">

                      <mat-form-field>
                        <mat-label>Approved Training Programs</mat-label>
                        <input type="text"
                               placeholder="Training Program"
                               aria-label="Training Program"
                               matInput
                               formControlName="trainingProgram"
                               [matAutocomplete]="auto">
                        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                          <mat-option *ngFor="let trainingProgram of filteredOptions | async" [value]="trainingProgram">
                            {{trainingProgram}}
                          </mat-option>
                        </mat-autocomplete>
                      </mat-form-field>
                  </div>
                  <div fxLayout="column" fxLayoutGap="8px" fxFlex="25">
                    <mat-form-field>
                      <mat-label>Date Completed</mat-label>
                      <input placeholder="mm/dd/yyyy" formControlName="trainingDate" matInput [matDatepicker]="trainingDate"
                        [max]="sharedService.today" />
                      <mat-datepicker-toggle matSuffix [for]="trainingDate"></mat-datepicker-toggle>
                      <mat-datepicker #trainingDate>
                        <mat-datepicker-actions>
                          <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
                          <button mat-button matDatepickerCancel>Cancel</button>
                        </mat-datepicker-actions>
                      </mat-datepicker>
                      <mat-error *ngIf="documentsForm.get('trainingDate').value > sharedService.today">
                        Please choose an earlier date.
                      </mat-error>
                      <mat-error *ngIf="documentsForm.get('trainingDate').hasError('required')">
                        Training Date is required
                      </mat-error>
                    </mat-form-field>
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>

        </div>
      </form>
      <div fxLayout="row" fxLayoutGap="8px" fxFlex="50" fxFlex.lt-md="100" class="p-t-16">
        <button mat-raised-button matStepperPrevious>{{!licenseLocked? "Save and " : ""}}Back</button>
        <button mat-raised-button matStepperNext>{{!licenseLocked? "Save and " : ""}}Continue</button>
      </div>
    </mat-step>

    <!--Exam-->
    <mat-step *ngIf="facilitatorLicenses.length > 0">
      <ng-template matStepLabel>
        <span class="text-red-400" *ngIf="facilitatorLicense.examAttempts.length > 0 && !facilitatorLicense.passedExam && !licenseLocked"
          matTooltip="OHA Exam Incomplete" matTooltipPosition="below" [matTooltipShowDelay]="500">OHA Exam Incomplete</span>
        <span *ngIf="facilitatorLicense.examAttempts.length === 0 || facilitatorLicense.passedExam || licenseLocked" matTooltip="OHA Exam"
          matTooltipPosition="below" [matTooltipShowDelay]="500">OHA Exam</span>
      </ng-template>

      <mat-card>
        <mat-card-title>
          Facilitator Exam
        </mat-card-title>
        <mat-card-content>
          <div *ngIf="facilitatorLicense.passedExam" fxLayout="column" fxLayoutGap="24px">
            <span *ngIf="sharedService.tablet" class="f-s-18">Exam</span>
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="24px">
              <span fxFlex="70" class="f-s-20">You have already passed the exam. Please continue through the
                application.</span>
            </div>
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="24px">
              <span fxFlex="70" class="f-s-20">You passed the exam on {{facilitatorLicense.examDate | date:
                'MM/dd/yyyy'}}</span>
            </div>
          </div>
          <div *ngIf="!facilitatorLicense.passedExam" fxLayout="column" fxLayoutGap="24px">
            <span *ngIf="sharedService.tablet" class="f-s-18">Exam</span>
            <div fxLayout="column" fxLayoutGap="8px">
              <span class="f-s-16">Every applicant for a facilitator license must take the required OPS Regulations Exam and
                receive a passing score prior to being issued a facilitator license.
              </span>
              <span class="f-s-16" style="padding-left: 2em">
                <strong>• </strong>A score of 75 percent is passing
              </span>
              <span class="f-s-16" style="padding-left: 2em">
                <strong>• </strong>An applicant who does not pass the exam may retake the exam
              </span>
              <span class="f-s-14" style="padding-left: 4em">
                <strong>• </strong>The exam may be retaken immediately
              </span>
              <span class="f-s-14" style="padding-left: 4em">
                <strong>• </strong>There is no limit on exam retakes
              </span>
              <span class="f-s-16" style="padding-left: 2em">
                Applicants may access and refer to the statute and administrative rules found in <a  href="https://www.oregonlegislature.gov/bills_laws/ors/ors475a.html" target="_blank" rel="noreferrer noopener" style="word-break: break-all;
                white-space: normal;">ORS 475A</a> and <a  href="https://secure.sos.state.or.us/oard/displayDivisionRules.action?selectedDivision=7102" target="_blank" rel="noreferrer noopener" style="word-break: break-all;
                white-space: normal;">Oregon Administrative Rules</a> when answering exam questions. It may be helpful to have these links open prior to beginning the exam.
              </span>
              <span class="f-s-16" style="padding-left: 3em">
                <strong>• Important Note: </strong>If the exam is exited before completion, the system does not currently have the ability to save the answers and we are working on it. With that, please plan for 30-60 minutes to take the exam. If you must leave the exam,
                you may retake the exam immediately. Please contact us if you need assistance or an alternative exam format.
              </span>
            </div>

        <div fxLayout="column" fxLayoutGap="8px" *ngFor="let question of examAttempt.examQuestions; let elementIndex = index">
          <div fxLayout="row" fxLayoutGap="8px">
            <span><strong>({{elementIndex + 1}})</strong></span>
            <span>{{question.text}}</span>
          </div>
          <mat-radio-group fxLayout="column" fxLayoutGap="8px" style="padding-left: 3em">
              <div fxLayout="row" fxLayoutGap="8px" *ngFor="let answer of question.examAnswers; let answerIndex = index">

                <mat-radio-button [value]="answer" (change)="selectAnswer(answer, question)">
                  <span><strong>({{getAnswerIndexLetter(answerIndex)}}) </strong></span>
                  <span class="text-wrap">{{answer.text}}</span>
                </mat-radio-button>
              </div>
          </mat-radio-group>
        </div>
      </div>
    </mat-card-content>
    </mat-card>
      <div fxLayout="row" fxLayoutGap="8px" fxFlex="50" fxFlex.lt-md="100" class="p-t-16">
        <button mat-raised-button matStepperPrevious>Back</button>
        <button mat-raised-button matStepperNext>Continue</button>
        <button mat-raised-button type="button" color="primary"
          *ngIf="!facilitatorLicense.passedExam && facilitatorLicense.id !== 0" (click)="submitExam()"
          [disabled]="!examAttempt.valid">Submit Exam</button>
      </div>
    </mat-step>

    <!--Fees-->
    <mat-step *ngIf="facilitatorLicenses.length > 0">
      <ng-template matStepLabel>
        <span matTooltip="Fee Information" matTooltipPosition="below" [matTooltipShowDelay]="500">Fee Information</span>
      </ng-template>
      <div fxLayout="column" fxLayoutGap="8px" fxFlex="50" fxFlex.lt-lg="100">
        <span *ngIf="sharedService.tablet" class="f-s-18">Fees</span>
        <app-payment-notice [canPay]="false" entityType="FacilitatorLicense" feeType="ApplicationFee" [renewal]="renewing"></app-payment-notice>
        <div fxLayout="row" fxLayoutGap="8px" fxFlex="50" fxFlex.lt-md="100">
          <button mat-raised-button matStepperPrevious>Back</button>
          <button mat-raised-button matStepperNext>Continue</button>
        </div>
      </div>
    </mat-step>

    <!--Verify and Submit-->
    <mat-step [stepControl]="licenseForm" (ngSubmit)="submitApplication()" *ngIf="facilitatorLicenses.length > 0">
      <ng-template matStepLabel>
        <span matTooltip="Review and Submit" matTooltipPosition="below" [matTooltipShowDelay]="500">Review and Submit</span>
      </ng-template>
      <div fxLayout="column" fxLayoutGap="8px">
        <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
          <mat-card fxFlex="50" fxFlex.lt-lg="100">
            <mat-card-title>
              <span *ngIf="applicantForm.valid || licenseLocked">Applicant Information</span>
              <span class="text-red-400" *ngIf="!applicantForm.valid && !licenseLocked">Applicant Information Incomplete</span>
            </mat-card-title>
            <mat-card-content>
              <div fxLayout="column" fxLayoutGap="8px" class="p-b-16">
                <div fxLayout="row" fxLayout.lt-lg="column">
                  <span fxFlex="20" fxFlex.lt-lg="100"><strong>Legal Name</strong></span>
                  <div fxLayout="row" fxFlex="80" fxFlex.lt-lg="100" fxLayoutGap="8px">
                    <span>{{facilitatorLicense.applicants[0]?.legalFirstName}}</span>
                    <span>{{facilitatorLicense.applicants[0]?.legalMiddleName}}</span>
                    <span>{{facilitatorLicense.applicants[0]?.legalLastName}}</span>
                  </div>
                </div>
                <div fxLayout="row" fxLayout.lt-lg="column">
                  <span fxFlex="20" fxFlex.lt-lg="100"><strong>Preferred Name</strong></span>
                  <div fxLayout="row" fxFlex="80" fxFlex.lt-lg="100" fxLayoutGap="8px">
                    <span>{{facilitatorLicense.applicants[0]?.preferredFirstName}}</span>
                    <span>{{facilitatorLicense.applicants[0]?.preferredMiddleName}}</span>
                    <span>{{facilitatorLicense.applicants[0]?.preferredLastName}}</span>
                  </div>
                </div>
                <div fxLayout="row" fxLayout.lt-lg="column">
                  <span fxFlex="20" fxFlex.lt-lg="100"><strong>Other Names</strong></span>
                  <div fxLayout="row" fxFlex="80" fxFlex.lt-lg="100" fxLayoutGap="8px">
                    <span>{{facilitatorLicense.applicants[0]?.alternateNames}}</span>
                  </div>
                </div>
                <div fxLayout="row" fxLayout.lt-lg="column">
                  <span fxFlex="20" fxFlex.lt-lg="100"><strong>Physical Address</strong></span>
                  <div fxLayout="row" fxFlex="80" fxFlex.lt-lg="100" fxLayoutGap="8px">
                    <span>{{facilitatorLicense.applicants[0]?.physicalStreet}}</span>
                    <span>{{facilitatorLicense.applicants[0]?.physicalCity}}</span>
                    <span>{{facilitatorLicense.applicants[0]?.physicalState}}</span>
                    <span>{{facilitatorLicense.applicants[0]?.physicalZip}}</span>
                    <span>{{facilitatorLicense.applicants[0]?.physicalCounty}}</span>
                  </div>
                </div>
                <div fxLayout="row" fxLayout.lt-lg="column">
                  <span fxFlex="20" fxFlex.lt-lg="100"><strong>Mailing Address</strong></span>
                  <div fxFlex="80" fxFlex.lt-lg="100" fxLayout="row" fxLayoutGap="8px" *ngIf="!facilitatorLicense.applicants[0]?.mailingIsPhysical">
                    <span>{{facilitatorLicense.applicants[0]?.mailingStreet}}</span>
                    <span>{{facilitatorLicense.applicants[0]?.mailingCity}}</span>
                    <span>{{facilitatorLicense.applicants[0]?.mailingState}}</span>
                    <span>{{facilitatorLicense.applicants[0]?.mailingZip}}</span>
                    <span>{{facilitatorLicense.applicants[0]?.mailingCounty}}</span>
                  </div>
                  <div fxFlex="80" fxFlex.lt-lg="100" *ngIf="facilitatorLicense.applicants[0]?.mailingIsPhysical" fxLayout="row" fxLayoutGap="8px">
                    <span>Same as Physical</span>
                  </div>
                </div>
                <div fxLayout="row" fxLayout.lt-lg="column">
                  <span fxFlex="20" fxFlex.lt-lg="100"><strong>Primary Phone</strong></span>
                  <div fxLayout="row" fxFlex.lt-lg="100" fxFlex="80" fxLayoutGap="8px">
                    <span>{{facilitatorLicense.applicants[0]?.phone | mask: '(000) 000-0000 000000'}}</span>
                  </div>
                </div>
                <div fxLayout="row" fxLayout.lt-lg="column">
                  <span fxFlex="20" fxFlex.lt-lg="100"><strong>Alternate Phone</strong></span>
                  <div fxLayout="row" fxFlex="80" fxFlex.lt-lg="100" fxLayoutGap="8px">
                    <span>{{facilitatorLicense.applicants[0]?.alternatePhone | mask: '(000) 000-0000 000000'}}</span>
                  </div>
                </div>
                <div fxLayout="row" fxLayout.lt-lg="column">
                  <span fxFlex="20" fxFlex.lt-lg="100"><strong>Email</strong></span>
                  <div fxLayout="row" fxFlex="80" fxFlex.lt-lg="100" fxLayoutGap="8px">
                    <span>{{facilitatorLicense.applicants[0]?.email}}</span>
                  </div>
                </div>
                <div fxLayout="row" fxLayout.lt-lg="column">
                  <span fxFlex="20" fxFlex.lt-lg="100"><strong>Language</strong></span>
                  <div fxLayout="row" fxFlex="80" fxFlex.lt-lg="100" fxLayoutGap="8px">
                    <span>{{facilitatorLicense.applicants[0]?.language}}</span>
                  </div>
                </div>
                <div fxLayout="row" fxLayout.lt-lg="column">
                  <span fxFlex="20" fxFlex.lt-lg="100"><strong>Training Program</strong></span>
                  <span>{{facilitatorLicense.trainingProgram}}</span>
                </div>

                <div fxLayout="row" fxLayout.lt-lg="column">
                  <span fxFlex="20" fxFlex.lt-lg="100"><strong>Completed On:</strong></span>
                  <span>{{facilitatorLicense.trainingDate | date}}</span>
                </div>

                <div fxLayout="column" *ngIf="facilitatorLicense.applicants[0]?.previousLicense">
                  <mat-checkbox [checked]="true" disabled>
                    Has this individual applied for or received any other licenses from Oregon Psilocybin Services?
                  </mat-checkbox>
                  <div fxLayout="column">
                    <span>Previous Affiliated License</span>
                    <span>{{facilitatorLicense.applicants[0]?.affiliatedLicenses}}</span>
                  </div>
                </div>

              </div>
            </mat-card-content>
          </mat-card>

          <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px" fxFlex="50" fxFlex.lt-lg="100">
            <mat-card fxFlex="60">
              <mat-card-title>
                <div fxLayout="column">
                  <span>Current Documents</span>
                  <span class="text-red-400" *ngIf="!applicantDocumentsValid || !licenseDocumentsValid || !additionalDocumentsValid">Not All Requirements are Met</span>
                </div>
              </mat-card-title>
              <mat-card-content>
                <div fxLayout="column">
                  <span class="text-red-400" *ngIf="missingTypes[trainingCertificateType]">{{documentTypeReference[trainingCertificateType]}} required</span>
                  <span class="text-red-400" *ngIf="missingTypes[socialEquityPlanType]">{{documentTypeReference[socialEquityPlanType]}} required</span>
                  <span class="text-red-400" *ngIf="missingTypes[personalIdentificationType]">{{documentTypeReference[personalIdentificationType]}} required</span>
                </div>
                <div fxLayout="column" fxLayoutGap="8px">
                  <div fxLayout="row">
                    <span class="f-s-18" fxFlex="60"><strong>File</strong></span>
                    <span class="f-s-18"><strong>Requirement</strong></span>
                  </div>
                  <div fxLayout="row" *ngFor="let document of facilitatorLicense.applicants[0]?.applicantDocuments">
                    <span fxFlex="60" class="text-indigo-A700 text-wrap" style="cursor: pointer" (click)="downloadDocument(document.id, document.name, true)" title="{{document.name}}">{{document.name.length > 15 ? document.name.substring(0,15) + "..." : document.name}}</span>
                    <div fxLayout="column">
                      <div fxLayout="row" *ngFor="let type of document.types">
                        {{documentTypeReference[type]}}
                      </div>
                    </div>
                  </div>
                  <div fxLayout="row" *ngFor="let document of facilitatorLicense?.licenseDocuments">
                    <span fxFlex="60" class="text-indigo-A700 text-wrap" style="cursor: pointer" (click)="downloadDocument(document.id, document.name, false)" title="{{document.name}}">{{document.name.length > 15 ? document.name.substring(0,15) + "..." : document.name}}</span>
                    <div fxLayout="column">
                      <div fxLayout="row" *ngFor="let type of document.types">
                        {{documentTypeReference[type]}}
                      </div>
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
            <mat-card fxFlex="40">
              <mat-card-title>
                <span *ngIf="facilitatorLicense.passedExam">
                  Facilitator Exam
                </span>
                <span class="text-red-400" *ngIf="!facilitatorLicense.passedExam">
                  You Must Pass the Exam</span>
              </mat-card-title>
              <mat-card-content>
                <div fxLayout="column" fxLayoutGap="8px">
                  <div fxLayout="row" fxLayoutGap="8px">
                    <span class="f-s-18" fxFlex="30"><strong>Score</strong></span>
                    <span class="f-s-18" fxFlex="30"><strong>Result</strong></span>
                    <span class="f-s-18" fxFlex="40"><strong>Date Taken</strong></span>
                  </div>
                  <div fxLayout="row" fxLayoutGap="8px" *ngFor="let attempt of facilitatorLicense.examAttempts">
                    <span fxFlex="30">{{attempt.score}}%</span>
                    <span *ngIf="attempt.passed" class="f-s-16" fxFlex="30"><strong>Passed</strong></span>
                    <span *ngIf="!attempt.passed" fxFlex="30">Failed</span>
                    <span fxFlex="40">{{attempt.dateTaken | date}}</span>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>

        </div>
        <div fxLayout="row" fxLayoutGap="8px">
          <mat-card fxFlex="50" fxFlex.lt-lg="100">
            <mat-card-title>
              Sign and Submit
            </mat-card-title>
            <mat-card-content>
              <form [formGroup]="licenseForm">
                <div fxLayout="column" fxLayoutGap="8px">
                  <span class="p-l-24">
                    <strong>• </strong>
                    I certify the information provided in this application is true and complete to the best of my knowledge.
                    I understand falsifying an application, supplying misleading information, or withholding information is grounds for denial of my application or revocation of my license.
                  </span>
                  <span class="p-l-24">
                    <strong>• </strong>
                    I understand I am responsible for making my own determination regarding the legal risks associated with a Schedule 1 substance
                    under the Federal Controlled Substances Act.
                  </span>
                  <span class="p-l-24">
                    <strong>• </strong>
                    I understand the information provided in application materials, including applicant name, is subject to disclosure
                    under Oregon's public records laws and that certain information may be posted on OHA's website for purposes of license verification.
                  </span>
                  <span class="p-l-24">
                    <strong>• </strong>
                    I understand the following information in the application will be used by Oregon Psilocybin Services to start the background check process:
                    legal name; date of birth; physical address; phone number; email address; proof of identity; prior addresses if lived out of state during the
                    past five years; social security number (if consented); and prior names and aliases.
                  </span>
                  <span class="p-l-24">
                    <strong>• </strong>I understand I must complete and pass a background check as a condition of licensure.
                  </span>
                  <span class="p-l-24">
                    <strong>• </strong>
                    I understand I must notify Oregon Psilocybin Services in writing within 10 business days of any conviction for any misdemeanor or felony committed
                    and I will be required to undergo another criminal background check.
                  </span>
                  <span class="p-l-24">
                    <strong>• </strong>I understand I must notify Oregon Psilocybin Services in writing within 10 business days of a change in contact information.
                  </span>
                  <span class="p-l-24">
                    <strong>• </strong>I understand I am also required to obtain a worker permit as described in OAR 333-333-4070.
                  </span>
                  <span class="p-l-24">
                    <strong>• </strong>
                    I have read, understand, and agree to adhere to the Oregon Psilocybin Services Revised Statutes (ORS) Chapter 475A,
                    Oregon Administrative Rules (OAR) Chapter 333, Division 333, as well as any other applicable statutes or laws.
                  </span>
                  <span class="p-l-24">
                    <strong>• </strong>
                    I understand that failure to adhere to these statutes and rules may result in a denial of my application or disciplinary action if
                    I am licensed by Oregon Psilocybin Services.
                  </span>
                  <span class="p-l-24">
                    <strong>• </strong>
                    I understand the psilocybin facilitator license has a term of one year from the effective date of licensure.
                    If I wish to renew my license after that term, I understand I must complete a renewal application.
                  </span>
                  <span class="p-l-24">
                    <strong>• </strong>
                    I understand a license will not be issued until the license fee payment is paid and cleared.
                    A licensee may not operate until the effective date of licensure.
                  </span>
                  <span class="p-l-24" *ngIf="showRequiredApplicantDataAttestation(facilitatorLicense.applicationDate)">
                    <strong>• </strong>Applicant(s) understand and will comply with the requirement to complete and submit the Required 303 Applicant and Licensee REAL & SOGI
                    Data form in accordance with ORS 475A.374.
                  </span>
                  <span class="p-l-24">
                    <strong>• </strong>I understand the psilocybin facilitator application and annual license fees are non-refundable.
                  </span>
                  <mat-checkbox formControlName="attestation">
                    <span style="white-space: normal;">I have read and I understand the above statements</span>
                  </mat-checkbox>

                      <mat-radio-group aria-label="Select an option" formControlName="directoryConsent" fxLayoutGap="16px" style="padding-top: 1em">
                        <div fxLayout="column" fxLayoutGap="16px">
                          <div fxLayout="row">
                            <mat-radio-button [value]="true">
                              <span class="text-wrap">
                                I consent to having my license name, license type, and the contact information selected below listed in the voluntary OPS Licensee Directory.
                              </span>
                            </mat-radio-button>
                          </div>
                            <span>Please select which contact information you would like to include in the directory:</span>

                          <div fxLayout="column" style="padding-left: 1em" *ngIf="licenseForm.get('directoryConsent').value">
                            <div fxLayou="row" fxLayoutGap="5px">
                              <mat-checkbox formControlName="directoryPreferredName" *ngIf="applicantForm.get('preferredFirstName').value != '' || applicantForm.get('preferredLastName').value != ''">
                                <span style="white-space: normal;">Display my preferred name in the License Directory instead of my legal name.</span>
                              </mat-checkbox>
                            </div>

                            <div fxLayou="row" fxLayoutGap="5px">
                              <mat-checkbox formControlName="directoryConsentWebsite">
                                <span style="white-space: normal;">Website:</span>
                              </mat-checkbox>

                              <mat-form-field *ngIf="licenseForm.get('directoryConsentWebsite').value">
                                <mat-label>
                                  Website
                                </mat-label>
                                <input matInput formControlName="directoryWebsite">
                                <mat-error>
                                  <span *ngIf="licenseForm.get('directoryWebsite').hasError('required')">Directory website URL is required</span>
                                  <span *ngIf="licenseForm.get('directoryWebsite').hasError('pattern')">Please enter a valid URL (http:// or https://)</span>
                                </mat-error>
                              </mat-form-field>
                            </div>

                            <div fxLayou="row" fxLayoutGap="5px">
                              <mat-checkbox formControlName="directoryConsentEmail">
                                <span style="white-space: normal;">Email:</span>
                              </mat-checkbox>

                              <mat-form-field *ngIf="licenseForm.get('directoryConsentEmail').value">
                                <mat-label>
                                  Email
                                </mat-label>
                                <input matInput formControlName="directoryEmail">
                                <mat-error *ngIf="validateDirectoryConsentEmail()">
                                  This field is required
                                </mat-error>
                              </mat-form-field>
                            </div>

                            <div fxLayou="row" fxLayoutGap="5px">
                              <mat-checkbox formControlName="directoryConsentPhone">
                                <span style="white-space: normal;">Phone Number:</span>
                              </mat-checkbox>

                              <mat-form-field *ngIf="licenseForm.get('directoryConsentPhone').value">
                                <mat-label>
                                  Phone number
                                </mat-label>
                                <input matInput formControlName="directoryPhone" mask="(000) 000-0000 000000">
                                <mat-error *ngIf="validateDirectoryConsentPhone()">
                                  This field is required
                                </mat-error>
                              </mat-form-field>
                            </div>
                          </div>

                          <div fxLayout="row">
                            <mat-radio-button [value]="false">
                              <span class="text-wrap">
                                I <strong>do not</strong> consent to having my license name, license type, and contact information listed in the voluntary OPS Licensee Directory.
                              </span>
                            </mat-radio-button>
                          </div>
                        </div>
                      </mat-radio-group>
                      <mat-error *ngIf="licenseForm.get('directoryConsent').invalid">You must select one statement regarding consent</mat-error>
                      <span class="text-wrap"><em>Please note, if you do not consent, you may still be licensed with Oregon Psilocybin Services.</em></span>

                  <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px" fxLayoutAlign="start center" fxLayoutAlign.lt-lg="">
                    <mat-form-field fxFlex="50" fxFlex.lt-md="100">
                      <mat-label>
                        Signature
                      </mat-label>
                      <input matInput placeholder="Signature" formControlName="electronicSignature">
                      <mat-error *ngIf="licenseForm.get('electronicSignature').invalid">
                        Signature is required
                      </mat-error>
                    </mat-form-field>
                    <button [disabled]="validateDirectoryConsentWebsite() || !applicationValid || !licenseForm.valid || licenseLocked" type="submit" mat-raised-button
                      color="primary" (click)="submitApplication()">Submit</button>
                  </div>
                </div>
              </form>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
      <div fxLayout="row" fxLayoutGap="8px" fxFlex="50" fxFlex.lt-md="100" class="p-t-24">
        <button mat-raised-button matStepperPrevious>Back</button>
      </div>
    </mat-step>

  </mat-stepper>
</div>

<div *ngIf="!viewForm && !showSubmitted && !renewing">
  <div fxLayout="column" fxLayoutGap="8px">
    <div>
      <button *ngIf="showStartButton && canApplyAgain" mat-flat-button type="button" color="primary" (click)="startApplication()">New Application</button>
    </div>
    <div *ngFor="let license of facilitatorLicenses">
      <mat-card [style.background]="cardColor" fxFlex="40" fxFlex.lt-lg="80">
        <mat-card-content>
          <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="24px" style="font-size: large">
            <div fxLayout="column" fxLayoutGap="8px" fxLayoutAlign="center" fxFlex>
              <div fxLayout="row" class="m-b-0">
                <span fxFlex="40" fxFlex.lt-lg="40"><strong>Facilitator</strong></span>
                <div fxLayout="row" fxLayoutGap="4px">
                  <span class="f-s-16">{{license.applicants[0].legalFirstName}}</span>
                  <span class="f-s-16">{{license.applicants[0].legalMiddleName}}</span>
                  <span class="f-s-16">{{license.applicants[0].legalLastName}}</span>
                </div>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" class="m-b-0">
                <span fxFlex="40" fxFlex.lt-lg="40"><strong>Facilitator Id</strong></span>
                <span class="f-s-16" id="programId">{{license.licenseId}}</span>
                <button mat-icon-button (click)="sharedService.copyToClipboard(license.licenseId)" color="primary"
                        matTooltip="Copy to Clipboard" matTooltipShowDelay=800 aria-label="Copy to Clipboard">
                  <mat-icon>content_copy</mat-icon>
                </button>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center">
                <span fxFlex="40" fxFlex.lt-lg="40"><strong>Status</strong></span>
                <div fxLayout="column">
                  <span class="f-s-20 f-w-700" [ngClass]="license.status === sharedService.approved? 'text-light-green-600':
                                  (license.status === sharedService.submitted ||
                                   license.status === sharedService.pending ||
                                   license.status === sharedService.awaitingPayment ||
                                     license.status === sharedService.underReview)? 'text-orange-900' : 'text-red-900'" *ngIf="license.status != sharedService.submittedForApproval && license.status != sharedService.renewalSubmittedForApproval">
                    {{license.status}}
                </span>
                <span class="f-s-20 f-w-700" [ngClass]="'text-orange-900'" *ngIf="license.status == sharedService.submittedForApproval || license.status == sharedService.renewalSubmittedForApproval">{{sharedService.underReview}}</span>
                </div>
              </div>
              <div fxLayout="row" *ngIf="license.status === sharedService.submitted">
                <span fxFlex="40" fxFlex.lt-lg="40"><strong>Date Submitted</strong></span>
                <span class="f-s-18">{{license.applicationDate | date}}</span>
              </div>
              <div fxLayout="row" *ngIf="license.status === sharedService.approved">
                <span fxFlex="40" fxFlex.lt-lg="40"><strong>Date Approved</strong></span>
                <span class="f-s-18">{{license.approvedDate | date}}</span>
              </div>
              <div fxLayout="row" *ngIf="license.status === sharedService.approved || license.status === sharedService.expired">
                <span fxFlex="40" fxFlex.lt-lg="40"><strong>Expiration Date</strong></span>
                <span class="f-s-18">{{license.expirationDate | date}}</span>
              </div>
              <div fxLayout="row" *ngIf="license.status === sharedService.approved && license.isRenewal">
                <span fxFlex="40" fxFlex.lt-lg="40"><strong>Date Renewal Approved</strong></span>
                <span class="f-s-18">{{license.renewedDate | date}}</span>
              </div>

            </div>
            <div fxLayout="column" fxLayout.lt-lg="row" fxLayoutAlign="start start" fxLayoutGap="8px">
              <button *ngIf="license.status !== sharedService.pendingRenewal && license.status !== sharedService.renewalSubmitted && !license.isRenewal"
                      mat-flat-button type="button" color="primary" (click)="viewApplication(license)">
                      <div fxLayout="row" fxLayoutGap="8px" *ngIf="showEditLoading">
                        <span>Loading</span>
                        <mat-spinner color="accent" [diameter]="25" [strokeWidth]="5" aria-label="Loading"></mat-spinner>
                      </div>
                      <span *ngIf="!showEditLoading && license.status != 'Pending'">View</span>
                      <span *ngIf="!showEditLoading && license.status == 'Pending'">Edit</span>
              </button>
              <button *ngIf="license.isRenewal"
                mat-flat-button type="button" color="primary" (click)="viewRenewal(license)">
                <div fxLayout="row" fxLayoutGap="8px" *ngIf="showEditLoading">
                  <span>Loading</span>
                  <mat-spinner color="accent" [diameter]="25" [strokeWidth]="5" aria-label="Loading"></mat-spinner>
                </div>
                <span *ngIf="license.status === sharedService.pendingRenewal">Continue Renewal</span>
                <span *ngIf="license.status !== sharedService.pendingRenewal">View</span>
              </button>
              <button *ngIf="license.canRenew && !hasRenewal(license) && license.status == sharedService.approved && !startedRenewal"
                mat-flat-button type="button" color="primary" (click)="createRenewal(license)">
                <span>Start Renewal</span>
              </button>
              <button *ngIf="license.status == 'Submitted'" mat-flat-button type="button" color="accent"
                (click)="requestWithdraw(license)">Request Withdraw
              </button>
              <button *ngIf="license.status == 'Approved'" mat-flat-button type="button" color="accent"
                (click)="requestSurrender(license)">Request Surrender
              </button>
              <!-- <button *ngIf="license.status !== sharedService.withdrawn && license.status !== sharedService.closed"
                      mat-flat-button type="button" color="warn" (click)="withdrawApplication(license)">
                <span *ngIf="license.status === sharedService.submitted || license.status === sharedService.underReview">Request Withdrawal</span>
                <span *ngIf="license.status !== sharedService.submitted && license.status !== sharedService.underReview">Withdraw</span>
              </button> -->
              <a *ngIf="licenseHasUnpaidFees(license)" mat-flat-button type="button" color="warn" routerLink="/payments">Pay Fees</a>
              <button *ngIf="license.status == 'Approved'" mat-flat-button type="button" (click)="viewPrintScreen(license)">Print License</button>
              <button *ngIf="license.status == 'Approved'" color="primary" mat-flat-button type="button" (click)="changeDirectory(license)">
                <span class="text-wrap">
                  Change OPS Licensee Directory Consent
                </span>
              </button>
            </div>
          </div>

          <div fxLayout="row" fxLayout.lt-lg="column" class="m-t-8">
            <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
              <div fxLayout="row" fxFlex="100">
                <span fxFlex="50"><strong>Application Fee Status</strong></span>
                <span [ngClass]="hasPaidFee(license, paymentService.applicationFeeType) ? 'text-light-green-600':  'text-red-900'">
                  <span class="f-s-18">{{getPaymentFeeStatus(license, paymentService.applicationFeeType)}}</span>
                </span>
              </div>

              <div fxLayout="row" *ngIf="hasPaidFee(license, paymentService.applicationFeeType)" fxFlex="100">
                <span fxFlex="50"><strong>Application Fee Cleared</strong></span>
                <span class="f-s-18">{{getFeeCleared(license, paymentService.applicationFeeType)}}</span>
              </div>
            </div>

            <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
              <div fxLayout="row" fxFlex="100">
                <span fxFlex="50"><strong>License Fee Status</strong></span>
                <span [ngClass]="hasPaidFee(license, paymentService.licenseFeeType) ? 'text-light-green-600':  'text-red-900'">
                  <span class="f-s-18">{{getPaymentFeeStatus(license, paymentService.licenseFeeType)}}</span>
                  <span class="f-s-18">{{getPaymentFeeStatus(license, paymentService.reducedLicenseFeeType)}}</span>
                </span>
              </div>

              <div fxLayout="row" *ngIf="hasPaidFee(license, paymentService.licenseFeeType)" fxFlex="50">
                <span fxFlex="50"><strong>License Fee Cleared</strong></span>
                <span class="f-s-18">{{getFeeCleared(license, paymentService.licenseFeeType)}}</span>
                <span class="f-s-18">{{getFeeCleared(license, paymentService.reducedLicenseFeeType)}}</span>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>

<div *ngIf="showSubmitted">
  <mat-card class="sticky-header p-y-0" [style.background-color]="sharedService.purple">
    <mat-card-content>
      <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center" style="color: #303030">
        <mat-icon class="medium-icon m-24">group</mat-icon>
        <span *ngIf="!renewing" class="f-s-20" fxFlex>Facilitator Application Submitted</span>
        <span *ngIf="renewing" class="f-s-20" fxFlex>Facilitator Application Renewal Submitted</span>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-title *ngIf="!renewing">
      Thank you for submitting the Oregon Psilocybin Services facilitator license application.
    </mat-card-title>
    <mat-card-title *ngIf="renewing">
      Thank you for submitting the Oregon Psilocybin Services facilitator license renewal.
    </mat-card-title>
    <mat-card-content>
      <div fxLayout="column">
        <div fxLayout="row">
          <span class="f-s-16" fxFlex="75">
            You can review your application status and updates to the status in TLC.
            Please be sure to monitor your email for follow-up communication from the Oregon Psilocybin Services Licensing Team.
            To ensure you receive emails from OPS, please consider
            adding Licensing.Psilocybin@oha.oregon.gov to your email contacts to prevent the email going to your spam folder.
          </span>
        </div>
        <span class="f-s-20 m-t-8" *ngIf="!renewing">
          <strong>A Worker Permit is also required for your license, if you do not already have one click <a [routerLink]="['/license/worker-permit']">here</a> to be taken to your application.</strong>
        </span>
      </div>
    </mat-card-content>
  </mat-card>
  <app-payment-notice *ngIf="facilitatorLicense != null &&
                             facilitatorLicense.payments != null &&
                             licenseHasUnpaidFees(facilitatorLicense)"
                             feeType="ApplicationFee" entityType="FacilitatorLicense" [canPay]="true" [id]="facilitatorLicense.id">
  </app-payment-notice>

  <div fxLayout="row" fxLayoutGap="16px" class="m-t-16">
    <button mat-raised-button  type="button" (click)="continue()">Continue</button>
  </div>
</div>

<!--Renewals-->
<div fxLayout="column" fxLayoutGap="8px" *ngIf="renewing && !showSubmitted">
  <mat-stepper [orientation]="sharedService.isMobile? 'vertical' : 'horizontal'" #RenewalStepper
    [selectedIndex]="renewalStep" (selectionChange)="changeRenewalStep($event)">
    <!--Getting Started-->
    <mat-step>
      <ng-template matStepLabel>
        <span matTooltip="Getting Started" matTooltipPosition="below" [matTooltipShowDelay]="500">Getting Started</span>
      </ng-template>
      <div fxLayout="column" fxLayoutGap="8px" class="p-b-8">
        <mat-card>
          <mat-card-title>
            <span>
              Thank you for your interest in applying to renew your psilocybin Facilitator License through the Oregon Psilocybin Services - Training Program, Licensing, and Compliance system (TLC).
            </span>
          </mat-card-title>
          <mat-card-content>
            <div fxLayout="column" >
              <span>More detailed information to support the application process can be found in the Psilocybin Facilitator License Renewal Application Guide for Renewal Applications Submitted Online.</span>
              <span> Please visit <a  href="https://www.oregon.gov/oha/PH/PREVENTIONWELLNESS/Documents/Facilitator-License-Renewal-Application-Guide-ONLINE.pdf" target="_blank" rel="noreferrer noopener" style="word-break: break-all; white-space: normal;">https://www.oregon.gov/oha/PH/PREVENTIONWELLNESS/Documents/Facilitator-License-Renewal-Application-Guide-ONLINE.pdf</a>,
                to access this guide.</span>
            </div>
            <div fxLayout="row" fxLayout.lt-lg="column" style="padding-top: 2em" fxLayoutGap="12px">
              <div fxLayout="column"  fxLayoutGap="8px">
                <strong class="p-l-24">
                  Applicants must read, understand, and agree to adhere to the Oregon Psilocybin Services Revised Statutes
                  <a  href="https://www.oregonlegislature.gov/bills_laws/ors/ors475a.html" target="_blank" rel="noreferrer noopener" style="word-break: break-all; white-space: normal;">ORS 475A</a>,
                  Oregon Administrative Rules
                  <a  href="https://secure.sos.state.or.us/oard/displayDivisionRules.action?selectedDivision=7102" target="_blank" rel="noreferrer noopener" style="word-break: break-all; white-space: normal;">(OAR) Chapter 333</a>,
                  Division 333, as well as any other applicable statutes or laws.
                </strong>
              </div>
            </div>

            <div fxLayout="row" fxLayout.lt-lg="column" style="padding-top: 2em" fxLayoutGap="12px">
             <div fxLayout="column"  fxLayoutGap="8px">
               <strong>A completed renewal application packet contains the following:</strong>
               <span class="p-l-24">
                 <strong>• </strong>Completed and signed facilitator license renewal application
               </span>
               <span class="p-l-24">
                 <strong>• </strong>Uploaded evaluation of last year's Social Equity Plan
               </span>
             </div>
            </div>

          <div fxLayout="row" style="padding-top: 2em">
            <div fxLayout="column" fxLayoutGap="8px">
              <strong>Important Notes: </strong>
              <span class="p-l-24">
                <strong>• </strong>Please review each section of the application to ensure it is complete
              </span>
              <span class="p-l-24">
                <strong>• </strong>Toward the end of the renewal application, a fee page will describe how to submit the renewal license fee which may be paid online, by mail or in-person
              </span>
              <span class="p-l-24">
                <strong>• Timely and Untimely Applications:</strong>
                Please review the application guide for more detailed information on when to submit a renewal application.
              </span>
              <div fxLayout="column" style="padding-left: 1em;" fxLayoutGap="8px">
                <span class="p-l-24">
                  <strong>• </strong>
                  <strong>Timely:</strong> Renewal applications that are complete, submitted according to requirements, and submitted at
                  least 60 days before the license expires will be considered timely under OPS rules. Licensees who submit
                  timely applications may continue to operate on an expired license if OPS is unable to process the renewal
                  application prior to the license expiration date. Incomplete renewal applications will not be considered timely
                  unless they are made complete <strong>at least 60 days</strong> before the license expires.
                </span>
                <span class="p-l-24">
                  <strong>• </strong>
                  <strong>Untimely:</strong> Renewal applications that are submitted <strong>less than 60 days</strong> before the license expires will be considered
                  untimely under OPS rules. Licensees who submit untimely applications are prohibited from continuing to operate on an
                  expired license if OPS is unable to process the renewal application prior to the license expiration date. Licensees
                  are prohibited from exercising license privileges on the date their license expires if OPS is unable to process their
                  renewal application before the expiration date and the renewal application is untimely.
                </span>
              </div>
            </div>
          </div>

          <div fxLayout="column" fxLayoutGap="8px" style="padding-top: 2em">
            <span>
              <strong>When you click the continue button at the bottom of this page, you will proceed to the psilocybin facilitator license application.</strong>
              The renewal automatically saves in TLC. You may exit the application and come back later to complete and submit the renewal application, if needed
            </span>
          </div>
          <div fxLayout="column" fxLayoutGap="8px" style="padding-top: 2em;" >
            <span>
              <strong>DISCLAIMER:</strong>
              Psilocybin remains a schedule I drug under the federal Controlled Substances Act.
              Applicants are responsible for making their own determinations regarding the legal risks associated with participating in this program.
              The information you provide in your application materials, including applicant name, is subject to disclosure under Oregon's public records laws.
            </span>
          </div>
          </mat-card-content>
        </mat-card>
        <div fxLayout="row" fxFlex="50" fxFlex.lt-md="100" class="p-t-16">
          <button mat-raised-button matStepperNext>Continue</button>
        </div>
      </div>
    </mat-step>

    <!--Applicant-->
    <mat-step [stepControl]="applicantForm">
      <form [formGroup]="applicantForm" fxLayoutGap="8px">
        <ng-template matStepLabel>
          <span class="text-red-400" *ngIf="(!applicantForm.valid && !applicantForm.pristine && !licenseLocked) || !applicantDocumentsValid"
            matTooltip="Personal Information Incomplete" matTooltipPosition="below" [matTooltipShowDelay]="500">
            Applicant Information Incomplete</span>
          <span *ngIf="(applicantForm.valid || applicantForm.pristine || licenseLocked) && applicantDocumentsValid"
            matTooltip="Applicant Information" matTooltipPosition="below" [matTooltipShowDelay]="500">Applicant Information</span>
        </ng-template>
        <div fxLayout="column">
          <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
            <mat-card fxFlex="50" fxFlex.lt-lg="100">
              <mat-card-title>
                <div fxLayout="row" fxLayout.lt-lg="column">
                  <span fxFlex>Applicant Information</span>
                  <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
                    <span class="f-s-16"><strong>Facilitator Id</strong></span>
                    <span class="f-s-16" id="programId">{{facilitatorLicense.licenseId}}</span>
                    <button mat-icon-button (click)="sharedService.copyToClipboard(facilitatorLicense.licenseId)" color="primary"
                            matTooltip="Copy to Clipboard" matTooltipShowDelay=800 aria-label="Copy to Clipboard">
                      <mat-icon>content_copy</mat-icon>
                    </button>
                  </div>
                </div>
              </mat-card-title>
              <mat-card-content>
                <div fxLayout="column" fxLayoutGap="8px">
                  <span><strong>Legal Name</strong></span>
                  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" fxLayoutGap.lt-lg="">
                    <mat-form-field fxFlex="40" fxFlex.lt-md="100">
                      <input matInput placeholder="First" formControlName="legalFirstName">
                      <mat-error *ngIf="applicantForm.get('legalFirstName').invalid">
                        Please enter your first name
                      </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="40" fxFlex.lt-md="100">
                      <input matInput placeholder="Last" formControlName="legalLastName">
                      <mat-error *ngIf="applicantForm.get('legalLastName').invalid">
                        Please enter your last name
                      </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="20" fxFlex.lt-md="100">
                      <input matInput placeholder="Middle" formControlName="legalMiddleName">
                    </mat-form-field>
                  </div>

                  <span><strong>Preferred Name</strong></span>
                  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" fxLayoutGap.lt-lg="">
                    <mat-form-field fxFlex="40" fxFlex.lt-md="100">
                      <input matInput placeholder="First" formControlName="preferredFirstName">
                    </mat-form-field>

                    <mat-form-field fxFlex="40" fxFlex.lt-md="100" disabled>
                      <input matInput placeholder="Last" formControlName="preferredLastName">
                    </mat-form-field>

                    <mat-form-field fxFlex="20" fxFlex.lt-md="100" disabled>
                      <input matInput placeholder="Middle" formControlName="preferredMiddleName">
                    </mat-form-field>
                  </div>

                  <div fxLayout="column">
                    <span><strong>Prior Names and Aliases</strong></span>
                    <span>This information is used for the purposes of the background check</span>
                    <div fxLayout="row" fxLayoutGap="8px" fxLayout.lt-lg="column">
                      <mat-form-field fxFlex="80" fxFlex.lt-md="100" disabled>
                        <input matInput placeholder="Name, other name, any other names"
                          formControlName="alternateNames">
                      </mat-form-field>

                      <mat-form-field>
                        <mat-label>Date of Birth</mat-label>
                        <input placeholder="mm/dd/yyyy" formControlName="dob" matInput [matDatepicker]="dob"
                          [max]="sharedService.today" [disabled]="renewing"/>
                        <mat-datepicker-toggle matSuffix [for]="dob"></mat-datepicker-toggle>
                        <mat-datepicker #dob>
                          <mat-datepicker-actions>
                            <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
                            <button mat-button matDatepickerCancel>Cancel</button>
                          </mat-datepicker-actions>
                        </mat-datepicker>
                        <mat-error *ngIf="applicantForm.get('dob').value > sharedService.today">
                          Please choose an earlier date.
                        </mat-error>
                        <mat-error *ngIf="applicantForm.get('dob').hasError('required')">
                          Date of birth is required
                        </mat-error>
                      </mat-form-field>

                    </div>
                  </div>

                  <div fxLayout="column">
                    <span><strong>*SOCIAL SECURITY NUMBER DISCLOSURE:</strong></span>
                    <span style="padding-top: 1em; padding-left: 1em"><strong>• </strong>
                      As part of your application for an initial or renewal license, Federal and State laws require you to provide your
                      Social Security Number (SSN),
                      if you have one, to Oregon Psilocybin Services for child support enforcement purposes (42 USC § 666(a)(13) & ORS
                      25.785).
                      If you are an applicant or licensee and you have an SSN but fail to provide your SSN, Oregon Psilocybin Services may
                      refuse to process your application.
                      Your SSN will be used only for child support enforcement purposes unless you confirm your agreement that OPS can use
                      it for administrative purposes
                      as well.
                    </span>

                    <span style="padding-top: 1em; padding-left: 1em">
                      <strong>IMPORTANT NOTE: If you do not have a social security number, you may still be licensed or permitted by Oregon
                        Psilocybin Services.</strong>
                    </span>

                    <span style="padding-top: 1em; padding-left: 1em"><strong>• </strong>
                      Based on our authority under OAR 333-333-4100, we are requesting your voluntary consent to use your SSN for the
                      following administrative purposes only:
                      to positively confirm your identity during the criminal records check. Oregon Psilocybin Services will not deny you
                      any rights, benefits or privileges
                      otherwise provided by law if you do not consent to use of your SSN for these administrative purposes (5 USC§
                      552(a)).
                      <strong>Please check the appropriate box next to the social security field indicating whether you consent or do not
                        consent.</strong>
                    </span>
                    <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="16px" fxLayoutGap.lt-lg="" fxLayoutAlign="start center"
                      fxLayoutAlign.lt-lg="">
                      <mat-form-field fxFlex="25" fxFlex.lt-md="100">
                        <input matInput placeholder="SSN" formControlName="ssn" mask="000-00-0000">
                        <mat-error *ngIf="validateSsn()">
                          SSN is Required
                        </mat-error>
                      </mat-form-field>
                      <mat-checkbox formControlName="noSsn" [disabled]="renewing">
                        I don't have a social security number
                      </mat-checkbox>
                    </div>
                     <div fxLayout="column" fxLayoutGap="8px">
                      <span [ngStyle]="{'color': validateSsnAttestation()? 'red' : ''}">Do you consent to use of SSN for purposes of confirming identity during a criminal records check?</span>
                      <mat-checkbox formControlName="ssnAttestation" (click)="changeAttestation(true)">
                        Yes
                      </mat-checkbox>
                      <mat-checkbox formControlName="ssnNoAttestation" (click)="changeAttestation(false)">
                        No
                      </mat-checkbox>
                    </div>
                    <span class="text-wrap">Since the last time you applied for a license/renewal have you had any updates to your convictions history related to misdemeanors or felonies?</span>
                    <mat-radio-group aria-label="Select an option" formControlName="changeInConvictionHistory" fxLayoutGap="8px">
                      <mat-radio-button [value]="true">Yes</mat-radio-button>
                      <mat-radio-button [value]="false">No</mat-radio-button>
                    </mat-radio-group>
                    <mat-error *ngIf="applicantForm.get('changeInConvictionHistory').hasError('required')">This field is required</mat-error>
                    <span class="text-wrap"><i>Oregon Background Check Unit provides background checks for OPS. We will reach out if additional background check work is required.</i></span>
                  </div>
                  <div fxLayout="column" fxLayoutGap="12px">
                    <span><strong>
                      *For purposes of these questions, “license” means any professional or occupational license, permit or certification that authorizes an individual
                      to engage in an occupation or business, issued by a local, state, or tribal governmental entity, or by a governmental body in another country.
                    </strong></span>
                    <span class="text-wrap">In the last 10 years, have you held a license in Oregon or in another state or country? If yes, please describe the type of license, what state or country it was issued in, and provide dates that the license was in effect.</span>
                    <mat-radio-group aria-label="Select an option" formControlName="heldLicense" fxLayoutGap="8px">
                      <mat-radio-button [value]="true">Yes</mat-radio-button>
                      <mat-radio-button [value]="false">No</mat-radio-button>
                    </mat-radio-group>
                    <mat-error *ngIf="validateHeldLicense()">This field is required</mat-error>
                    <mat-form-field fxFlex="75" fxFlex.lt-lg="100" *ngIf="applicantForm.get('heldLicense').value">
                      <textarea matInput placeholder="Previous Licenses Held" cols="2" formControlName="licensesHeld"></textarea>
                    </mat-form-field>
                    <mat-error *ngIf="validateHeldLicenses()"></mat-error>

                    <span class="text-wrap">In the last 10 years, have you been denied a license? If yes, please upload documentation explaining why and provide all relevant information.</span>
                    <mat-radio-group aria-label="Select an option" formControlName="deniedLicense" fxLayoutGap="8px">
                      <mat-radio-button [value]="true">Yes</mat-radio-button>
                      <mat-radio-button [value]="false">No</mat-radio-button>
                    </mat-radio-group>
                    <mat-error *ngIf="validateDeniedLicense()">This field is required</mat-error>
                    <mat-error *ngIf="!validDeniedLicenseDocument()">You must upload a supporting document</mat-error>
                    <div *ngIf="applicantForm.get('deniedLicense').value">
                      <input type="file" class="file-input" (change)="uploadAdditionalDocument($event, deniedLicenseType)"
                        #deniedLicenseUpload />
                      <div fxLayout="row" fxLayoutGap="8px">
                        <div class="file-upload">
                          <button type="button" [disabled]="licenseLocked"
                            mat-raised-button color="primary" class="upload-btn" (click)="deniedLicenseUpload.click()">
                            Browse
                            <mat-icon>attach_file</mat-icon>
                          </button>
                        </div>
                      </div>
                    </div>

                    <span class="text-wrap">For any license you have held, have you been subject to discipline by the entity that issued the license or had the license suspended or revoked in the last 10 years?
                      If so, please upload documentation explaining the reasons for the discipline or revocation and provide all relevant documentation.</span>
                    <mat-radio-group aria-label="Select an option" formControlName="subjectToDiscipline" fxLayoutGap="8px">
                      <mat-radio-button [value]="true">Yes</mat-radio-button>
                      <mat-radio-button [value]="false">No</mat-radio-button>
                    </mat-radio-group>
                    <mat-error *ngIf="validateSubjectToDiscipline()">This field is required</mat-error>
                    <mat-error *ngIf="!validSubjectToDisciplineDocument()">You must upload a supporting document</mat-error>
                    <div *ngIf="applicantForm.get('subjectToDiscipline').value">
                      <input type="file" class="file-input" (change)="uploadAdditionalDocument($event, subjectToDisciplineType)"
                        #subjectToDisciplineUpload />
                      <div fxLayout="row" fxLayoutGap="8px">
                        <div class="file-upload">
                          <button type="button" [disabled]="licenseLocked"
                            mat-raised-button color="primary" class="upload-btn" (click)="subjectToDisciplineUpload.click()">
                            Browse
                            <mat-icon>attach_file</mat-icon>
                          </button>
                        </div>
                      </div>
                    </div>

                    <span class="text-wrap">In the last 10 years, have you been sued for money damages related to work you provided under a license? If yes, please upload documentation to explain and provide all relevant documentation.</span>
                    <mat-radio-group aria-label="Select an option" formControlName="suedForDamages" fxLayoutGap="8px">
                      <mat-radio-button [value]="true">Yes</mat-radio-button>
                      <mat-radio-button [value]="false">No</mat-radio-button>
                    </mat-radio-group>
                    <mat-error *ngIf="validateSuedForDamages()">This field is required</mat-error>
                    <mat-error *ngIf="!validSuedForDamagesDocument()">You must upload a supporting document</mat-error>
                    <div *ngIf="applicantForm.get('suedForDamages').value">
                      <input type="file" class="file-input" (change)="uploadAdditionalDocument($event, suedForDamagesType)"
                        #suedForDamagesUpload />
                      <div fxLayout="row" fxLayoutGap="8px">
                        <div class="file-upload">
                          <button type="button" [disabled]="licenseLocked"
                            mat-raised-button color="primary" class="upload-btn" (click)="suedForDamagesUpload.click()">
                            Browse
                            <mat-icon>attach_file</mat-icon>
                          </button>
                        </div>
                      </div>
                    </div>

                    <span class="text-wrap">In the last 10 years, have you settled allegations or claims against you related to a license? If so, please upload documentation to explain and provide all relevant documentation.</span>
                    <mat-radio-group aria-label="Select an option" formControlName="settledAllegations" fxLayoutGap="8px">
                      <mat-radio-button [value]="true">Yes</mat-radio-button>
                      <mat-radio-button [value]="false">No</mat-radio-button>
                    </mat-radio-group>
                    <mat-error *ngIf="validateSettledAllegations()">This field is required</mat-error>
                    <mat-error *ngIf="!validSettledAllegationsDocument()">You must upload a supporting document</mat-error>
                    <div *ngIf="applicantForm.get('settledAllegations').value">
                      <input type="file" class="file-input" (change)="uploadAdditionalDocument($event, settledAllegationsType)"
                        #settledAllegationsUpload />
                      <div fxLayout="row" fxLayoutGap="8px">
                        <div class="file-upload">
                          <button type="button" [disabled]="licenseLocked"
                            mat-raised-button color="primary" class="upload-btn" (click)="settledAllegationsUpload.click()">
                            Browse
                            <mat-icon>attach_file</mat-icon>
                          </button>
                        </div>
                      </div>
                    </div>

                    <span class="text-wrap">Has anyone ever alleged that you abused a child or an adult? If you answered yes, please upload documentation to explain the events and provide all relevant documentation.</span>
                    <mat-radio-group aria-label="Select an option" formControlName="allegedAbuse" fxLayoutGap="8px">
                      <mat-radio-button [value]="true">Yes</mat-radio-button>
                      <mat-radio-button [value]="false">No</mat-radio-button>
                    </mat-radio-group>
                    <mat-error *ngIf="validateAllegedAbuse()">This field is required</mat-error>

                    <div *ngIf="applicantForm.get('allegedAbuse').value">
                      <div fxLayout="row">
                        <span class="text-wrap">Was the allegation substantiated? If you answered yes, please upload documentation to explain the events and provide all relevant documentation.</span>
                      </div>
                      <div fxLayout="row">
                        <mat-radio-group aria-label="Select an option" formControlName="substantiatedAllegation" fxLayoutGap="8px">
                          <mat-radio-button [value]="true">Yes</mat-radio-button>
                          <mat-radio-button [value]="false">No</mat-radio-button>
                        </mat-radio-group>
                      </div>
                    </div>
                    <mat-error *ngIf="validateSubstantiatedAllegation()">This field is required</mat-error>
                    <mat-error *ngIf="!validAllegedAbuseDocument()">You must upload a supporting document</mat-error>

                    <div *ngIf="applicantForm.get('allegedAbuse').value">
                      <input type="file" class="file-input" (change)="uploadAdditionalDocument($event, allegedAbuseType)"
                        #allegedAbuseUpload />
                      <div fxLayout="row" fxLayoutGap="8px">
                        <div class="file-upload">
                          <button type="button" [disabled]="licenseLocked"
                            mat-raised-button color="primary" class="upload-btn" (click)="allegedAbuseUpload.click()">
                            Browse
                            <mat-icon>attach_file</mat-icon>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>

            <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
              <mat-card>
                <mat-card-title>
                  Contact Information
                </mat-card-title>
                <mat-card-content>
                  <div fxLayout="column" fxLayoutGap="8px">
                    <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
                      <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                        <span class="f-2-20"><strong>Primary Phone</strong></span>
                        <mat-form-field>
                          <input matInput placeholder="Phone" formControlName="phone" mask="(000) 000-0000 000000" [validation]="false">
                          <mat-error *ngIf="applicantForm.get('phone').hasError('required')">
                            A primary phone number is required
                          </mat-error>
                          <mat-error *ngIf="applicantForm.get('phone').hasError('pattern')">
                            Phone should match this format: 999-999-9999 x1234
                          </mat-error>
                        </mat-form-field>
                      </div>
                      <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                        <span class="f-2-20"><strong>Alternate Phone</strong></span>
                        <mat-form-field>
                          <input matInput placeholder="Alt Phone" formControlName="alternatePhone" mask="(000) 000-0000 000000" [validation]="false">
                          <mat-error *ngIf="applicantForm.get('alternatePhone').hasError('pattern')">
                            Phone should match this format: 999-999-9999 x1234
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>

                    <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
                      <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                        <span class="f-2-20"><strong>Primary Email Address</strong></span>
                        <mat-form-field>
                          <input matInput placeholder="Email" formControlName="email">
                          <mat-error *ngIf="applicantForm.get('email').hasError('required')">
                            Please enter an email adress
                          </mat-error>
                          <mat-error *ngIf="applicantForm.get('email').hasError('email')">
                            This doesn't look like a valid email address
                          </mat-error>
                        </mat-form-field>
                      </div>
                      <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                        <span class="f-2-20"><strong>Confirm Email Address</strong></span>
                        <mat-form-field>
                          <input matInput placeholder="Confirm Email" formControlName="confirmEmail">
                          <mat-error *ngIf="validateEmail()">
                            Email does not match
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>

                    <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
                      <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                        <span class="f-2-18"><strong>Preferred Language</strong></span>
                        <mat-form-field>
                          <input matInput placeholder="Language" formControlName="language">
                        </mat-form-field>
                      </div>
                    </div>

                    <div fxLayout="column">
                      <span class="f-2-20"><strong>Other OPS Licenses or Permits:</strong></span>
                      <mat-checkbox formControlName="previousLicense" (change)="validatePreviousLicense()">
                        Check the box if the applicant applied for or received any other licenses or permits from Oregon Psilocybin Services
                      </mat-checkbox>
                      <div fxLayout="column" class="m-t-8" *ngIf="this.applicantForm.get('previousLicense').value">
                        <span>Please provide the license id(s) for each Oregon Psilocybin Services license.</span>
                        <mat-form-field>
                          <input matInput formControlName="affiliatedLicenses">
                          <mat-placeholder style="color:red" *ngIf="applicantForm.get('affiliatedLicenses').value == ''">Affiliated Licenses*</mat-placeholder>
                          <mat-placeholder style="color:grey" *ngIf="applicantForm.get('affiliatedLicenses').value != ''">Affiliated Licenses</mat-placeholder>
                          <mat-error *ngIf="validatePreviousLicense()">
                            Please provide all license numbers
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>

              <mat-card>
                <mat-card-title>Address</mat-card-title>
                <mat-card-content>
                  <div fxLayout="column">
                    <span><strong>Physical Address</strong></span>
                    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
                      <mat-form-field fxFlex="30" fxFlex.lt-md="100">
                        <input matInput placeholder="Street Apt/Unit/Suite" formControlName="physicalStreet">
                        <mat-error *ngIf="applicantForm.get('physicalStreet').invalid">
                          Please provide a street
                        </mat-error>
                      </mat-form-field>

                      <mat-form-field fxFlex="30" fxFlex.lt-md="100">
                        <input matInput placeholder="City" formControlName="physicalCity">
                        <mat-error *ngIf="applicantForm.get('physicalCity').invalid">
                          Please provide a city
                        </mat-error>
                      </mat-form-field>

                      <mat-form-field fxFlex="40" fxFlex.lt-md="100">
                        <mat-label>State/US territory/freely associated state</mat-label>
                        <mat-select formControlName="physicalState">
                          <mat-option *ngFor="let state of sharedService.states" value={{state}}>{{state}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="applicantForm.get('physicalState').invalid">
                          Please provide a state
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
                      <mat-form-field fxFlex="40" fxFlex.lt-md="100">
                        <input matInput placeholder="Zip" formControlName="physicalZip">
                        <mat-error *ngIf="applicantForm.get('physicalZip').hasError('required')">
                          Please provide a zipcode
                        </mat-error>
                        <mat-error *ngIf="applicantForm.get('physicalZip').hasError('pattern')">
                          Zipcode should match this format: 99999 or 99999-1234
                        </mat-error>
                      </mat-form-field>

                      <mat-form-field fxFlex="40" fxFlex.lt-md="100"
                      *ngIf="applicantForm.get('physicalState').value === 'OR'">
                        <mat-label>County</mat-label>
                        <mat-select formControlName="physicalCounty">
                          <mat-option>-- None --</mat-option>
                          <mat-option *ngFor="let county of sharedService.counties" value={{county}}>{{county}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>

                  <div fxLayout="column" class="m-t-8">
                    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="24px">
                      <span><strong>Mailing Address</strong></span>
                      <mat-checkbox formControlName="mailingIsPhysical" (change)="mailingIsSame()">
                        Same as physical
                      </mat-checkbox>
                    </div>
                    <div fxLayout="column" [@slideInOut] *ngIf="!applicantForm.get('mailingIsPhysical').value">
                      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
                        <mat-form-field fxFlex="30" fxFlex.lt-md="100">
                          <input matInput placeholder="Street Apt/Unit/Suite" formControlName="mailingStreet"
                            (blur)="validateMailingStreet()">
                          <mat-error *ngIf="validateMailingStreet()">
                            Please provide a street
                          </mat-error>
                        </mat-form-field>



                        <mat-form-field fxFlex="30" fxFlex.lt-md="100">
                          <input matInput placeholder="City" formControlName="mailingCity" (blur)="validateMailingCity()">
                          <mat-error *ngIf="validateMailingCity()">
                            Please provide a city
                          </mat-error>
                        </mat-form-field>

                        <mat-form-field fxFlex="40" fxFlex.lt-md="100">
                          <mat-label>State/US territory/freely associated state</mat-label>
                          <mat-select formControlName="mailingState" (blur)="validateMailingState()">
                            <mat-option *ngFor="let state of sharedService.states" value={{state}}>{{state}}</mat-option>
                          </mat-select>
                          <mat-error *ngIf="validateMailingState()">
                            Please provide a state
                          </mat-error>
                        </mat-form-field>
                      </div>

                      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
                        <mat-form-field fxFlex="40" fxFlex.lt-md="100">
                          <input matInput placeholder="Zip" formControlName="mailingZip" (blur)="validateMailingZip()">
                          <mat-error *ngIf="validateMailingZip()">
                            Please provide a zipcode
                          </mat-error>
                          <mat-error *ngIf="applicantForm.get('mailingZip').hasError('pattern')">
                            Zipcode should match this format: 99999 or 99999-1234
                          </mat-error>
                        </mat-form-field>

                        <mat-form-field fxFlex="40" fxFlex.lt-md="100"
                          *ngIf="applicantForm.get('mailingState').value === 'OR'">
                          <mat-label>County</mat-label>
                          <mat-select formControlName="mailingCounty">
                            <mat-option>-- None --</mat-option>
                            <mat-option *ngFor="let county of sharedService.counties" value={{county}}>{{county}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>

                  <app-residential-history [applicantId]="facilitatorLicense.applicants[0]?.id"
                                           [residentialHistory]="facilitatorLicense.applicants[0]?.residentialHistory"
                                           [licenseLocked]="licenseLocked"
                                           *ngIf="backgroundExpiring">
                  </app-residential-history>
                </mat-card-content>
              </mat-card>
            </div>
          </div>

          <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
            <mat-card fxFlex="50" fxFlex.lt-lg="100">
              <mat-card-title>
                <span *ngIf="applicantDocumentsValid && showOregonResidency(facilitatorLicense.applicationDate)">
                  Identity and Residency Verification
                </span>
                <span *ngIf="!applicantDocumentsValid && showOregonResidency(facilitatorLicense.applicationDate)" class="text-red-400">
                  Identity and Residency Verification Incomplete
                </span>
                <span *ngIf="applicantDocumentsValid && !showOregonResidency(facilitatorLicense.applicationDate)">
                  Identity Verification
                </span>
                <span *ngIf="!applicantDocumentsValid && !showOregonResidency(facilitatorLicense.applicationDate)" class="text-red-400">
                  Identity Verification Incomplete
                </span>
              </mat-card-title>
              <mat-card-content>
                <div fxlayout="row" *ngIf="showOregonResidency(facilitatorLicense.applicationDate)">
                <mat-checkbox formControlName="oregonResidency">
                  <span class="text-wrap" [ngStyle]="{'color': applicantForm.get('oregonResidency').hasError('required') && applicantForm.get('oregonResidency').touched? 'red' : ''}">I certify that I am a current Oregon resident and have been a resident of Oregon for two or more years</span>
                  <mat-error *ngIf="applicantForm.get('oregonResidency').hasError('required') && applicantForm.get('oregonResidency').touched">
                    You must attest to being an Oregon resident
                  </mat-error>
                </mat-checkbox>
              </div>
              <div fxlayout="row">
                <mat-checkbox formControlName="highSchoolDiploma">
                  <span class="text-wrap" [ngStyle]="{'color': applicantForm.get('highSchoolDiploma').hasError('required') && applicantForm.get('highSchoolDiploma').touched? 'red' : ''}">I certify I have a high school diploma or equivalent education as required by ORS 475A.325 (2)(d)</span>
                  <mat-error *ngIf="applicantForm.get('highSchoolDiploma').hasError('required') && applicantForm.get('highSchoolDiploma').touched">
                    You must attest to having a high school diploma or equivalent education
                  </mat-error>
                </mat-checkbox>
              </div>
                <div fxLayout="column" fxLayoutGap.lt-lg="8px" style="padding-top: 2em">
                  <span>If you have an updated form of identification, upload a copy of the front and back of the valid, unexpired government-issued photo identification. Accepted identification includes:</span>
                  <span style="padding-left: 1em">
                    <strong>• </strong>Passport;
                  </span>
                  <span style="padding-left: 1em">
                    <strong>• </strong>Driver license, whether issued by the State of Oregon or by another state of the United States;
                  </span>
                  <span style="padding-left: 1em">
                    <strong>• </strong>Identification card issued under ORS 807.400;
                  </span>
                  <span style="padding-left: 1em">
                    <strong>• </strong>United States military identification card;
                  </span>
                  <span style="padding-left: 1em">
                    <strong>• </strong>An identification card issued by a federally recognized Indian tribe with photo, name and date of birth; or
                  </span>
                  <span style="padding-left: 1em">
                    <strong>• </strong>Any other identification card issued by a state or territory that bears a picture of the person,
                                       the name of the person, the person's date of birth and a physical description of the person
                  </span>
                  <div fxLayout="column" fxLayoutGap="8px" class="m-t-8">
                    <span class="f-s-14">Upload Your Identification</span>
                    <input type="file" accept=".doc,.docx,.rtf,.xls,.xlsx,.pdf,.txt,.png,.jpg,.jpeg,.bmp" class="file-input" (change)="uploadApplicantDocument($event)"
                      #applicantFileUpload />
                    <div fxLayout="row" fxLayoutGap="8px">
                      <div class="file-upload">
                        <button type="button" [disabled]="licenseLocked"
                          mat-raised-button color="primary" class="upload-btn" (click)="applicantFileUpload.click()">
                          Browse
                          <mat-icon>attach_file</mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
            <mat-card fxFlex="50" fxFlex.lt-lg="100">
              <mat-card-title>
                <span *ngIf="applicantDocumentsValid">Current Documents</span>
              </mat-card-title>
              <mat-card-content>
                <div fxLayout="column">
                  <div fxLayout="row" *ngIf="!sharedService.mobile">
                    <span class="f-s-16" fxFlex="30"><strong>File</strong></span>
                    <span class="f-s-16"><strong>Requirement</strong></span>
                  </div>
                  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center" fxLayoutAlign.lt-md=""
                    *ngFor="let document of facilitatorLicense.applicants[0]?.applicantDocuments">
                    <span fxFlex="30" fxFlex.lt-md="" class="text-indigo-A700 text-wrap" style="cursor: pointer" (click)="downloadDocument(document.id, document.name, true)" title="{{document.name}}">{{document.name.length > 15 ? document.name.substring(0,15) + "..." : document.name}}</span>
                    <div fxFlex="70" fxFlex.lt-md="" fxLayout="row" fxLayoutAlign="start center">
                      <div fxFlex="40" fxFlex.lt-md="" fxLayout="column">
                        <span *ngFor="let type of document.types">
                          {{documentTypeReference[type]}}
                        </span>
                      </div>
                      <button *ngIf="!licenseLocked && !document.deprecated" type="button" mat-icon-button class="m-8" (click)="deleteFile(document.id, document.name, true)"
                        color="warn" aria-label="Delete">
                        <mat-icon>delete</mat-icon>
                      </button>
                      <span *ngIf="document.deprecated" style="color:red">Deprecated</span>
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </div>

        <mat-card fxLayoutGap="8px" fxLayout="column" >
          <div fxLayout="row">
              <div fxLayout="column" fxLayoutGap="8px">
                <span class="text-wrap">Is all the information on this screen current and correct?</span>
                <span class="text-wrap"><i>Some information may be outdated or incorrect but you cannot change it on this application.
                  These changes require additional documentation and possibly additional fees. These changes are made by a Change Request.
                  If information is incorrect or outdated, please select "No," below and follow the instructions for a Change Request.</i></span>
                <mat-radio-group aria-label="Select an option" formControlName="applicantPageCorrect" fxLayoutGap="8px">
                  <mat-radio-button [value]="true">Yes</mat-radio-button>
                  <mat-radio-button [value]="false">No</mat-radio-button>
                </mat-radio-group>
                <mat-error *ngIf="applicantForm.get('applicantPageCorrect').hasError('required')">This field is required</mat-error>
              </div>
          </div>

          <div fxLayout="row" *ngIf="applicantForm.get('applicantPageCorrect').value == false">
            <div fxLayout="column" fxFlex="40">
              <mat-form-field>
                <textarea rows="2" matInput placeholder="Please list the information that is not current and correct" formControlName="applicantPageCorrectReason"></textarea>
                <mat-error *ngIf="validateApplicantPageCorrect()">
                  This field is required
                </mat-error>
               </mat-form-field>
               <span>You are required to submit a Change Request to update this information. For more information on Change Requests, please see the TLC Home Page, Change Requests section. This is the first page you see when you log into TLC.</span>
            </div>
          </div>
        </mat-card>
      </form>

      <div class="m-t-16" fxLayout="row" fxLayoutGap="8px" fxFlex="50" fxFlex.lt-md="100">
        <button mat-raised-button matStepperPrevious>{{!licenseLocked? "Save and " : ""}}Back</button>
        <button mat-raised-button matStepperNext>{{!licenseLocked? "Save and " : ""}}Continue</button>
      </div>
    </mat-step>

    <!--Documents-->
     <mat-step [stepControl]="documentsForm" *ngIf="facilitatorLicenses.length > 0">
      <form [formGroup]="documentsForm">
        <ng-template matStepLabel>

          <span *ngIf="((documentsForm.valid && licenseDocumentsValid) || documentsForm.pristine || licenseLocked)"
            matTooltip="Documents" matTooltipPosition="below" [matTooltipShowDelay]="500">Documents</span>

          <span class="text-red-400" *ngIf="((!documentsForm.valid && !documentsForm.pristine) || !licenseDocumentsValid) && !licenseLocked"
            matTooltip="Documents Incomplete" matTooltipPosition="below" [matTooltipShowDelay]="500">Documents Incomplete</span>

          </ng-template>
        <div fxLayout="column" fxLayoutGap="8px">
          <div fxLayout="row" fxLayout.lt-lg="column">
            <mat-card fxFlex="100">
              <mat-card-title>
                Upload the Following Documentation to Apply for a Psilocybin Facilitator License:
              </mat-card-title>
              <mat-card-content>
                <div fxLayout="column" fxLayoutGap="8px" class="p-b-8">
                  <span class="p-l-24"><strong>• </strong>Social Equity Plan Evaluation</span>
                  <span class="p-l-24"><strong>• </strong>Updated Social Equity Plan (not required)</span>
                  <span class="p-l-24"><strong>• </strong>A copy of your certificate of completion from a psilocybin facilitator training program with an OPS approved curriculum (not required)</span>
                  <div fxLayout="column" style="padding-left: 2em">
                    <span class="p-l-24"><strong>• </strong>Please note: the facilitator training program must have an OPS approved curriculum </span>
                  </div>
                  <span class="p-l-24"><strong>• </strong>If you are requesting a reduced licensing fee, please upload one of the following required supporting documents:</span>

                  <div fxLayout="column" style="padding-left: 2em">
                    <span class="p-l-24"><strong>• </strong>A copy of a current monthly Supplemental Security Income benefit statement showing dates of coverage</span>
                  </div>
                  <div fxLayout="column" style="padding-left: 2em">
                    <span class="p-l-24"><strong>• </strong>A copy of the applicant's current eligibility statement or card if enrolled in Oregon Health Plan</span>
                  </div>
                  <div fxLayout="column" style="padding-left: 2em">
                    <span class="p-l-24"><strong>• </strong>A copy of current proof of food stamp benefits from the Oregon Supplemental Nutrition Assistance Program </span>
                  </div>
                  <div fxLayout="column" style="padding-left: 2em">
                    <span class="p-l-24"><strong>• </strong>A copy of proof of having served in the Armed Forces, such as but not limited to, submitting a Veteran's Administration form DD-214</span>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>

          <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="16px" fxFlex="100">
            <mat-card fxFlex="50" fxFlex.lt-lg="100">
              <mat-card-title>
                Upload Supporting Documents
              </mat-card-title>
              <mat-card-content>
                <div fxLayout="column" fxLayoutGap="8px">
                  <span class="f-2-14">For each document select the requirements it verifies</span>
                  <div fxLayout="column" fxLayoutGap="8px" class="p-t-8">

                    <div fxLayout="column">
                      <mat-checkbox formControlName="trainingCertificate" [labelPosition]="'before'">
                       <span [ngClass]="missingTypes[trainingCertificateType]? 'text-red-400' : ''">Training Certificate</span>
                      </mat-checkbox>

                      <mat-checkbox formControlName="updatedEquityPlan" [labelPosition]="'before'">
                        <span [ngClass]="missingTypes[updatedEquityPlanType]? 'text-red-400' : ''">
                          Updated Social Equity Plan
                        </span>
                      </mat-checkbox>

                      <mat-checkbox formControlName="equityPlanEvaluation" [labelPosition]="'before'">
                        <span [ngClass]="missingTypes[equityPlanEvaluationType]? 'text-red-400' : ''">
                          Social Equity Plan Evaluation
                        </span>
                      </mat-checkbox>

                      <mat-checkbox formControlName="reducedFee" [labelPosition]="'before'">Reduced Fee</mat-checkbox>
                    </div>

                    <input type="file" accept=".doc,.docx,.rtf,.xls,.xlsx,.pdf,.txt,.png,.jpg,.jpeg,.bmp" class="file-input" (change)="uploadLicenseDocument($event)" #fileUpload />
                    <div fxLayout="row" fxLayoutGap="8px">
                      <div class="file-upload">
                        <button [disabled]="!documentsForm.get('trainingCertificate').value &&
                                            !documentsForm.get('socialEquityPlan').value &&
                                            !documentsForm.get('reducedFee').value &&
                                            !documentsForm.get('updatedEquityPlan').value &&
                                            !documentsForm.get('equityPlanEvaluation').value"
                                mat-raised-button color="primary" class="upload-btn" (click)="fileUpload.click()">
                          Browse
                          <mat-icon>attach_file</mat-icon>
                        </button>
                      </div>
                    </div>

                  </div>
                </div>
              </mat-card-content>
            </mat-card>

            <mat-card fxFlex="50" fxFlex.lt-lg="100">
              <mat-card-title>
                <span *ngIf="applicantDocumentsValid">Current Documents</span>
              </mat-card-title>
              <mat-card-content>
                <div fxLayout="column">
                  <div fxLayout="row" >
                    <span class="f-s-16" fxFlex="40"><strong>File</strong></span>
                    <span class="f-s-16"><strong>Requirement</strong></span>
                  </div>
                  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutAlign.lt-md=""
                    *ngFor="let document of this.facilitatorLicense.licenseDocuments" fxLayoutGap="8px">
                    <span fxFlex="40" class="text-indigo-A700 text-wrap" style="cursor: pointer" (click)="downloadDocument(document.id, document.name, false)" title="{{document.name}}">{{document.name.length > 15 ? document.name.substring(0,15) + "..." : document.name}}</span>
                    <div fxFlex="70"  fxLayout="row" fxLayoutAlign="start center">
                      <div fxFlex="50" fxLayout="column">
                        <span *ngFor="let type of document.types">
                          {{documentTypeReference[type]}}
                        </span>
                      </div>
                      <div fxLayout="row" *ngIf="!sharedService.mobile">
                      <button *ngIf="!licenseLocked" type="button" mat-icon-button class="m-8" (click)="deleteFile(document.id, document.name, false)"
                        color="warn" aria-label="Delete">
                        <mat-icon>delete</mat-icon>
                      </button>
                    </div>
                    <div fxLayout="column" *ngIf="sharedService.mobile">
                      <button *ngIf="!licenseLocked" type="button" mat-icon-button class="m-8" (click)="deleteFile(document.id, document.name, false)"
                        color="warn" aria-label="Delete">
                        <mat-icon>delete</mat-icon>
                      </button>
                    </div>
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>

          <div fxLayout="row">
            <mat-card fxFlex="100">
              <mat-card-title>
                Training Program Information
              </mat-card-title>
              <mat-card-content>
                <div fxLayout="column" fxLayoutGap="8px" fxFlex="50" fxFlex.lt-lg="100">
                  <span>Name of Training Program with OHA Approved Curriculum</span>
                  <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">

                    <div fxLayout="column" fxLayoutGap="8px" fxFlex="75">

                      <mat-form-field>
                        <mat-label>Approved Training Programs</mat-label>
                        <input type="text"
                               placeholder="Training Program"
                               aria-label="Training Program"
                               matInput
                               formControlName="trainingProgram"
                               [matAutocomplete]="auto">
                        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                          <mat-option *ngFor="let trainingProgram of filteredOptions | async" [value]="trainingProgram">
                            {{trainingProgram}}
                          </mat-option>
                        </mat-autocomplete>
                      </mat-form-field>
                  </div>
                  <div fxLayout="column" fxLayoutGap="8px" fxFlex="25">
                    <mat-form-field>
                      <mat-label>Date Completed</mat-label>
                      <input placeholder="mm/dd/yyyy" formControlName="trainingDate" matInput [matDatepicker]="trainingDate"
                        [max]="sharedService.today" />
                      <mat-datepicker-toggle matSuffix [for]="trainingDate"></mat-datepicker-toggle>
                      <mat-datepicker #trainingDate>
                        <mat-datepicker-actions>
                          <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
                          <button mat-button matDatepickerCancel>Cancel</button>
                        </mat-datepicker-actions>
                      </mat-datepicker>
                      <mat-error *ngIf="documentsForm.get('trainingDate').value > sharedService.today">
                        Please choose an earlier date.
                      </mat-error>
                      <mat-error *ngIf="documentsForm.get('trainingDate').hasError('required')">
                        Training Date is required
                      </mat-error>
                    </mat-form-field>
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
          <mat-card fxLayoutGap="8px" fxLayout="column" fxFlex="10">
            <div fxLayout="row">
                <div fxLayout="column" fxLayoutGap="8px">
                  <span class="text-wrap">Is all the information on this screen current and correct?</span>
                  <span class="text-wrap"><i>Some information may be outdated or incorrect but you cannot change it on this application.
                    These changes require additional documentation and possibly additional fees. These changes are made by a Change Request.
                    If information is incorrect or outdated, please select "No," below and follow the instructions for a Change Request.</i></span>
                  <mat-radio-group aria-label="Select an option" formControlName="documentPageCorrect" fxLayoutGap="8px">
                    <mat-radio-button [value]="true">Yes</mat-radio-button>
                    <mat-radio-button [value]="false">No</mat-radio-button>
                  </mat-radio-group>
                  <mat-error *ngIf="documentsForm.get('documentPageCorrect').hasError('required')">This field is required</mat-error>
                </div>
            </div>

            <div fxLayout="row" *ngIf="documentsForm.get('documentPageCorrect').value == false">
              <div fxLayout="column" fxFlex="40">
                <mat-form-field>
                  <textarea rows="2" matInput placeholder="Please list the information that is not current and correct" formControlName="documentPageCorrectReason"></textarea>
                  <mat-error *ngIf="validateDocumentsCorrect()">
                    This field is required
                  </mat-error>
                 </mat-form-field>
                 <span>You are required to submit a Change Request to update this information. For more information on Change Requests, please see the TLC Home Page, Change Requests section. This is the first page you see when you log into TLC.</span>
              </div>
            </div>
          </mat-card>
        </div>
      </form>
      <div fxLayout="row" fxLayoutGap="8px" fxFlex="50" fxFlex.lt-md="100" class="p-t-16">
        <button mat-raised-button matStepperPrevious>{{!licenseLocked? "Save and " : ""}}Back</button>
        <button mat-raised-button matStepperNext>{{!licenseLocked? "Save and " : ""}}Continue</button>
      </div>
    </mat-step>

    <!--Fees-->
    <mat-step *ngIf="facilitatorLicenses.length > 0">
      <ng-template matStepLabel>
        <span matTooltip="Fee Information" matTooltipPosition="below" [matTooltipShowDelay]="500">Fee Information</span>
      </ng-template>
      <div fxLayout="column" fxLayoutGap="8px" fxFlex="50" fxFlex.lt-lg="100">
        <span *ngIf="sharedService.tablet" class="f-s-18">Fees</span>
        <app-payment-notice [canPay]="false" entityType="FacilitatorLicense" feeType="ApplicationFee" [renewal]="renewing"></app-payment-notice>
        <div fxLayout="row" fxLayoutGap="8px" fxFlex="50" fxFlex.lt-md="100">
          <button mat-raised-button matStepperPrevious>Back</button>
          <button mat-raised-button matStepperNext>Continue</button>
        </div>
      </div>
    </mat-step>

    <!--Verify and Submit-->
    <mat-step [stepControl]="licenseForm" (ngSubmit)="submitApplication()" *ngIf="facilitatorLicenses.length > 0">
      <ng-template matStepLabel>
        <span matTooltip="Review and Submit" matTooltipPosition="below" [matTooltipShowDelay]="500">Review and Submit</span>
      </ng-template>
      <div fxLayout="column" fxLayoutGap="8px">
        <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
          <mat-card fxFlex="50" fxFlex.lt-lg="100">
            <mat-card-title>
              <span *ngIf="applicantForm.valid || licenseLocked">Applicant Information</span>
              <span class="text-red-400" *ngIf="!applicantForm.valid && !licenseLocked">Applicant Information Incomplete</span>
            </mat-card-title>
            <mat-card-content>
              <div fxLayout="column" fxLayoutGap="8px" class="p-b-16">
                <div fxLayout="row" fxLayout.lt-lg="column">
                  <span fxFlex="20" fxFlex.lt-lg="100"><strong>Legal Name</strong></span>
                  <div fxLayout="row" fxFlex="80" fxFlex.lt-lg="100" fxLayoutGap="8px">
                    <span>{{facilitatorLicense.applicants[0]?.legalFirstName}}</span>
                    <span>{{facilitatorLicense.applicants[0]?.legalMiddleName}}</span>
                    <span>{{facilitatorLicense.applicants[0]?.legalLastName}}</span>
                  </div>
                </div>
                <div fxLayout="row" fxLayout.lt-lg="column">
                  <span fxFlex="20" fxFlex.lt-lg="100"><strong>Preferred Name</strong></span>
                  <div fxLayout="row" fxFlex="80" fxFlex.lt-lg="100" fxLayoutGap="8px">
                    <span>{{facilitatorLicense.applicants[0]?.preferredFirstName}}</span>
                    <span>{{facilitatorLicense.applicants[0]?.preferredMiddleName}}</span>
                    <span>{{facilitatorLicense.applicants[0]?.preferredLastName}}</span>
                  </div>
                </div>
                <div fxLayout="row" fxLayout.lt-lg="column">
                  <span fxFlex="20" fxFlex.lt-lg="100"><strong>Other Names</strong></span>
                  <div fxLayout="row" fxFlex="80" fxFlex.lt-lg="100" fxLayoutGap="8px">
                    <span>{{facilitatorLicense.applicants[0]?.alternateNames}}</span>
                  </div>
                </div>
                <div fxLayout="row" fxLayout.lt-lg="column">
                  <span fxFlex="20" fxFlex.lt-lg="100"><strong>Physical Address</strong></span>
                  <div fxLayout="row" fxFlex="80" fxFlex.lt-lg="100" fxLayoutGap="8px">
                    <span>{{facilitatorLicense.applicants[0]?.physicalStreet}}</span>
                    <span>{{facilitatorLicense.applicants[0]?.physicalCity}}</span>
                    <span>{{facilitatorLicense.applicants[0]?.physicalState}}</span>
                    <span>{{facilitatorLicense.applicants[0]?.physicalZip}}</span>
                    <span>{{facilitatorLicense.applicants[0]?.physicalCounty}}</span>
                  </div>
                </div>
                <div fxLayout="row" fxLayout.lt-lg="column">
                  <span fxFlex="20" fxFlex.lt-lg="100"><strong>Mailing Address</strong></span>
                  <div fxFlex="80" fxFlex.lt-lg="100" fxLayout="row" fxLayoutGap="8px" *ngIf="!facilitatorLicense.applicants[0]?.mailingIsPhysical">
                    <span>{{facilitatorLicense.applicants[0]?.mailingStreet}}</span>
                    <span>{{facilitatorLicense.applicants[0]?.mailingCity}}</span>
                    <span>{{facilitatorLicense.applicants[0]?.mailingState}}</span>
                    <span>{{facilitatorLicense.applicants[0]?.mailingZip}}</span>
                    <span>{{facilitatorLicense.applicants[0]?.mailingCounty}}</span>
                  </div>
                  <div fxFlex="80" fxFlex.lt-lg="100" *ngIf="facilitatorLicense.applicants[0]?.mailingIsPhysical" fxLayout="row" fxLayoutGap="8px">
                    <span>Same as Physical</span>
                  </div>
                </div>
                <div fxLayout="row" fxLayout.lt-lg="column">
                  <span fxFlex="20" fxFlex.lt-lg="100"><strong>Primary Phone</strong></span>
                  <div fxLayout="row" fxFlex.lt-lg="100" fxFlex="80" fxLayoutGap="8px">
                    <span>{{facilitatorLicense.applicants[0]?.phone | mask: '(000) 000-0000 000000'}}</span>
                  </div>
                </div>
                <div fxLayout="row" fxLayout.lt-lg="column">
                  <span fxFlex="20" fxFlex.lt-lg="100"><strong>Alternate Phone</strong></span>
                  <div fxLayout="row" fxFlex="80" fxFlex.lt-lg="100" fxLayoutGap="8px">
                    <span>{{facilitatorLicense.applicants[0]?.alternatePhone | mask: '(000) 000-0000 000000'}}</span>
                  </div>
                </div>
                <div fxLayout="row" fxLayout.lt-lg="column">
                  <span fxFlex="20" fxFlex.lt-lg="100"><strong>Email</strong></span>
                  <div fxLayout="row" fxFlex="80" fxFlex.lt-lg="100" fxLayoutGap="8px">
                    <span>{{facilitatorLicense.applicants[0]?.email}}</span>
                  </div>
                </div>
                <div fxLayout="row" fxLayout.lt-lg="column">
                  <span fxFlex="20" fxFlex.lt-lg="100"><strong>Language</strong></span>
                  <div fxLayout="row" fxFlex="80" fxFlex.lt-lg="100" fxLayoutGap="8px">
                    <span>{{facilitatorLicense.applicants[0]?.language}}</span>
                  </div>
                </div>
                <div fxLayout="row" fxLayout.lt-lg="column">
                  <span fxFlex="20" fxFlex.lt-lg="100"><strong>Training Program</strong></span>
                  <span>{{facilitatorLicense.trainingProgram}}</span>
                </div>

                <div fxLayout="row" fxLayout.lt-lg="column">
                  <span fxFlex="20" fxFlex.lt-lg="100"><strong>Completed On:</strong></span>
                  <span>{{facilitatorLicense.trainingDate | date}}</span>
                </div>

                <div fxLayout="column" *ngIf="facilitatorLicense.applicants[0]?.previousLicense">
                  <mat-checkbox [checked]="true" disabled>
                    Has this individual applied for or received any other licenses from Oregon Psilocybin Services?
                  </mat-checkbox>
                  <div fxLayout="column">
                    <span>Previous Affiliated License</span>
                    <span>{{facilitatorLicense.applicants[0]?.affiliatedLicenses}}</span>
                  </div>
                </div>

              </div>
            </mat-card-content>
          </mat-card>

          <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px" fxFlex="50" fxFlex.lt-lg="100">
            <mat-card fxFlex="60">
              <mat-card-title>
                <div fxLayout="column">
                  <span>Current Documents</span>
                  <span class="text-red-400" *ngIf="!applicantDocumentsValid || !licenseDocumentsValid || (facilitatorLicense.isRenewal && !documentsForm.get('documentPageCorrect').value)">Not All Requirements are Met</span>
                </div>
              </mat-card-title>
              <mat-card-content>
                <div fxLayout="column">
                  <span class="text-red-400" *ngIf="missingTypes[trainingCertificateType]">{{documentTypeReference[trainingCertificateType]}} required</span>
                  <span class="text-red-400" *ngIf="missingTypes[socialEquityPlanType]">{{documentTypeReference[socialEquityPlanType]}} required</span>
                  <span class="text-red-400" *ngIf="missingTypes[personalIdentificationType]">{{documentTypeReference[personalIdentificationType]}} required</span>
                  <span class="text-red-400" *ngIf="missingTypes[updatedEquityPlanType]">{{documentTypeReference[updatedEquityPlanType]}} required</span>
                </div>
                <div fxLayout="column" fxLayoutGap="8px">
                  <div fxLayout="row">
                    <span class="f-s-18" fxFlex="60"><strong>File</strong></span>
                    <span class="f-s-18"><strong>Requirement</strong></span>
                  </div>
                  <div fxLayout="row" *ngFor="let document of facilitatorLicense.applicants[0]?.applicantDocuments">
                    <span fxFlex="60" title="{{document.name}}">{{document.name.length > 15 ? document.name.substring(0,15) + "..." : document.name}}</span>
                    <div fxLayout="column">
                      <div fxLayout="row" *ngFor="let type of document.types">
                        {{documentTypeReference[type]}}
                      </div>
                    </div>
                  </div>
                  <div fxLayout="row" *ngFor="let document of facilitatorLicense?.licenseDocuments">
                    <span fxFlex="60" title="{{document.name}}">{{document.name.length > 15 ? document.name.substring(0,15) + "..." : document.name}}</span>
                    <div fxLayout="column">
                      <div fxLayout="row" *ngFor="let type of document.types">
                        {{documentTypeReference[type]}}
                      </div>
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
            <mat-card fxFlex="40">
              <mat-card-title>
                <span *ngIf="facilitatorLicense.passedExam">
                  Facilitator Exam
                </span>
                <span class="text-red-400" *ngIf="!facilitatorLicense.passedExam">
                  You Must Pass the Exam</span>
              </mat-card-title>
              <mat-card-content>
                <div fxLayout="column" fxLayoutGap="8px">
                  <div fxLayout="row" fxLayoutGap="8px">
                    <span class="f-s-18" fxFlex="30"><strong>Score</strong></span>
                    <span class="f-s-18" fxFlex="30"><strong>Result</strong></span>
                    <span class="f-s-18" fxFlex="40"><strong>Date Taken</strong></span>
                  </div>
                  <div fxLayout="row" fxLayoutGap="8px" *ngFor="let attempt of facilitatorLicense.examAttempts">
                    <span fxFlex="30">{{attempt.score}}%</span>
                    <span *ngIf="attempt.passed" class="f-s-16" fxFlex="30"><strong>Passed</strong></span>
                    <span *ngIf="!attempt.passed" fxFlex="30">Failed</span>
                    <span fxFlex="40">{{attempt.dateTaken | date}}</span>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>

        </div>
        <div fxLayout="row" fxLayoutGap="8px">
          <mat-card fxFlex="50" fxFlex.lt-lg="100">
            <mat-card-title>
              Sign and Submit
            </mat-card-title>
            <mat-card-content>
              <form [formGroup]="licenseForm">
                <div fxLayout="column" fxLayoutGap="8px">
                  <span class="p-l-24">
                    <strong>• </strong>
                    I certify the information provided in this application is true and complete to the best of my knowledge.
                    I understand falsifying an application, supplying misleading information, or withholding information is grounds for denial of my application or revocation of my license.
                  </span>
                  <span class="p-l-24">
                    <strong>• </strong>
                    I understand I am responsible for making my own determination regarding the legal risks associated with a Schedule 1 substance
                    under the Federal Controlled Substances Act.
                  </span>
                  <span class="p-l-24">
                    <strong>• </strong>
                    I understand the information provided in application materials, including applicant name, is subject to disclosure
                    under Oregon's public records laws and that certain information may be posted on OHA's website for purposes of license verification.
                  </span>
                  <span class="p-l-24">
                    <strong>• </strong>
                    I understand the following information in the application will be used by Oregon Psilocybin Services to start the background check process:
                    legal name; date of birth; physical address; phone number; email address; proof of identity; prior addresses if lived out of state during the
                    past five years; social security number (if consented); and prior names and aliases.
                  </span>
                  <span class="p-l-24">
                    <strong>• </strong>I understand I must complete and pass a background check as a condition of licensure.
                  </span>
                  <span class="p-l-24">
                    <strong>• </strong>
                    I understand I must notify Oregon Psilocybin Services in writing within 10 business days of any conviction for any misdemeanor or felony committed
                    and I will be required to undergo another criminal background check.
                  </span>
                  <span class="p-l-24">
                    <strong>• </strong>I understand I must notify Oregon Psilocybin Services in writing within 10 business days of a change in contact information.
                  </span>
                  <span class="p-l-24">
                    <strong>• </strong>I understand I am also required to obtain a worker permit as described in OAR 333-333-4070.
                  </span>
                  <span class="p-l-24">
                    <strong>• </strong>
                    I have read, understand, and agree to adhere to the Oregon Psilocybin Services Revised Statutes (ORS) Chapter 475A,
                    Oregon Administrative Rules (OAR) Chapter 333, Division 333, as well as any other applicable statutes or laws.
                  </span>
                  <span class="p-l-24">
                    <strong>• </strong>
                    I understand that failure to adhere to these statutes and rules may result in a denial of my application or disciplinary action if
                    I am licensed by Oregon Psilocybin Services.
                  </span>
                  <span class="p-l-24">
                    <strong>• </strong>
                    I understand the psilocybin facilitator license has a term of one year from the effective date of licensure.
                    If I wish to renew my license after that term, I understand I must complete a renewal application.
                  </span>
                  <span class="p-l-24">
                    <strong>• </strong>
                    I understand a license will not be issued until the license fee payment is paid and cleared.
                    A licensee may not operate until the effective date of licensure.
                  </span>
                  <span class="p-l-24" *ngIf="showRequiredApplicantDataAttestation(facilitatorLicense.applicationDate)">
                    <strong>• </strong>Applicant(s) understand and will comply with the requirement to complete and submit the Required 303 Applicant and Licensee REAL & SOGI
                    Data form in accordance with ORS 475A.374.
                  </span>
                  <span class="p-l-24">
                    <strong>• </strong>I understand the psilocybin facilitator application and annual license fees are non-refundable.
                  </span>
                  <mat-checkbox formControlName="attestation">
                    <span style="white-space: normal;" [ngStyle]="{'color': licenseForm.get('attestation').hasError('required')? 'red' : ''}">I have read and I understand the above statements</span>
                  </mat-checkbox>

                      <mat-radio-group aria-label="Select an option" formControlName="directoryConsent" fxLayoutGap="16px" style="padding-top: 1em">
                        <div fxLayout="column" fxLayoutGap="16px">
                          <div fxLayout="row">
                            <mat-radio-button [value]="true">
                              <span class="text-wrap">
                                I consent to having my license name, license type, and the contact information selected below listed in the voluntary OPS Licensee Directory.
                              </span>
                            </mat-radio-button>
                          </div>
                            <span *ngIf="licenseForm.get('directoryConsent').value">Please select which contact information you would like to include in the directory:</span>

                          <div fxLayout="column" style="padding-left: 1em" *ngIf="licenseForm.get('directoryConsent').value">
                            <div fxLayou="row" fxLayoutGap="5px">
                              <mat-checkbox formControlName="directoryPreferredName" *ngIf="applicantForm.get('preferredFirstName').value != '' || applicantForm.get('preferredLastName').value != ''">
                                <span style="white-space: normal;">Display my preferred name in the License Directory instead of my legal name.</span>
                              </mat-checkbox>
                            </div>

                            <div fxLayou="row" fxLayoutGap="5px">
                              <mat-checkbox formControlName="directoryConsentWebsite">
                                <span style="white-space: normal;">Website:</span>
                              </mat-checkbox>

                              <mat-form-field *ngIf="licenseForm.get('directoryConsentWebsite').value">
                                <mat-label>
                                  Website
                                </mat-label>
                                <input matInput formControlName="directoryWebsite">
                                <mat-error>
                                  <span *ngIf="licenseForm.get('directoryWebsite').hasError('required')">Directory website URL is required</span>
                                  <span *ngIf="licenseForm.get('directoryWebsite').hasError('pattern')">Please enter a valid URL (http:// or https://)</span>
                                </mat-error>
                              </mat-form-field>
                            </div>

                            <div fxLayou="row" fxLayoutGap="5px">
                              <mat-checkbox formControlName="directoryConsentEmail">
                                <span style="white-space: normal;">Email:</span>
                              </mat-checkbox>

                              <mat-form-field *ngIf="licenseForm.get('directoryConsentEmail').value">
                                <mat-label>
                                  Email
                                </mat-label>
                                <input matInput formControlName="directoryEmail">
                                <mat-error *ngIf="validateDirectoryConsentEmail()">
                                  This field is required
                                </mat-error>
                              </mat-form-field>
                            </div>

                            <div fxLayou="row" fxLayoutGap="5px">
                              <mat-checkbox formControlName="directoryConsentPhone">
                                <span style="white-space: normal;">Phone Number:</span>
                              </mat-checkbox>

                              <mat-form-field *ngIf="licenseForm.get('directoryConsentPhone').value">
                                <mat-label>
                                  Phone number
                                </mat-label>
                                <input matInput formControlName="directoryPhone" mask="(000) 000-0000 000000">
                                <mat-error *ngIf="validateDirectoryConsentPhone()">
                                  This field is required
                                </mat-error>
                              </mat-form-field>
                            </div>
                          </div>

                          <div fxLayout="row">
                            <mat-radio-button [value]="false">
                              <span class="text-wrap">
                                I <strong>do not</strong> consent to having my license name, license type, and contact information listed in the voluntary OPS Licensee Directory.
                              </span>
                            </mat-radio-button>
                          </div>
                        </div>
                      </mat-radio-group>
                      <mat-error *ngIf="licenseForm.get('directoryConsent').invalid">You must select one statement regarding consent</mat-error>
                      <span class="text-wrap"><em>Please note, if you do not consent, you may still be licensed with Oregon Psilocybin Services.</em></span>

                  <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px" fxLayoutAlign="start center" fxLayoutAlign.lt-lg="">
                    <mat-form-field fxFlex="50" fxFlex.lt-md="100">
                      <mat-label>
                        Signature
                      </mat-label>
                      <input matInput placeholder="Signature" formControlName="electronicSignature">
                      <mat-error *ngIf="licenseForm.get('electronicSignature').invalid">
                        Signature is required
                      </mat-error>
                    </mat-form-field>
                    <button [disabled]="validateDirectoryConsentWebsite() || !applicationValid || !licenseForm.valid || licenseLocked" type="submit" mat-raised-button
                      color="primary" (click)="submitRenewal()">Submit Renewal</button>
                  </div>
                </div>
              </form>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
      <div fxLayout="row" fxLayoutGap="8px" fxFlex="50" fxFlex.lt-md="100" class="p-t-24">
        <button mat-raised-button matStepperPrevious>Back</button>
      </div>
    </mat-step>

  </mat-stepper>
</div>

<div *ngIf="loadingFacilitator">
  <div [ngClass]="{'global-loader': true, 'global-loader-dark': sharedService.darkMode}">
    <h1>Loading</h1>
  </div>
</div>
