<mat-card [style.background]="cardColor" *ngIf="entityType !== 'WorkerPermitLicense'">
    <mat-card-content>
      <div fxLayout="column" fxLayoutGap="16px">
         <h2>Non-Refundable {{ paymentService.getPaymentTypeDescription(entityType, feeType, renewal) }} :
          <span *ngIf="entityType != null && entityType.length > 0">
            ${{paymentService.getPaymentTypeAmount(entityType, feeType, renewal, amount)}}
          </span>
        </h2>

        <span style="white-space: pre-wrap">
          {{paymentService.getPaymentTypeNotice(entityType, feeType, renewal)}}
        </span>

        <span>Payment method options:</span>
        <ul>
          <li><span *ngIf="!canPay">Online</span> <span *ngIf="canPay"><a (click)="pay()" >Pay online </a></span></li>
          <li>Money Order</li>
          <li>Cashier's Check</li>
          <li>Personal/Business Check (personal and business checks are held for 21 days for clearance)</li>
          <li>Cash* <strong>Please Do Not Mail Cash</strong></li>
        </ul>

        <div fxLayout="row" fxLayoutAlign="center start">
          <span><strong>Instructions for Submitting the Application and Fee Payment by Mail or In-Person</strong></span>
        </div>

        <span>
          Money orders and checks are payable to: <strong>Oregon Psilocybin Services</strong>
          Money orders and cashier's checks take less time to clear than personal and business checks.
          Personal and business checks are held for 21 days for clearance. Applications will be reviewed
          once payment has been cleared. Application fees are non-refundable.
        </span>

        <div fxLayout="column">
          <span>
            <strong>Mailing the application packet, money order or check, and remittance form:</strong>
          </span>
          <span>Oregon Health Authority - Public Health Division - Fiscal Services</span>
          <span>RE: OPS</span>
          <span>PO Box 14260</span>
          <span>Portland, OR 97293-0260</span>
        </div>

        <div fxLayout="column">
          <span><strong>In-person payment with a money order or check:</strong></span>
          <span>
            To make an in-person fee payment with a money order or check, print the remittance form and proceed to
            Cashier's Office on the second floor of the Portland State Office Building at 800 NE Oregon St, Suite 200,
            Portland, OR 97232 - Hours: 9am to 4pm, Mon to Fri
          </span>
        </div>

        <span>
          Additional instructions: The Cashier's Office window is located on the second floor next to the Vital Health Statistics window.
          If security asks if you have an appointment, you may let them know you are dropping off a payment for Oregon Psilocybin Services
          at the Cashier's Office window which does not require an appointment. Please include the remittance form with the payment.
        </span>

        <div fxLayout="column">
          <span><strong>To make cash payment arrangements or for payment questions, please contact:</strong></span>
          <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center" style="word-break: break-all; white-space: normal;">
            <a href="mailto:licensing.psilocybin@oha.oregon.gov ">
              <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="8px" fxLayoutAlign="start center" fxLayoutAlign.lt-sm="start start">
                <span>
                  licensing.psilocybin@oha.oregon.gov </span>
                <mat-icon>email</mat-icon>
              </div>
            </a>
            <span>or (971) 341-1509</span>
          </div>
        </div>

        <div fxLayout="column" fxLayoutGap="16px" *ngIf="entityType === 'FacilitatorLicense' && !renewal">
          <h2>Non-Refundable Facilitator Annual License Fee: $2,000 or $1,000 if Applicant Qualifies for Reduced License Fee:
          </h2>
          <span>
            If your license application is approved, pending license fee payment and clearance, you will receive a request to pay the annual licensing fee.
            Please review the guide for more detailed information on reduced fees. To request a reduced fee, please upload the required supporting document in
            the “Documents” tab of this application. If you qualify for a reduced license fee, the fee will be reduced accordingly. Please use the same payment instructions
            above to pay the annual license fee.
            <strong>
              A license will not be issued until the annual license fee is paid and cleared. A licensee may not operate until the effective date of licensure.
            </strong>
          </span>
        </div>
        <div fxLayout="column" fxLayoutGap="16px" *ngIf="entityType === 'ManufacturerLicense' && !renewal">
          <h2>Non-Refundable Manufacturer Annual License Fee: $10,000 or $5,000 if Applicant Qualifies for Reduced Fee
          </h2>
          <span>
            If your license application is approved, pending license fee payment and clearance, you will receive a request to pay the annual licensing fee.
            Please review the guide for more detailed information on reduced fees. To request a reduced fee, please upload the required supporting document in
            the “Documents” tab of this application. If you qualify for a reduced license fee, the fee will be reduced accordingly. Please use the same payment instructions
            above to pay the annual license fee.
            <strong>
              A license will not be issued until the annual license fee payment is paid and cleared. A licensee may not operate until the effective date of licensure.
            </strong>
          </span>
        </div>
        <div fxLayout="column" fxLayoutGap="16px" *ngIf="entityType === 'ServiceCenterLicense' && !renewal">
          <h2>Non-Refundable Service Center Annual License Fee: $10,000 or $5,000 if Applicant Qualifies for Reduced License Fee
          </h2>
          <span>
            If your license application is approved, pending license fee payment and clearance, you will receive a request to pay the annual licensing fee.
            Please review the guide for more detailed information on reduced fees. To request a reduced fee, please upload the required supporting
            document in the “Documents” tab of this application. If you qualify for a reduced license fee, the fee will be reduced accordingly.
            Please use the same payment instructions above to pay the annual license fee. <strong>A license will not be issued until the annual license fee
            payment has been paid and cleared. A licensee may not operate until the effective date of licensure.</strong>
          </span>
        </div>
        <div fxLayout="column" fxLayoutGap="16px" *ngIf="entityType === 'TestingLabLicense' && !renewal">
          <h2>Non-Refundable Laboratory Annual License Fee: $10,000
          </h2>
          <span>
            If your license application is approved, pending license fee payment and clearance, you will receive a request to pay the annual licensing fee.
            Please use the same payment instructions above to pay the annual license fee. <strong>A license will not be issued until the annual license fee
            payment is paid and cleared. A licensee may not operate until the effective date of licensure. </strong>
          </span>
        </div>
        <div fxLayout="column" fxFlex="15" fxFlex.lt-lg="" fxLayoutGap="8px" *ngIf="canPay">
          <div>
            <button *ngIf="!sharedService.mobile" mat-raised-button color="primary" type="button" (click)="viewRemittanceSlip()">View Remittance Slip</button>
          </div>
          <div>
            <button mat-stroked-button type='button' (click)="pay()" color="primary" *ngIf="canPay">Pay Online Now</button>
          </div>
          <span *ngIf="sharedService.mobile"><strong>Remittance Slip Not Available on Mobile</strong></span>
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card *ngIf="entityType === 'WorkerPermitLicense'">
    <mat-card-title>
      <span>Non-Refundable Worker Permit Fee $25.00</span>
    </mat-card-title>
    <mat-card-content>
      <div fxLayout="column" fxLayoutGap="8px">
        <span>
          After the worker permit application has been approved, pending worker permit fee payment and clearance,
          you will receive a request to pay the worker permit fee. At that time, we will provide a permit fee remittance
          form to submit with your permit fee payment. A worker permit will not be issued until the worker permit fee is
          paid and cleared. If issued a worker permit, an individual may not perform the duties described in ORS 475A.445
          until the effective date of approval.
        </span>
        <span style="color:red">
          <strong>
            Do not send the permit fee payment until contacted by OPS that your application has been approved and you
            receive payment instructions.
          </strong>
        </span>

        <div fxLayout="column">
          <span><strong>If you have any questions, please send us a TLC message or email/call: </strong></span>
          <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center" style="word-break: break-all; white-space: normal;">
            <a href="mailto:licensing.psilocybin@oha.oregon.gov ">
              <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="8px" fxLayoutAlign="start center" fxLayoutAlign.lt-sm="start start">
                <span>
                  licensing.psilocybin@oha.oregon.gov </span>
                <mat-icon>email</mat-icon>
              </div>
            </a>
            <span>or (971) 341-1509</span>
          </div>
        </div>


        <div fxLayout="column" fxFlex="15" fxFlex.lt-lg="" fxLayoutGap="8px" *ngIf="canPay">
          <div>
            <button *ngIf="!sharedService.mobile" mat-raised-button color="primary" type="button" (click)="viewRemittanceSlip()">View Remittance Slip</button>
          </div>
          <div>
            <button mat-stroked-button type='button' (click)="pay()" color="primary" *ngIf="canPay">Pay Online Now</button>
          </div>
          <span *ngIf="sharedService.mobile"><strong>Remittance Slip Not Available on Mobile</strong></span>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
